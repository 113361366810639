import React, { useState } from "react";
// import '../Project/Projects.css';
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  IconButton,
  Grid,
  TextField,
  DialogActions,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import FileSystemService from "./FileSystemService";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";

const API_URL = process.env.REACT_APP_API_URL;

function FileSharing({ show, handleClose, document, setLoading }) {
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [customShare, setCustomShare] = useState("");
  const [inviteError, setInviteError] = useState(false);
  const [inviteList, setInvitaionList] = useState([]);
  const [memberError, setMemberError] = useState(false);
  const schema = yup
    .object({
      subject: yup.string().required("Subject is required"),
      message: yup.string().required("Message body is required"),
      members_list: yup.array(),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const validateForm = () => {
    if (inviteList.length == 0)
      setMemberError("Provide Email to share the document");
    else setMemberError(true);
  };

  const onSubmit = (data) => {
    validateForm();
    if (inviteList.length > 0) {
      let project_document_id = "";
      if (document && document.is_dir === true) {
        project_document_id = document?.app_id;
      } else if (
        document &&
        document.is_dir === false &&
        document.project_document &&
        document.project_document.app_id
      ) {
        project_document_id = document.project_document.app_id;
      }
      const postData = {
        subject: data.subject,
        project_document_id: project_document_id,
        message: data.message,
        email_id: inviteList.join(","),
      };

      handleClose();
      reset();
      setLoading(true);
      FileSystemService.shareDocument(postData)
        .then((response) => {
          if (response && response.data) {
            setLoading(false);
            setOpenMessage({
              state: "success",
              message: response?.data?.message,
              open: true,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setOpenMessage({ state: "error", message: error, open: true });
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const addNewShare = () => {
    if (customShare.length == 0) setInviteError("Email cannot be empty");
    else if (!validateEmail(customShare)) setInviteError("Enter Valid Email");
    else {
      setInviteError("");
      let existingInviteList = inviteList;
      let validateMember = existingInviteList.filter(
        (list) => list === customShare
      );
      if (validateMember.length == 0) {
        existingInviteList.push(customShare);
        setInvitaionList(existingInviteList);
      }
      setCustomShare("");
      validateForm();
    }
  };

  const removeInvite = (email) => {
    const updatedInvitaion = inviteList.filter((obj) => obj !== email);
    setInvitaionList(updatedInvitaion);
  };

  const sharingDocumentsForm = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>

      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <h3 className="submain-heading">{`Share Selected File`}</h3>
        </DialogTitle>
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description scrollbar-primary">
            <Grid container spacing={2}>
              <div className="private-form">
                <form
                  className="form-private form-design"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={2}></Grid>
                  <div className="form-group mb-3 search-member-form">
                    <label for="subject" className="mb-2">
                      Email Subject
                    </label>
                    <div className="form-group mb-3">
                      <TextField
                        id="subject-basic"
                        variant="outlined"
                        placeholder="Email Subject"
                        {...register("subject")}
                      />
                      <p className="error-msg">{errors.subject?.message}</p>
                    </div>
                  </div>
                  <div className="form-group mb-3 search-member-form">
                    <label for="subject" className="mb-2">
                      Shared File Name
                    </label>
                    <div className="form-group mb-3">
                      <p>{document?.name}</p>
                    </div>
                  </div>
                  <div className="form-group mb-3 search-member-form">
                    <label for="message" className="mb-2">
                      Email Message
                    </label>
                    <div className="form-group mb-3">
                      <TextField
                        id="message"
                        variant="outlined"
                        placeholder="Messaget"
                        {...register("message")}
                      />
                      <p className="error-msg">{errors.message?.message}</p>
                    </div>
                  </div>
                  <div className="form-group mb-3 search-member-form">
                    <label for="exampleInputEmail1" className="mb-2">
                      Add Emails Receipient list
                    </label>
                    <div className="form-group mb-3">
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="name@company.com"
                            value={customShare}
                            onChange={(event) => {
                              setCustomShare(event.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            edge="end"
                            className="btn_styles"
                            aria-label="Add Email to share"
                            onClick={() => addNewShare()}
                          >
                            <PersonAddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <p className="error-msg">{inviteError}</p>
                    </div>
                  </div>
                  <div className="form-group mb-3 member-selected-form">
                    {inviteList.length > 0 && (
                      <label for="exampleInputEmail1" className="mb-2">
                        Invitation List
                      </label>
                    )}
                    <Grid container spacing={2}>
                      {inviteList.map((invities) => {
                        return (
                          <React.Fragment>
                            <Grid item xs={10}>
                              <span className="email_align">{invities}</span>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                className="btn_styles"
                                aria-label="delete"
                                onClick={() => removeInvite(invities)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                    <div className="form-group mb-3">
                      <p className="error-msg">{memberError}</p>
                    </div>
                  </div>
                  <div >
                    <DialogActions>
                      <SecondaryButton type="button" onClick={handleClose}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton type="submit">{"Submit"}</PrimaryButton>
                      </DialogActions>
                  </div>
                </form>
              </div>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return sharingDocumentsForm;
}

export default FileSharing;
