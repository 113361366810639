const projectStatusEnum = {
  0: { className: "error", name: "Not Started", value: 0 },
  1: { className: "warning", name: "In-Progress", value: 1 },
  2: { className: "success", name: "Completed", value: 2 },
};

const projectTypeEnum = {
  1: { name: "Residential", value: 1 },
  2: { name: "Commercial", value: 2 },
};

const projectTypeList = [
  { label: "Residential", value: 1 },
  { label: "Commercial", value: 2 },
];

const projectStatusList = [
  { label: "Not Started", value: 1 },
  { label: "In-Progress", value: 2 },
  { label: "Completed", value: 3 },
];

const memberTradeList = [
  "site preparation",
  "concrete",
  "structural and framing",
  "roofing",
  "siding",
  "sheet metal work",
  "carpentry",
  "masonry and stonework",
  "drywall",
  "painting and paper hanging",
  "tile setting",
  "miscellaneous special trade contractors",
  "electrical",
  "plumber",
  "HVAC",
];

export {
  projectStatusEnum,
  projectTypeEnum,
  projectTypeList,
  projectStatusList,
  memberTradeList
};
