import React, { useState, useEffect } from "react";
import { Breadcrumbs, Grid, InputAdornment } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { projectTypeEnum } from "../../../constants/Project.constants";
import { dateFormat } from "../../../constants/Global.constants";
import { NumericFormatCustom } from "../../../components/InputMask";

function ProjectStep2({ step2DataSubmit, updateStep, shouldResetForm}) {
  const schema = yup
    .object({
      allocated_approved_budget: yup
        .string()
        .required("Allocated/Approved Project Budget is required"),
      contract: yup.string(),
      desired_profit: yup
        .string()
        .required("Project Margin is required"),
      address: yup.string().required("Site Address is required"),
      owner_name: yup.string().required("Owner Name is required"),
      owner_phone: yup
        .number()
        .typeError("Use only numbers")
        .required("Owner Phone is required")
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      owner_address: yup.string().required("Owner Address is required"),
      owner_email: yup
        .string()
        .required("Email is required")
        .email("Email is invalid"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const step2Submit = (data) => {
    step2DataSubmit(data);
  };

  useEffect(() => {
    reset(shouldResetForm)
  }, [shouldResetForm])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <form
        className="form-private form-design"
        onSubmit={handleSubmit(step2Submit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="form-group mb-3">
              <label className="mb-2">
                Allocated/Approved Project Budget<i className="required">*</i>
              </label>
              <Controller
              name="allocated_approved_budget"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
              value={value}
                id="allocated_approved_budget"
                variant="outlined"
                placeholder="$0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("allocated_approved_budget")}
              />)} />
              <p className="error-msg">
                {errors.allocated_approved_budget?.message}
              </p>
            </div>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <div className="form-group mb-3">
              <label className="mb-2">Approved Project Contract#</label>
              <Controller
              name="contract"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
              value={value}
                id="contract"
                variant="outlined"
                placeholder="Approved Project Contract#"
                {...register("contract")}
              />)} />
              <p className="error-msg">
                {errors.contract?.message}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group mb-3">
            <label className="mb-2">
              Desired Profit Margin<i className="required">*</i>
            </label>
            <Controller
              name="desired_profit"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
              value={value}
              id="desired_profit"
              variant="outlined"
              placeholder="0.00"
              InputProps={{
                inputComponent: NumericFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              {...register("desired_profit")}
            />)} />
            <p className="error-msg">
              {errors.desired_profit?.message}
            </p>
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="mb-4 mt-3">
            <h4>OWNER DETAILS</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">
                Owner Name<i className="required">*</i>
              </label>
              <TextField
                id="owner_name"
                placeholder="Owner Name"
                variant="outlined"
                {...register("owner_name")}
              />
              <p className="error-msg">{errors.owner_name?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">
                Owner Email<i className="required">*</i>
              </label>
              <TextField
                id="owner_email"
                placeholder="Owner Email"
                {...register("owner_email")}
                variant="outlined"
              />
              <p className="error-msg">{errors.owner_email?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="form-group">
              <label className="mb-2">
                Owner Address<i className="required">*</i>
              </label>
              <TextField
                id="owner_address"
                placeholder="Owner Address"
                {...register("owner_address")}
                multiline
                maxRows={4}
                variant="outlined"
              />
              <p className="error-msg">{errors.owner_address?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">
                Owner Phone<i className="required">*</i>
              </label>
              <TextField
                id="owner_phone"
                placeholder="Owner Phone"
                {...register("owner_phone")}
                variant="outlined"
              />
              <p className="error-msg">{errors.owner_phone?.message}</p>
            </div>
          </Grid>
        </Grid>
        <div className="login-links-section text-right">
          <button type="button" className="secondary-button w-auto mr-3" onClick={()=>updateStep(0)}>
            Previous
          </button>
          <button type="submit" className="primarynext-button w-auto">
            Next
          </button>
        </div>
      </form>
    </LocalizationProvider>
  );
}

export default ProjectStep2;
