import http from "../../service/HttpService";
import { authHeaders } from "../../helper/AuthHelper";

const API_URL = process.env.REACT_APP_API_URL;

const getProjectDocuments = (id, parentId = '') => {
    return http
        .get(API_URL + `project-dir-structure?project_id=${id}&parent_id=${parentId}`, {
            headers: authHeaders(),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const uploadDocument = (formData) => {
    return http
        .post(API_URL + `projectdocument/`, formData, {
            headers: authHeaders(true)
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const uploadPCODocument = (formData) => {
    return http
        .post(API_URL + `projectdocument/`, formData, {
            headers: authHeaders(true)
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const updateDocumentTree = (formData) => {
    return http
        .post(API_URL + `project-dir-structure/`, formData, {
            headers: authHeaders()
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const shareDocument = (formData) => {
    return http
        .post(API_URL + `share-document/`, formData, {
            headers: authHeaders()
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const getProjectConversation = ({ page, limit, project_id }) => {
    return http
        .get(API_URL + "messages/", {
            headers: authHeaders(),
            params: {
                page, limit, project_id
            }
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const removeFile = (fileId) => {
    return http
        .delete(API_URL + `projectdocument/${fileId}`, {
            headers: authHeaders(),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

export default { getProjectDocuments, uploadDocument, updateDocumentTree, shareDocument, getProjectConversation, uploadPCODocument, removeFile }