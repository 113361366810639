import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Snackbar,
  styled,
} from "@mui/material";
// import '../Project/Projects.css';
import "rc-tree/assets/index.css";
// import Sidebar from "../../components/Sidebar";
import FileSystemService from "./FileSystemService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChonkyActions,
  ChonkyIconName,
  FileBrowser,
  FileContextMenu,
  FileList,
  FileNavbar,
  FileToolbar,
  defineFileAction,
} from "chonky";
import FileSharing from "./FileSharing";
import projectService from "../../service/ProjectService";
import StyledDropzone from "../../components/DropZone/DropZone";
import FileUpload from "./FileUpload";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";

function DirectorySystemLanding() {
  const navigate = useNavigate();
  let { project_id: projectId } = useParams();
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [projectDetails, setProjectDetails] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [folderPrefix, setKeyPrefix] = useState([]);
  const [currentFolder, setCurrentFolder] = useState({});
  const [files, setFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectionId, setSelectionId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [openUploadFile, setOpenUploadFile] = useState(false);

  const getProjectDirectory = (currentSelection) => {
    FileSystemService.getProjectDocuments(projectId, currentSelection?.id)
      .then((res) => {
        let refinedData = res?.data;
        refinedData = res?.data.map((file) => {
          file.id = file.app_id;
          file.isDir = file.is_dir;
          return file;
        });
        setFiles(refinedData);
        setLoading(false);
      })
      .catch((error) => {
        if (error)
          setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  useEffect(() => {
    if (projectId) getProjectDetails();
  }, []);

  useEffect(() => {
    getProjectDirectory(currentFolder);
  }, [folderPrefix, setFiles, currentFolder]);

  const folderChain = React.useMemo(() => {
    let folderChain;
    if (folderPrefix.length == 0) {
      folderChain = [];
    } else {
      let currentPrefix = "";
      folderChain = folderPrefix.map((prefixPart) => {
        currentPrefix = prefixPart;
        return {
          id: currentPrefix.id,
          name: currentPrefix.name,
          isDir: currentPrefix.isDir,
        };
      });
    }
    return folderChain;
  }, [folderPrefix]);

  const getProjectDetails = () => {
    projectService
      .getProjectById(projectId)
      .then((res) => {
        if (res?.data) {
          setProjectDetails(res?.data);
        }
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const handleDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
    setSelectionId(id);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setSelectionId(0);
  };

  const createFolder = (folderName) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("document", null);
    formData.append("project_id", projectId);
    formData.append("parent_id", currentFolder?.id);
    formData.append("isDir", true);
    formData.append("name", folderName);
    formData.append("sub_dir", currentFolder?.name);
    FileSystemService.uploadDocument(formData)
      .then((res) => {
        setOpenMessage({ state: "success", message: res?.message, open: true });
        getProjectDirectory(currentFolder);
        // let refinedData = res?.data;
        // refinedData.id = refinedData.app_id;
        // refinedData.isDir = refinedData.is_dir;
        // setFiles([...files, refinedData]);
      })
      .catch((error) => {
        setLoading(false);
        if (error)
          setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  const uploadFile = async (uploadData) => {
    const { filename: fileName, files } = uploadData;
    if (files && files.length > 0) {
      const file = files[0];

      var formData = new FormData();
      const filename = file.name;

      let last_dot = filename.lastIndexOf(".");
      let ext = filename.slice(last_dot + 1);
      let name = filename.slice(0, last_dot);
      formData.append("document", file, fileName + "." + ext);
      let docType = currentFolder?.name.toLowerCase();

      formData.append("project_id", projectId);
      formData.append("parent_id", currentFolder?.id);
      formData.append("isDir", false);
      formData.append("name", fileName);
      formData.append("sub_dir", currentFolder?.name);
      formData.append("document_type", docType);
      setLoading(true);

      await FileSystemService.uploadDocument(formData)
        .then((res) => {
          setOpenMessage({
            state: "success",
            message: res?.message,
            open: true,
          });
          getProjectDirectory(currentFolder);
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            setOpenMessage({ state: "error", message: error, open: true });
          } else {
            // Handle generic error
          }
        })
        .finally(() => {
          // Set loading to false when the file processing is complete
          setLoading(false);
        });
    }
  };

  const handleFileAction = useCallback(
    (data) => {
      console.log(data.id, ChonkyActions.OpenSelection.id);
      if (data.id === ChonkyActions.ChangeSelection.id) {
        console.log(data.state);
      } else if (data.id === ChonkyActions.OpenSelection.id) {
        console.log(data);
        if (
          data.state.selectedFiles.length > 0 &&
          !data.state.selectedFiles[0].isDir
        ) {
          window.open(
            data.state.selectedFiles[0]?.project_document?.document,
            "_blank"
          );
          return;
        }
      } else if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload?.files && data.payload?.files.length !== 1) return;
        if (!data.payload?.targetFile) return;
        if (!data.payload?.targetFile.isDir) {
          window.open(
            data.state.selectedFiles[0]?.project_document?.document,
            "_blank"
          );
          return;
        }
        let isExisting = folderPrefix.findIndex(
          (pref) => pref.id == data.payload.targetFile.id
        );
        if (isExisting == -1) {
          setKeyPrefix([...folderPrefix, data.payload.targetFile]);
          setCurrentFolder(data.payload.targetFile);
        } else {
          setCurrentFolder(data.payload.targetFile);
          setKeyPrefix(folderPrefix.slice(0, isExisting + 1));
        }
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        const folderName = prompt("Provide the name for your new folder:");
        if (folderName) createFolder(folderName);
      } else if (data.id === ChonkyActions.UploadFiles.id) {
        setOpenUploadFile(true);
        // const fileName = prompt('Provide the name for your new file:');
        // if (fileName) uploadFile(fileName);
      } else if (data.id === ChonkyActions.DeleteFiles.id) {
        handleDeleteDialog(data.state.selectedFiles[0].project_document);
      } else if (data.id === shareFile.id) {
        setSelectedDocument(data.state.selectedFiles[0]);
        setOpenDialog(true);
      }
    },
    [folderPrefix]
  );

  const shareFile = defineFileAction({
    id: "Share",
    button: {
      name: "Share",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.share,
    },
  });

  const myFileActions = [
    shareFile,
    ChonkyActions.CreateFolder,
    ChonkyActions.UploadFiles,
    ChonkyActions.DeleteFiles,
  ];

  const handleDelete = () => {
    if (selectionId && selectionId !== 0) {
      setLoading(true);
      const fileId = selectionId?.app_id || selectionId;
      FileSystemService.removeFile(fileId)
        .then((response) => {
          handleCloseDelete();
          getProjectDirectory(currentFolder);
          setOpenMessage({
            state: "success",
            message: response?.message,
            open: true,
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error)
            setOpenMessage({ state: "error", message: error, open: true });
        });
    }
  };

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const fileSystem = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      <div className="row mx-0 p-4">
        <div className="backgrd-white" style={{ minHeight: 700 }}>
          <div className="mb-2 d-flex">
            <h3 className="submain-heading">Project File System</h3>
          </div>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link underline="hover" color="inherit" href={`/projects`}>
              Projects
            </Link>
            <Link
              underline="hover"
              color="text.primary"
              href={`/projectFiles/${projectId}`}
              aria-current="page"
            >
              File System
            </Link>
          </Breadcrumbs>
          <FileBrowser
            files={files}
            folderChain={folderChain}
            onFileAction={handleFileAction}
            fileActions={myFileActions}
          >
            <FileNavbar />
            <FileToolbar />
            <FileList />
          </FileBrowser>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <FileSharing
            show={openDialog}
            handleClose={() => {
              setOpenDialog(false);
            }}
            document={selectedDocument}
            setLoading={setLoading}
          />
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent className="dialog-delete-block">
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the File/Folder?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleCloseDelete}>
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={handleDelete}>OK</PrimaryButton>
            </DialogActions>
          </Dialog>
          <FileUpload
            open={openUploadFile}
            handleCloseUpload={handleCloseUpload}
            uploadFile={uploadFile}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return <>{fileSystem}</>;
}

export default DirectorySystemLanding;
