import { authHeaders } from "../helper/AuthHelper";
import http from "./HttpService";

const API_URL = process.env.REACT_APP_API_URL;


const createChangeOrder = (formData) => {
    return http
        .post(API_URL + `project-change-order/`, formData, {
            headers: authHeaders(true)
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const updateChangeOrder = (CO_ID, bodyFormData) => {
    return http
        .put(API_URL + `project-change-order/${CO_ID}/`, bodyFormData, {
            headers: authHeaders(true),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const getPCOByID = (CO_ID) => {
    return http
        .get(API_URL + `project-change-order/${CO_ID}/`, {
            headers: authHeaders(),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const getAllPCO = ({ skip, limit, status, project_id, is_co }) => {
    return http
        .get(API_URL + "project-change-order/", {
            headers: authHeaders(),
            params: {
                skip, limit, status, project_id, is_co
            }
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data) {
                let msg = "Try again later!";
                if (error?.response?.data?.status[0])
                    msg = error?.response?.data?.status[0];
                throw msg;
            }

        });
}


export default { createChangeOrder, getAllPCO, updateChangeOrder, getPCOByID }