import http from "./HttpService";
import { authHeaders } from "../helper/AuthHelper";

const API_URL = process.env.REACT_APP_API_URL;

const getAllTasks = (params) => {
    return http
        .get("tasks/", {
            headers: authHeaders(),
            params
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;
        });
}


const addNewTask = (bodyFormData) => {
    return http
        .post("tasks/", bodyFormData, {
            headers: authHeaders(),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const updateTask = (bodyFormData) => {
    return http
        .put(`tasks/${bodyFormData.id}/`, bodyFormData, {
            headers: authHeaders(),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

const removeTask = (projectId) => {
    return http
        .delete(`tasks/${projectId}`, {
            headers: authHeaders(),
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

export default { addNewTask, getAllTasks, updateTask, removeTask }