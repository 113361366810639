import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Breadcrumbs,
  Grid,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { PCOReducer, DefaultPCO } from "./PCOReducer";
import PCOSteps from "./PCOSteps";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import {
  ColorlibConnector,
  ColorlibStepIconRoot,
} from "../../../components/ColorSteper";
import ChangeOrderService from "../../../service/ChangeOrderService";
import moment from "moment";

function PCOForm({ isChangeOrder }) {
  const navigate = useNavigate();
  let { project_id: projectId, type: typeId, pco_id: pcoId } = useParams();
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  let [step, setStep] = useState(0);
  const [PCOData, setPCOData] = useReducer(PCOReducer, DefaultPCO);
  let steps = PCOSteps(step, setStep, PCOData, setPCOData, isChangeOrder);
  let [isLoading, setLoading] = useState(true);
  const titleTerm = isChangeOrder ? "CO" : "PCO";

  useEffect(() => {
    if (pcoId) {
      ChangeOrderService.getPCOByID(pcoId)
        .then((res) => {
          res.Requestor.date_needed_by = moment(res.Requestor.date_needed_by);
          res.Requestor.assigned_to_list = res.Requestor.assigned_to;
          // Update the status to submitted when PCO converted from approved to CO
          if (
            res?.General?.status &&
            typeId === "existingPCO" &&
            isChangeOrder
          ) {
            res.General.status = 1;
          }
          setPCOData({
            type: "UPDATE_DATA",
            payload: res,
          });
          setLoading(false);
          setTimeout(() => {
            setStep(0);
          }, 100);
        })
        .catch((err) => {
          if (err && err.msg) {
          }
        });
    } else if (projectId && typeId) {
      let typeIdTmp =
        isChangeOrder && typeId === "existingRFT" && typeId === "existingPCO"
          ? ""
          : typeId;
      setPCOData({
        type: "UPDATE_DATA",
        payload: { project: projectId, co_category: typeIdTmp },
      });
      setLoading(false);
    }
  }, []);

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <Filter1Icon />,
      2: <Filter2Icon />,
      3: <Filter3Icon />,
      4: <Filter4Icon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const PCOFormContent = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      <div className="row mx-0 p-4">
        <div className="backgrd-white">
          <div className="mb-2 d-flex">
            <h3 className="submain-heading">{titleTerm}</h3>
          </div>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
            <Link to="/projects">Projects</Link>
            <Link to={`/${isChangeOrder ? "co" : "pco"}/${projectId}`}>
              {titleTerm} Dashboard
            </Link>
            <Link
              aria-current="page"
            >
              {pcoId ? "Edit" : "Create New"} {titleTerm}
            </Link>
          </Breadcrumbs>
          <div className="row mx-0 mb-4 " style={{ justifyContent: "center" }}>
            <Stepper
              alternativeLabel
              activeStep={step}
              connector={<ColorlibConnector />}
            >
              {steps.map((step) => (
                <Step key={step.id}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid
              sx={{ mt: 4, mb: 4 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <h4> {steps[`${step}`].title} </h4>
            </Grid>
            {!isLoading && <div>{steps[`${step}`].content}</div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return <>{PCOFormContent}</>;
}

export default PCOForm;
