import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import secureLocalStorage from "react-secure-storage";
import TeamsInternalTable from "./TeamsInternalTable";
import TeamsExternalTable from "./TeamsExternalTable";
import { PrimaryButton } from "../../components/bproButtons";

const TeamsDashboard = () => {
  const navigate = useNavigate();
  const userRaw = secureLocalStorage.getItem("userInfo");
  const userParsed = JSON.parse(userRaw);
  console.log(userParsed);
  const [tenantToken, setTenantToken] = React.useState("");
  const [currentTabIndex, setCurrentTabIndex] = useState("one");

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    const token = secureLocalStorage.getItem("builderTenantId");
    setTenantToken(token);
  }, []);
  return (
    <React.Fragment>
      {userParsed?.user_type}
      {userParsed?.user_type === "admin" && (
        <div className="row mx-0 mt-5 mb-3 d-flex justify-content-end">
          <div className="px-4 twobuttons_spaces">
            <PrimaryButton
              startIcon={<AddIcon />}
              onClick={() => navigate("/teams/addInternalMember")}
            >
              Add Internal Team Member
            </PrimaryButton>
            {"  "}
            <PrimaryButton
              startIcon={<AddIcon />}
              onClick={() => navigate("/teams/addExternallMember")}
            >
              Add External Team Member
            </PrimaryButton>
          </div>
        </div>
      )}

      {/* Request for Information section */}
      <div className="row mx-0 p-4">
        <div className="backgrd-white">
          <div className="mb-4 d-flex">
            <h3 className="submain-heading">Teams</h3>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ minHeight: 400, width: "100%" }}>
                <Tabs value={currentTabIndex} onChange={handleTabChange}>
                  <Tab value="one" label="Internal Members" />
                  <Tab value="two" label="External Members" />
                </Tabs>
                {currentTabIndex == "one" && <TeamsInternalTable />}
                {currentTabIndex == "two" && <TeamsExternalTable />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamsDashboard;
