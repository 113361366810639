import React, { useEffect, useState } from "react";
import { Breadcrumbs, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ProjectStep1 from "./ProjectStep1";
import ProjectStep2 from "./ProjectStep2";
import ProjectStep3 from "./ProjectStep3";
import ProjectService from "../../../service/ProjectService";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hasSubscription } from "../../../helper/AccessHelper";
const steps = ["", "", ""];

function Page3() {
  const navigate = useNavigate();
  let { project_app_id } = useParams();
  const [currentStep, updateStep] = useState(0);
  const [formData, setFormData] = useState({});

  const costKeys = [
    'materials_total_cost',
    'insurance_costs',
    'labor_total_cost',
    'equipment_total_cost',
    'permits_and_fees',
    'subcontractor_total_costs',
    'utilities_and_services',
    'transportation_costs',
    'land_acquisition_costs',
    'contingency_fund',
    'overhead_costs',
    'safety_measures',
    'consultant_fees',
    'technology_and_software_costs',
    'marketing_and_communication',
  ];

  useEffect(() => {
    // if (hasSubscription() !== true) {
    //   navigate("projects");
    // }
    if (project_app_id) {
      ProjectService.getProjectById(project_app_id).then((projectRes) => {
        let formData = projectRes;
        const projectOwner = projectRes.project_owner;
        const owner = {
          owner_name: projectOwner.name,
          owner_email: projectOwner.email,
          owner_address: projectOwner.address,
          owner_phone: projectOwner.phone,
        };
        formData = { ...formData, ...projectRes.project_costs, ...owner };
        delete formData.project_costs;
        delete formData.project_owner;
        formData.start_date = moment(formData.start_date);
        formData.end_date = moment(formData.end_date);
        setFormData(formData);
      });
    }
  }, []);

  const step1DataSubmit = (data) => {
    setFormData({ ...formData, ...data });
    updateStep(1);
  };

  const step2DataSubmit = (data) => {
    setFormData({ ...formData, ...data });
    updateStep(2);
  };

  const parseFloarFixed = (value) => {
    return parseFloat(value.replace(/,/g, '')).toFixed(2);
  };

  const step3DataSubmit = async (data) => {
    const bodyData = { ...formData, ...data };
    let totalProjCost = 0;
    for (let key of costKeys) {
      totalProjCost += parseFloat(data[key]);
    }
    
    setFormData(bodyData);
    const formBodyData = {
      project_name: bodyData.project_name,
      description: bodyData.description,
      start_date:
        moment(bodyData.start_date).format("YYYY-MM-DD") + "T00:00:00.000Z",
      end_date:
        moment(bodyData.end_date).format("YYYY-MM-DD") + "T00:00:00.000Z",
      project_type: bodyData.project_type,
      address: bodyData.address,
      allocated_approved_budget: parseFloarFixed(
        bodyData.allocated_approved_budget
      ),
      desired_profit: parseFloarFixed(bodyData.desired_profit),
      contract: bodyData.contract,
      status: bodyData.status,
      project_owner: {
        name: bodyData.owner_name,
        email: bodyData.owner_email,
        address: bodyData.owner_address,
        phone: bodyData.owner_phone,
      },
      project_costs: {
        materials_total_cost: parseFloarFixed(bodyData.materials_total_cost),
        labor_total_cost: parseFloarFixed(bodyData.labor_total_cost),
        equipment_total_cost: parseFloarFixed(bodyData.equipment_total_cost),
        permits_and_fees: parseFloarFixed(bodyData.permits_and_fees),
        subcontractor_total_costs: parseFloarFixed(
          bodyData.subcontractor_total_costs
        ),
        insurance_costs: parseFloarFixed(bodyData.insurance_costs),
        utilities_and_services: parseFloarFixed(
          bodyData.utilities_and_services
        ),
        transportation_costs: parseFloarFixed(bodyData.transportation_costs),
        land_acquisition_costs: parseFloarFixed(
          bodyData.land_acquisition_costs
        ),
        contingency_fund: parseFloarFixed(bodyData.contingency_fund),
        overhead_costs: parseFloarFixed(bodyData.overhead_costs),
        safety_measures: parseFloarFixed(bodyData.safety_measures),
        consultant_fees: parseFloarFixed(bodyData.consultant_fees),
        technology_and_software_costs: parseFloarFixed(
          bodyData.technology_and_software_costs
        ),
        marketing_and_communication: parseFloarFixed(
          bodyData.marketing_and_communication
        ),
        total_prject_cost: totalProjCost,
      },
    };
    let callFnService = ProjectService.addNewProject;
    if (project_app_id) {
      callFnService = ProjectService.updateProject;
      formBodyData.id = project_app_id;
    }

    const formDataSubmit = new FormData();
    formDataSubmit.append("json_data", JSON.stringify(formBodyData));
    const savedFile = bodyData.files || [];
    if (savedFile.length > 0) {
      savedFile.map((file) => {
        formDataSubmit.append("attachments", file, file.name);
      });
    }

    try {
      const getProjectRes = await callFnService(
        formDataSubmit,
        formBodyData.id
      );
      if (
        getProjectRes.data &&
        getProjectRes.data.data &&
        getProjectRes.data.data.app_id
      ) {
        navigate("projects");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row mx-0 p-4">
      <div className="backgrd-white">
        <div className="mb-2 d-flex">
          <h3 className="submain-heading">Projects</h3>
        </div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/projects" className="pointer-cus">
            Projects
          </Link>
          <p>{project_app_id ? "Edit" : "Create New"} Project</p>
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className="mx-auto">
            <div className="row mx-auto">
              <div className="private-form">
                <h3 className="submain-heading">
                  {project_app_id ? "Edit" : "New"} Project
                </h3>

                <div className="steps-wizard">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={currentStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                <div style={{ display: currentStep === 0 ? "block" : "none" }}>
                  <ProjectStep1
                    step1DataSubmit={step1DataSubmit}
                    shouldResetForm={formData}
                  />
                </div>
                <div style={{ display: currentStep === 1 ? "block" : "none" }}>
                  <ProjectStep2
                    step2DataSubmit={step2DataSubmit}
                    updateStep={updateStep}
                    shouldResetForm={formData}
                  />
                </div>
                <div style={{ display: currentStep === 2 ? "block" : "none" }}>
                  <ProjectStep3
                    step3DataSubmit={step3DataSubmit}
                    updateStep={updateStep}
                    shouldResetForm={formData}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Page3;
