import React, { useEffect, useState } from "react";
import UserService from "../../service/UserService";
import AdminDashboard from "./AdminDashboard";
import TenantDashboard from "./TenantDashboard";

const DashboardRoute = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState("one");

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    UserService.getDashboardData()
      .then((response) => {
        if (response && response.data) {
          setDashboardData(response.data);
        }
      })
      .catch((error) => {});
  };



  return (
    <React.Fragment>
      {dashboardData && dashboardData?.user_role === 'admin' &&
        <AdminDashboard/>
      }
      {dashboardData && dashboardData?.user_role === 'internal' &&
        <TenantDashboard/>
      }
      {dashboardData && dashboardData?.user_role === 'external' &&
        <TenantDashboard/>
      }
    </React.Fragment>
  );
};

export default DashboardRoute;
