import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  Grid,
  Backdrop,
  CircularProgress,
  TextField,
  Stack,
  Typography,
  Switch,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import StyledDropzone from "../../components/DropZone/DropZone";
import RFIService from "./RFIService";
import { PrimaryButton } from "../../components/bproButtons";

function RFISendBackModel({ handleClose, show, rfiID, projectData }) {
  // Initial state for message and loading status
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [isLoading, setLoading] = useState(false);
  const [savedFile, setSavedFile] = useState([]);

  // Form schema for validation
  const schema = yup
    .object({
      question: yup.string().required("Question/Answer is required"),
      is_question: yup.boolean().required("Selection is required"),
    })
    .required();

  // React hook form methods
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Form submission handler
  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("text", data.question);
    formData.append("is_question", data.question);
    formData.append("rfi_id", rfiID);
    formData.append("project", projectData?.project?.app_id);
    formData.append("status", 2);
    if (savedFile.length > 0) {
      savedFile.map((file) => {
        formData.append("attachments", file, file.name);
      });
    }
    handleClose(true);
    RFIService.addQA(formData)
      .then((response) => {
        reset();
        if (response && response.data) {
          setLoading(false);
          setOpenMessage({
            state: "success",
            message: response?.message,
            open: true,
          });
          handleClose(true);
        }
      })
      .catch((error) => {
        setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  // Function to handle file upload
  const uploadFiles = (file) => {
    setSavedFile(file);
  };

  // JSX for the RFISendBackModel form
  const RFISendBackForm = (
    <React.Fragment>
      {/* Snackbar for displaying messages */}
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      {/* Backdrop for loading indicator */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialog for RFISendBackModel form */}
      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <h3 className="submain-heading">{`Send Back/Anwser`}</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="col-lg-12 ">
              <div>
                {/* RFISendBackModel form */}
                <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 10 }}>
                  {/* Switch for question/answer selection */}
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Question</Typography>
                    <Switch
                      defaultChecked
                      {...register("is_question")}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Typography>Answer</Typography>
                  </Stack>
                  {/* Text field for question/answer */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="question"
                      placeholder="Question/Answer *"
                      {...register("question")}
                      onChange={(e) => {}}
                      multiline
                      maxRows={4}
                      fullWidth
                      variant="outlined"
                    />
                    <p className="error-msg">{errors.question?.message}</p>
                  </Grid>
                  {/* Dropzone for attachments */}
                  <Grid item xs={12} className="mb-4">
                    <label for="attachments" className="mb-2">
                      Attachments
                    </label>
                    <StyledDropzone
                      id="attachments"
                      uploadFiles={uploadFiles}
                      fileData={[]}
                      rfiData={{}}
                    />
                  </Grid>
                  {/* Submit button */}
                  <div className="row justify-content-end">
                    <div className="col-6">
                      <PrimaryButton
                        id="rfi_reassign_submit"
                        type="submit"
                        className="primarynext-button w-auto"
                      >
                        {"Submit"}
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return RFISendBackForm;
}

export default RFISendBackModel;
