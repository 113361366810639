import React, { useEffect, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Autocomplete,
  Backdrop,
  CircularProgress,
  Switch,
  FormControlLabel,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Breadcrumbs,
  Link,
} from "@mui/material";
import StyledDropzone from "../../components/DropZone/DropZone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ProjectService from "../../service/ProjectService";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { dateFormat } from "../../constants/Global.constants";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import UserService from "../../service/UserService";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DLService from "../../service/DLService";
import TaskService from "../../service/TaskService";
import DailyLogPDFGeneration from "./DailyLogPDFGeneration";
import { pdf } from "@react-pdf/renderer";
import { getWeatherCurrentByCountry } from "../../service/Weather";
import secureLocalStorage from "react-secure-storage";

function VisitorList({ visitors, deleteHandler }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>
                <h5>Visitor Name</h5>
              </TableCell>
              <TableCell colSpan={2}>
                <h5>Visitor Email</h5>
              </TableCell>
              <TableCell>
                <h5>Visitor Ph. no.</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visitors.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell colSpan={2}>{item.email}</TableCell>
                  <TableCell>{item.ph_no}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="comment"
                      onClick={() => deleteHandler(item.id)}
                    >
                      <DeleteIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function NewTaskForm({ newTaskHandler }) {
  const schema = yup
    .object({
      name: yup.string().required("Visitor Name is Required"),
      email: yup
        .string()
        .required("Visitor Email is required")
        .email("Email is invalid"),
      ph_no: yup
        .number()
        .typeError("Use only numbers")
        .required("Phone number is required")
        .transform((_, val) => (val !== "" ? Number(val) : null)),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState,
    clearErrors,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const onSubmit = () => {
    let data = getValues();
    newTaskHandler(data);
    reset();
    clearErrors();
  };

  const errors = formState.errors;

  const validateForm = () => {
    if (formState.isValid) onSubmit();
  };

  return (
    <form className="new-visitor-form">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            id="name"
            placeholder="Visitor Name *"
            label="Visitor Name *"
            //onBlur={trigger("name")}
            fullWidth
            variant="outlined"
            {...register("name")}
          />
          <p className="error-msg">{errors && errors.name?.message}</p>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="email"
            placeholder="Visitor Email *"
            label="Visitor Email *"
            fullWidth
            //onBlur={trigger("email")}
            variant="outlined"
            {...register("email")}
          />
          <p className="error-msg">{errors && errors.email?.message}</p>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="ph_no"
            placeholder="Visitor Phone no *"
            label="Visitor Phone no *"
            //onBlur={trigger("ph_no")}
            fullWidth
            variant="outlined"
            {...register("ph_no")}
          />
          <p className="error-msg">{errors && errors.ph_no?.message}</p>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              trigger();
              setTimeout(() => {
                validateForm();
              }, []);
            }}
            startIcon={<AddIcon />}
          >
            Add Visitor
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const TaskPriorityEnum = {
  0: { className: "green-text", name: "Low" },
  1: { className: "yellow-text", name: "Medium" },
  2: { className: "orange-text", name: "High" },
};
const DEFAULTWEATHER = 40;

function DailyLogForm() {
  const userRaw = secureLocalStorage.getItem("userInfo");
  const userParsed = JSON.parse(userRaw);
  const navigate = useNavigate();
  let { project_id: projectId, DLId } = useParams();
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [projectData, setProjectData] = useState({ members: [] });
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectRole, setProjectRole] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [savedFile, setSavedFile] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [weatherStatus, setWeatherStatus] = useState('');
  const schema = yup
    .object({
      contract_no: yup.string(),
      contractor: yup.object().required("Contractor is Required"),
      superintendent: yup.object().required("Superintendent To is Required"),
      weather_status: yup.string().required("Weather Status is required"),
      workers_on_site: yup.array().required("Workers attendance is required"),
      work_notes: yup.string().required("Work Notes is required"),
      has_site_visitors: yup.boolean(),
      site_visitors: yup.array(),
      has_safety_issues: yup.boolean(),
      health_safety_issue_desc: yup.string().when("has_safety_issues", {
        is: true,
        then: () => yup.string().required("Describe safety issue"),
      }),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    unregister,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const hasSiteVisitors = watch("has_site_visitors");
  const hasSafetyIssues = watch("has_safety_issues");
  useEffect(() => {
    if (hasSiteVisitors) {
      register("site_visitors");
    } else {
      unregister("site_visitors");
    }
    if (hasSafetyIssues) {
      register("health_safety_issue_desc");
    } else {
      unregister("health_safety_issue_desc");
    }
  }, [register, unregister, hasSiteVisitors, hasSafetyIssues]);

  useEffect(() => {
    if (projectId) {
      getProjectDetails();
      getCompletedTaskList();
    }
  }, []);

  useEffect(()=>{

    (async () =>{
      const getCurrentWeather = await getWeatherCurrentByCountry();
      if (getCurrentWeather && getCurrentWeather.hasOwnProperty('current') && getCurrentWeather.current.temp_f) {
        setValue("weather_status", getCurrentWeather.current.temp_f);
        setWeatherStatus(getCurrentWeather.current.temp_f);
      } else {
        setWeatherStatus(DEFAULTWEATHER);
      }
    })();
  },[]);

  const uniqueId = () => Math.floor(Math.random() * Date.now());

  const deleteHandler = (visitorId) => {
    setVisitors(visitors.filter(({ id }) => visitorId !== id));
  };

  const newTaskHandler = (data) => {
    const newVisitor = {
      id: uniqueId(),
      ...data,
    };
    setVisitors([...visitors, newVisitor]);
  };

  const getRoleData = (ProData) => {
    UserService.getAllRoles()
      .then((res) => {
        setRoleData(res.data);
        let userRole = ProData?.members.filter(
          (mem) => mem?.user_id?.email === userParsed.email
        );
        if (userRole.length > 0) {
          const roleAppId = userRole[0]?.role_id?.app_id;
          setValue("reqestor_role", roleAppId);
          setProjectRole(roleAppId);
          trigger("reqestor_role");
        }
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const getProjectDetails = () => {
    ProjectService.getProjectMembersById(projectId)
      .then((res) => {
        if (res?.data) {
          let ProData = res?.data;
          //ProData.members = res?.data.members.map(mem => mem.user_id)
          setProjectData(ProData);
          setValue("project_title", ProData?.project?.project_name);
          setValue("project_site_address", ProData?.project?.address);
          setProjectAddress(ProData?.project?.address);
          setProjectName(ProData?.project?.project_name);
          getRoleData(ProData);
        }
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const getCompletedTaskList = () => {
    TaskService.getAllTasks({
      limit: 100,
      skip: 0,
      status: 2,
      completed_by_today: true,
      project_id: projectId,
    })
      .then((res) => {
        setTaskData(res?.data?.results);
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const uploadFiles = (file) => {
    setSavedFile(file);
  };

  const generatePdfDocument = async (documentData) => {
    const blob = await pdf(
      <DailyLogPDFGeneration title="Daily Log PDF" pdfObj={documentData} />
    ).toBlob();
    return blob;
    // saveAs(blob, "a.pdf")
  };

  const onSubmit = async (data) => {
    const docPDF = await generatePdfDocument({
      ...data,
      ...{ taskList: taskData },
      ...{ site_visitors: visitors }
    });
    data.project = projectId;
    data.is_deleted = false;
    data.contractor = data.contractor?.user_id?.app_id;
    data.superintendent = data.superintendent?.user_id?.app_id;
    data.completed_tasks = taskData.map((task) => task.app_id);
    data.site_visitors = visitors.map((visitir) => {
      return { name: visitir.name, email: visitir.email, ph_no:visitir.ph_no };
    });
    let siteWorkersList = [];
    data.workers_on_site?.map((worker) => {
      siteWorkersList.push(worker?.user_id?.app_id);
    });
    data.workers_on_site = siteWorkersList;
    let CurrentDate = moment().format();
    const formData = new FormData();
    formData.append("json_data", JSON.stringify(data));
    const uploadFiles = [];
    uploadFiles.push(docPDF);
    formData.append("attachments", docPDF, `dailyLog-${CurrentDate}.pdf`);
    if (savedFile.length > 0) {
      savedFile.map((file) => {
        formData.append("attachments", file, file.name);
      });
    }
    setLoading(true);
    DLService.createDailyLog(formData)
      .then((response) => {
        if (response && response.data) {
          setLoading(false);
          setOpenMessage({
            state: "success",
            message: response?.message,
            open: true,
          });
          navigate(`/dailyLogs/${projectId}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  const getPriority = (id) => {
    return (
      <span className={TaskPriorityEnum[id]?.className}>
        {TaskPriorityEnum[id]?.name}
      </span>
    );
  };

  const taskCompletionList = () => {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h5>TASK TITLE</h5>
                </TableCell>
                <TableCell colSpan={2}>
                  <h5>DUE DATE</h5>
                </TableCell>
                <TableCell>
                  <h5>PRIORITY</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskData.map((task) => {
                return (
                  <TableRow key={task.id}>
                    <TableCell>{task.task_name}</TableCell>
                    <TableCell colSpan={2}>
                      {moment(task.due_date).format(dateFormat)}
                    </TableCell>
                    <TableCell>{getPriority(task.priority)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const DLFormContent = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
        <div className="row mx-0 p-4">
            <div className="backgrd-white">
                <div className="mb-2 d-flex">
                    <h3 className="submain-heading">Project File System</h3>
                </div>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2}}>
                    <Link underline="hover" color="inherit" href={`/projects`}>
                        Projects
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        href={`/projectFiles/${projectId}`}
                        aria-current="page"
                    >
                        File System
                    </Link>
                </Breadcrumbs>
                <React.Fragment>
                <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                    <form
                        className="form-sign-in pb-5"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            {" "}
                            <h4> Project Information </h4>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                            id="project_title"
                            label="Project title *"
                            placeholder="Project title *"
                            value={projectName}
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                            onChange={(e) => { }}
                            fullWidth
                            variant="outlined"
                            />
                            <p className="error-msg">{errors.project_title?.message}</p>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                            id="project_site_address"
                            label="Project Address *"
                            placeholder="Project Address *"
                            disabled
                            value={projectAddress}
                            InputProps={{
                                readOnly: true,
                            }}
                            onChange={(e) => { }}
                            multiline
                            maxRows={4}
                            fullWidth
                            variant="outlined"
                            />
                            <p className="error-msg">
                            {errors.project_site_address?.message}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {" "}
                            <h4> Tasks Completed Today </h4>
                            {taskCompletionList()}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {" "}
                            <h4> Current Work Information </h4>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="contract_no"
                            label="Contract Number"
                            placeholder="Contract Number"
                            fullWidth
                            variant="outlined"
                            {...register("contract_no")}
                            />
                            <p className="error-msg">{errors.contract_no?.message}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                            name="contractor"
                            control={control}
                            render={({ field, ...props }) => {
                                return (
                                <Autocomplete
                                    label="Contractor *"
                                    options={projectData?.members}
                                    getOptionLabel={(option) => option?.user_id?.email}
                                    placeholder="Contractor *"
                                    variant="outlined"
                                    getOptionSelected={(option, value) => {
                                    return option.user_id?.email === value.email;
                                    }}
                                    onChange={(event, value) => {
                                    field.onChange(value);
                                    trigger("contractor");
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Contractor *" />
                                    )}
                                />
                                );
                            }}
                            />
                            <p className="error-msg">{errors.contractor?.message}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                            name="superintendent"
                            control={control}
                            render={({ field, ...props }) => {
                                return (
                                <Autocomplete
                                    label="Superintendent *"
                                    options={projectData?.members}
                                    getOptionLabel={(option) => option?.user_id?.email}
                                    placeholder="Superintendent *"
                                    variant="outlined"
                                    getOptionSelected={(option, value) => {
                                    return option.user_id?.email === value.email;
                                    }}
                                    onChange={(event, value) => {
                                    field.onChange(value);
                                    trigger("superintendent");
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} label="Superintendent *" />
                                    )}
                                />
                                );
                            }}
                            />
                            <p className="error-msg">
                            {errors.superintendent?.message}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="weather_status"
                            label="Weather Status (Fahrenheit)"
                            placeholder="Weather Status"
                            fullWidth
                            variant="outlined"
                            {...register("weather_status")}
                            value={weatherStatus}
                            onChange={(event, value) => {
                                setValue('weather_status', event.target.value);
                                setWeatherStatus(event.target.value);
                            }}
                            disabled
                            />
                            <p className="error-msg">
                            {errors.weather_status?.message}
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                            name="workers_on_site"
                            control={control}
                            render={({ field, ...props }) => {
                                return (
                                <Autocomplete
                                    label="Workers On Site *"
                                    multiple
                                    options={projectData?.members}
                                    getOptionLabel={(option) => option?.user_id?.email}
                                    placeholder="Workers On Site *"
                                    variant="outlined"
                                    getOptionSelected={(option, value) => {
                                    return option.user_id?.email === value.email;
                                    }}
                                    onChange={(event, value) => {
                                    field.onChange(value);
                                    trigger("workers_on_site");
                                    }}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Workers On Site *"
                                    />
                                    )}
                                />
                                );
                            }}
                            />
                            <p className="error-msg">
                            {errors.workers_on_site?.message}
                            </p>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                            id="work_notes"
                            label="Work Notes *"
                            placeholder="Work Notes *"
                            {...register("work_notes")}
                            multiline
                            maxRows={4}
                            fullWidth
                            variant="outlined"
                            />
                            <p className="error-msg">{errors.work_notes?.message}</p>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                            control={
                                <Switch
                                id="has_site_visitors"
                                label="Has Site Visitors? "
                                placeholder="Has Site Visitors?"
                                {...register("has_site_visitors")}
                                />
                            }
                            label="Has Site Visitors?"
                            labelPlacement="start"
                            />
                            <p className="error-msg">
                            {errors.has_site_visitors?.message}
                            </p>
                        </Grid>
                        {hasSiteVisitors ? (
                            <Grid item xs={12} md={12}>
                            <NewTaskForm newTaskHandler={newTaskHandler} />
                            <VisitorList
                                visitors={visitors}
                                deleteHandler={deleteHandler}
                            />
                            <p className="error-msg">
                                {visitors.length == 0
                                ? "Visitors Details Required"
                                : ""}
                            </p>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                            control={
                                <Switch
                                id="has_safety_issues"
                                label="Health/Safety Issues? "
                                placeholder="Health/Safety Issues?"
                                {...register("has_safety_issues")}
                                />
                            }
                            label="Health/Safety Issues?"
                            labelPlacement="start"
                            />
                            <p className="error-msg">
                            {errors.has_safety_issues?.message}
                            </p>
                        </Grid>
                        {hasSafetyIssues ? (
                            <Grid item xs={12} md={12}>
                            <TextField
                                id="health_safety_issue_desc"
                                label="Health/Safety Issue Desc *"
                                placeholder="Health/Safety Issue Desc *"
                                {...register("health_safety_issue_desc")}
                                multiline
                                maxRows={4}
                                fullWidth
                                variant="outlined"
                            />
                            <p className="error-msg">
                                {errors.health_safety_issue_desc?.message}
                            </p>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <label htmlFor="attachments" className="mb-2">
                            Attachments
                            </label>
                            <StyledDropzone
                            id="attachments"
                            uploadFiles={uploadFiles}
                            fileData={[]}
                            DLData={{}}
                            />
                        </Grid>
                        </Grid>
                        <Grid
                        sx={{ mt: 4 }}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        >
                        <div className="row">
                            <div className="col-6">
                            <button
                                id="DL_cancel"
                                type="button"
                                className="secondary-button w-auto"
                                onClick={() => {
                                navigate(`/dailylogs/${projectId}`);
                                }}
                            >
                                {"Cancel"}
                            </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                            <button
                                id="DL_step2_submit"
                                type="submit"
                                className="primarynext-button w-auto"
                            >
                                {"Submit"}
                            </button>
                            </div>
                        </div>
                        </Grid>
                    </form>
                    </LocalizationProvider>
                </Container>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    onClick={() => { }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </React.Fragment>
            </div>
      </div>
    </React.Fragment>
  );

  return <>{DLFormContent}</>;
}

export default DailyLogForm;
