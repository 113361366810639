import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import StyledDropzone from "../../components/DropZone/DropZone";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";

const mediaFileTypes = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "text/csv": [".csv"],
};

const allFileTypes = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "text/csv": [".csv"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/msword": [".doc"],
  "application/pdf": [".pdf"],
};

const FileUpload = ({
  open,
  uploadFile,
  handleCloseUpload,
  onlyMedia = false,
}) => {
  const [savedFile, setSavedFile] = useState([]);

  // Form schema for validation
  const schema = yup
    .object({
      filename: yup.string().required("Filename is required"),
      files: yup.mixed().required("File is required"),
    })
    .required();

  // React hook form methods
  const {
    register,
    handleSubmit,
    trigger,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to handle file upload
  const uploadFiles = (file) => {
    setSavedFile(file);
    setValue("files", file);
    trigger("files");
  };

  const onSubmit = (data) => {
    uploadFile(data);
    onClose();
  };

  const onClose = () => {
    setValue("files", null);
    setValue("filename", "");
    handleCloseUpload();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle>
        <h3 className="submain-heading">Upload File</h3>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 0, mb: 5 }}>
        <DialogContent className="dialog-delete-block">
          {/* Text field for filename */}
          <Grid item xs={12} md={12}>
            <InputLabel className="mb-2">Filename *</InputLabel>
            <Controller
              name="filename"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="Filename"
                  placeholder="Filename *"
                  {...register("filename")}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <p className="error-msg">{errors.filename?.message}</p>
          </Grid>
          <Grid item xs={12} className="mb-4">
            <label for="attachments" className="mb-2">
              Attachments
            </label>
            <StyledDropzone
              id="attachments"
              uploadFiles={uploadFiles}
              fileData={[]}
              rfiData={{}}
              accptedFilesTypes={onlyMedia ? mediaFileTypes : allFileTypes}
            />
            <p className="error-msg">{errors.files?.message}</p>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mr: 2 }}>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton type="submit">Upload</PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FileUpload;
