import React, { useMemo, useRef, useState } from "react";
import GridTable from "../../components/DataGrid/GridTable";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import UserService from "../../service/UserService";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";

const TeamsInternalTable = ({ refresh, editTask, setTaskCount }) => {
  const navigate = useNavigate();
  const childRef = useRef();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectionId, setSelectionId] = useState(0);
  const [memberDetails, setmemberDetails] = useState({});

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name",
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "invite_email",
        header: "Email",
      },
      {
        accessorKey: "title",
        header: "Title/Function",
      },
      {
        accessorKey: "is_invite_accepted",
        header: "Accepted Invite",
        Cell: ({ cell }) => {
          return (
            <td>
              {cell.getValue() ? (
                <Chip color="success" label="Member" />
              ) : (
                <Chip color="warning" label="Pending Invite" />
              )}
            </td>
          );
        },
      },
    ],
    []
  );

  const handleDeleteDialog = (id, task) => {
    setOpenDeleteDialog(true);
    setSelectionId(id);
    setmemberDetails(task);
  };

  // Define menu items for row actions
  const renderRowActionMenuItems = ({ closeMenu, row }) => [
    <MenuItem
      key={0}
      onClick={() => resendEmail(row.original?.app_id)}
      sx={{ m: 0 }}
      disabled={row.original?.is_invite_accepted ? true : false}
    >
      Resend Invite
    </MenuItem>,
    <MenuItem
      key={0}
      onClick={() => {
        handleDeleteDialog(row.original?.app_id, row.original);
      }}
      sx={{ m: 0 }}
    >
      Delete
    </MenuItem>,
  ];

  const resendEmail = (memberId) => {
    UserService.resendMemberEmail({ tenant_member_id: memberId })
      .then((response) => {
        toast.info(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "dark",
        });
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  // Function to fetch data from API
  const fetchDataFromAPI = async (params) => {
    return await UserService.getAllTenants(params);
  };

  const handleDelete = () => {
    if (selectionId) {
      UserService.removeTenant(selectionId)
        .then((response) => {
          //getTaskList();
          toast.info(response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
          handleCloseDelete();
          childRef.current.refreshTable();
        })
        .catch((err) => {
          if (err && err.msg) {
          }
        });
    }
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setSelectionId(0);
    setmemberDetails({});
  };

  // Render the table component
  return (
    <React.Fragment>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete team member{" "}
            <b>
              "{memberDetails.first_name} {memberDetails.last_name}"
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton className="cancel-button" onClick={handleCloseDelete}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className="cancel-button" onClick={handleDelete}>
            OK
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <GridTable
        columns={columns}
        renderRowActionMenuItems={renderRowActionMenuItems}
        apiCall={fetchDataFromAPI}
        tenent={"Internal"}
        ref={childRef}
      />
    </React.Fragment>
  );
};

export default TeamsInternalTable;
