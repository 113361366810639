import { authHeaders } from "../../helper/AuthHelper";
import http from "../../service/HttpService";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Function to create a new Request for Information (RFI).
 * @param {FormData} formData - The form data containing RFI information.
 * @returns {Promise<Object>} The response data containing the created RFI.
 * @throws {string} Throws an error message if the request fails.
 */
const createRFI = (formData) => {
  return http
    .post(API_URL + `request-for-info/`, formData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to fetch RFIs by project ID.
 * @param {string} projectId - The ID of the project to fetch RFIs for.
 * @param {Object} params - Additional query parameters for the request.
 * @returns {Promise<Object>} The response data containing the RFIs for the project.
 * @throws {string} Throws an error message if the request fails.
 */
const getRFIbyProjectID = (projectId, params) => {
  return http
    .get(API_URL + `request-for-info/?project_id=${projectId}`, {
      headers: authHeaders(),
      params: params
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to update RFI data.
 * @param {string} RFI_ID - The ID of the RFI to update.
 * @param {FormData} bodyFormData - The form data containing the updated RFI information.
 * @returns {Promise<Object>} The response data containing the updated RFI.
 * @throws {string} Throws an error message if the request fails.
 */
const updateRFIData = (RFI_ID, bodyFormData) => {
  return http
    .put(API_URL + `request-for-info/${RFI_ID}/`, bodyFormData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to fetch an RFI by its ID.
 * @param {string} RFI_id - The ID of the RFI to fetch.
 * @returns {Promise<Object>} The response data containing the requested RFI.
 * @throws {string} Throws an error message if the request fails.
 */
const getRFIbyID = (RFI_id) => {
  return http
    .get(API_URL + `request-for-info/${RFI_id}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to add a new question and answer (QA) to an RFI.
 * @param {FormData} formData - The form data containing the QA information.
 * @returns {Promise<Object>} The response data containing the added QA.
 * @throws {string} Throws an error message if the request fails.
 */
const addQA = (formData) => {
  return http
    .post(API_URL + `rfi-communication/`, formData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

export default { createRFI, getRFIbyProjectID, getRFIbyID, updateRFIData, addQA };
