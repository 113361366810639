import moment from "moment";
import { dateFormat } from "../../../constants/Global.constants";
import { getUserInfoDetails } from "../../../helper/AccessHelper";

const userParsed = getUserInfoDetails();

export const DefaultPCO = {
    "project": "",
    "co_category": "",
    approved_by: [],
    projectDetails: {},
    General: {
        "title": "",
        "status": "1",
        "change_reason": "1",
        "description": "",
    },
    Requestor: {
        "company_name": "",
        "submitted_by": (userParsed && userParsed.email) ? userParsed.email : "",
        "role_on_the_project": "",
        "assigned_to_list": [],
    },
    Owner: {
        "owner_name": "",
        "owner_address": "",
        "owner_phone": "",
        "approved_cost": "",
        "proposed_total_days_added": "",
    },
    items: [],
    file: []
}

export const PCOReducer = (PCO, { type, payload }) => {
    switch (type) {
        case 'UPDATE_GENERAL_INFO':
            return {
                ...PCO,
                General: payload
            }
        case 'UPDATE_REQUESTOR_INFO':
            return {
                ...PCO,
                Requestor: payload
            }
        case 'UPDATE_OWNER_INFO':
            return {
                ...PCO,
                Owner: payload
            }
        case 'UPDATE_BREAKDOWN_INFO':
            return {
                ...PCO,
                items: payload,
            }
        case 'UPDATE_PROJECT_DATA':
            return {
                ...PCO,
                projectDetails: payload
            }
        case 'UPDATE_DATA':
            return {
                ...PCO,
                ...payload
            }
        case 'UPDATE_FILE':
            return {
                ...PCO,
                file: [...PCO.file, ...payload]
            }
        default:
            return PCO
    }
}