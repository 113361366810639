import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import * as yup from "yup"
import { DialogTitle, DialogContent, DialogContentText, Grid, Card, CardActionArea, CardHeader, TextField, MenuItem, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "../../service/UserService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ChangeOrderService from "../../service/ChangeOrderService";
import RFIService from "../RFI/RFIService";

const OPTION = { new: 'new', existingPCO: 'existingPCO', existingRFI: 'existingRFI'};
const APPROVED_STATUS = 3;
const definedCards = [
    { app_id: OPTION.new, role_name: 'From scratch', description: '', disabled: false},
    { app_id: OPTION.existingPCO, role_name: 'From existing PCO', description: '', disabled: false},
    { app_id: OPTION.existingRFI, role_name: 'From existing RFI', description: '', disabled: true }
];

const definedRoleCards = ['Owner', 'General Contractor', 'Contractor'];

function COSelectionCard({ handleClose, show, projectId }) {
    const navigate = useNavigate();
    const [roleData, setRoleData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [option, setOption] = useState(definedCards);

    const [pcoList, setPcoList] = useState([]);
    const [rfiList, setRfiList] = useState([]);

    const schema = yup
        .object({
            optionList: yup.string().required("Option is required"),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { optionList: '' }
    })

    useEffect(() => {
        getRoleData();
        getPCOList();
        getRFIList();
    }, []);
    
    const getRoleData = () => {
        UserService.getAllRoles().then((res) => {
            setRoleData(res.data.filter(data => definedRoleCards.indexOf(data.role_name) > -1));
        })
        .catch((err) => {
            if (err && err.msg) {
            }
        });
    }

    
  const getRFIList = () => {
    RFIService.getRFIbyProjectID(projectId)
      .then((res) => {
        setRfiList(res?.data?.results);
      });
  };
    
    const getPCOList = () => {
        ChangeOrderService.getAllPCO({ project_id: projectId, limit: 100, skip: (0 * 1), status: APPROVED_STATUS, is_co: 'False' })
            .then((res) => {
                setPcoList(res?.data?.results);
            })
    };

    const newCO = (type, role) => {
        if (role.disabled) 
            return false;
        setSelectedOption(type);
        switch (type) {
            case OPTION.new:
                setOption(roleData);
                break;
            case OPTION.existingPCO:
            case OPTION.existingRFI:
                break;
            default:
                navigate(`/createCO/${projectId}/${type}`);
                break;
        }
    }

    const handleCloseDialog = () =>  {
        handleClose();
        setSelectedOption('');
        setOption(definedCards);
    }

    const onSubmit = (data) => {
        navigate(`/createCO/${projectId}/${selectedOption}/${data.optionList}`);
    }

    const renderListSection = () => {
        let list = [];
        let listMsg = '';
        switch (selectedOption) {
            case OPTION.existingPCO:
                list = pcoList;
                listMsg = `No Approved PCO's found`;
                break;
            case OPTION.existingRFI:
                list = rfiList;
                listMsg = `No Approved RFI's found`;
                break;
            default:
                break; 
        }
        const listLen = list.length;
        return <>
            {listLen > 0 ? (
                <form className="form-sign-in pb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row m-0 pt-3 mb-4">
                        <InputLabel>{selectedOption === OPTION.existingPCO ? `PCO *` : `RFI *`}</InputLabel>
                        <TextField
                            id="optionList"
                            fullWidth
                            variant="outlined"
                            select
                            {...register("optionList")}
                        >
                            {list.map((item, index) => {
                                return <MenuItem key={index} value={selectedOption === OPTION.existingPCO ? item.co_id : item.rfi_id}>{selectedOption === OPTION.existingPCO ? item.General?.title : item.title}</MenuItem>
                            })}
                        </TextField>
                        <p className="error-msg">{errors.optionList?.message}</p>
                    </div>
                    <div className="row m-0 mx-0 justify-content-end">
                        <div className="col-12 px-0 mt-1 text-end"><button id="co_create_submit" type="submit" className="primarynext-button">{"Convert to CO"}</button></div>
                    </div>
                </form>
            ) : (
                <>
                    <Grid item xs={12} md={12} sx={{ mt: 5 }}><h5
                        direction="row"
                        justifyContent="center"
                        align="center">{listMsg}</h5></Grid>
                    <Grid item xs={12} md={12}
                        justifyContent="center"
                        align="center" sx={{ mb: 5 }}>
                        <button className="primarynext-button" onClick={() => { setSelectedOption('') }}>Go Back</button>
                    </Grid>
                </>
            ) 
        }</>;
    }

    const getTitleTxt = () => {
        let title = '';
        if (selectedOption === OPTION.new) {
            title = '> From Scratch';
        } else if (selectedOption === OPTION.existingPCO)  {
            title = '> From existing PCO';
        } else if (selectedOption === OPTION.existingRFI) {
            title = '> From existing RFI';
        }
        return title;
    }

    const COSelectionCardData = (<React.Fragment>
        <Dialog
            open={show}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle><h4 className="content-heading">{`Create New Change Order ${getTitleTxt()}`}</h4></DialogTitle>
            <DialogContent className="dialog-delete-block">
                <DialogContentText id="alert-dialog-description scrollbar-primary" sx={{mb:2}}>
                    {
                        ((selectedOption === '') || (selectedOption === OPTION.new) ) &&
                        <div className="col-lg-12 scrollbar-primary">
                            <Grid
                                container
                                spacing={5}
                                sx={{ mb: 10 }}
                            >
                                {option.map(role => {
                                    return <Grid item xs={12} md={4} sx={{curor: role.disabled ? 'not-allowed !important' : '' }}>
                                        <Card elevation={3} sx={{ backgroundColor: role.disabled ? "#cec8c8" : "#EFF7FF"}}>
                                            <CardActionArea onClick={() => newCO(role.app_id, role)}>
                                                <CardHeader
                                                    title={role.role_name}
                                                    subheader={role.description}
                                                // avatar={<PersonPinIcon sx={{ fontSize: 50 }} />}
                                                />
                                            </CardActionArea>
                                        </Card >
                                    </Grid>
                                })}
                            </Grid>
                        </div>
                    }
                    {(selectedOption === OPTION.existingPCO || selectedOption === OPTION.existingRFI) && renderListSection()}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </React.Fragment>)

    return (COSelectionCardData);
}

export default COSelectionCard;
