import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import AuthService from "../../service/AuthService";
import "./register.css";
import UserService from "../../service/UserService";
import { toast } from "react-toastify";

const steps = ["", "", ""];

function Register() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [duplicateEmail, setEmailStatus] = useState(false);
  const [formData, setFormData] = useState({});
  const [roleData, setRoleData] = useState([]);
  const [projectRole, setProjectRole] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is required")
        .email("Email is invalid"),
    })
    .required();

  const navigate = useNavigate();
  const {
    register: step1Reg,
    handleSubmit: handleStep1Submit,
    formState: { errors: step1Error },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const step2Schema = yup
    .object({
      first_name: yup
        .string()
        .min(3, "First Name must be at least 3 characters"),
      last_name: yup.string().min(3, "Last Name must be at least 3 characters"),
      company_name: yup.string(),
      address: yup.string(),
      phone_number: yup
        .number()
        .typeError("Use only numbers")
        .required("Owner Phone is required")
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      role: yup.string(),
      annual_project_size: yup.string(),
    })
    .required();

  const {
    register: step2Reg,
    handleSubmit: handleStep2Submit,
    setValue,
    trigger,
    reset,
    formState: { errors: step2Error },
  } = useForm({
    resolver: yupResolver(step2Schema),
  });

  const step3Schema = yup
    .object({
      password: yup
        .string()
        .required("New Password is required")
        .min(6, "Password should be minimum of six character"),
      confirmpassword: yup
        .string()
        .required("Confirm Password is required")
        .min(6, "Password should be minimum of six character")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const {
    register: step3Reg,
    handleSubmit: handleStep3Submit,
    formState: { errors: step3Error },
  } = useForm({
    resolver: yupResolver(step3Schema),
  });

  useEffect(() => {
    getRoleData();
  }, []);

  const getRoleData = () => {
    UserService.getAllRoles()
      .then((res) => {
        setRoleData(res.data);
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const handleRoleChange = (event) => {
    let newRoleVal = event.target.value;
    setValue("role", newRoleVal);
    setProjectRole(newRoleVal);
    trigger("role");
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      navigate("/projects?firstTimeLogin=true");
    }, 1000);
  };

  const step1Submit = (data) => {
    AuthService.verifyEmail(data?.email)
      .then((response) => {
        if (response) {
          setFormData({ ...formData, ...data });
          setActiveStep(1);
          setEmailStatus(false);
        }
      })
      .catch((errors) => {
        setEmailStatus(true);
      });
  };

  const step2Submit = (data) => {
    setFormData({ ...formData, ...data });
    setActiveStep(2);
  };

  const skipStep2 = () => {
    reset();
    setActiveStep(2);
  };

  const step3Submit = (data) => {
    if (searchParams.get("token"))
      data.token = searchParams.get("token");
    if (searchParams.get("tenant_id"))
      data.tenant_id = searchParams.get("tenant_id");
    setFormData({ ...formData, ...data });
    AuthService.registerUser({ ...formData, ...data })
      .then((response) => {
        if (response && response.data) {
          toast.info(response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
          setDialogOpen(true);
        }
      })
      .catch((errors) => {});
  };

  const triggerEmail = () => {
    AuthService.resendVerificationEmail({ email: formData?.email })
      .then((response) => {
        toast.info(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/projects?firstTimeLogin=true");
        }, 1000);
      })
      .catch((err) => {});
  };

  return (
    <div className="row mx-auto">
      <div className="public-form">
        <div className="text-center">
          <img src="/images/logo.svg" alt="BUILDIFYPRO" />
        </div>
        <h3>
          Welcome to BUILDIFY<span>PRO</span>
        </h3>
        <h6>
          Enter your details to Register in BUILDIFY<span>PRO</span>
        </h6>
        <div className="steps-wizard">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
        {activeStep == 0 && (
          <form
            className="form-signin form-design"
            onSubmit={handleStep1Submit(step1Submit)}
          >
            <div className="form-group">
              <label className="mb-2">Email address</label>
              <div className="form_icon">
                <TextField
                  id="reg-email"
                  variant="outlined"
                  {...step1Reg("email")}
                />
              </div>
              <p className="error-msg">{step1Error.email?.message}</p>
              {duplicateEmail && (
                <p className="error-msg">Email already exists</p>
              )}
            </div>

            <div className="login-links-section text-right">
              <button type="submit" className="primarynext-button w-auto">
                NEXT
              </button>
            </div>
            <div className="login-links-section">
              <div className="signuplinks">
                Back to
                <Link to="/login"> Login</Link>
              </div>
            </div>
          </form>
        )}
        {activeStep == 1 && (
          <form
            className="form-signin form-design"
            onSubmit={handleStep2Submit(step2Submit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="form-group mb-3">
                  <label className="mb-2">First Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="First Name"
                    {...step2Reg("first_name")}
                  />
                  <p className="error-msg">{step2Error.first_name?.message}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-group mb-3">
                  <label className="mb-2">Last Name</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Last Name"
                    {...step2Reg("last_name")}
                  />
                  <p className="error-msg">{step2Error.last_name?.message}</p>
                </div>
              </Grid>
            </Grid>
            <div className="form-group">
              <label className="mb-2">Phone Number</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="(305) 899-0232"
                {...step2Reg("phone_number")}
              />
              <p className="error-msg">{step2Error.phone_number?.message}</p>
            </div>
            <div className="form-group">
              <label className="mb-2">Company address</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="5301 Stevens Creek Blvd. Santa Clara, CA 95051, US"
                multiline
                maxRows={4}
                {...step2Reg("address")}
              />
              <p className="error-msg">{step2Error.address?.message}</p>
            </div>
            <div className="form-group">
              <label className="mb-2">Company Name</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="ABC company"
                {...step2Reg("company_name")}
              />
              <p className="error-msg">{step2Error.company_name?.message}</p>
            </div>
            <div className="form-group">
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <div className="select-dropdown">
                <Select
                  labelId="role_on_the_project_label"
                  id="role_on_the_project"
                  label="Role on the Project *"
                  value={projectRole}
                  onChange={handleRoleChange}
                  variant="outlined"
                >
                  {roleData.map((role) => {
                    return (
                      <MenuItem key={role.app_id} value={role.app_id}>
                        {role.role_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <p className="error-msg">{step2Error.role?.message}</p>
            </div>
            <div className="form-group">
              <label className="mb-2">Annual Project Size</label>

              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="$ 50,000"
                {...step2Reg("annual_project_size")}
              />
              <p className="error-msg">
                {step2Error.annual_project_size?.message}
              </p>
            </div>
            <div className="login-links-section text-right">
              <button
                type="button"
                className="secondary-button w-auto mr-3"
                onClick={skipStep2}
              >
                SKIP
              </button>
              <button type="submit" className="primarynext-button w-auto">
                NEXT
              </button>
            </div>
          </form>
        )}
        {activeStep == 2 && (
          <form
            className="form-signin form-design"
            onSubmit={handleStep3Submit(step3Submit)}
          >
            <div className="form-group">
              <label className="mb-2">Password</label>
              <div className="form_icon">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="*********"
                  type="password"
                  {...step3Reg("password")}
                />
                <span className="icons-pos">
                  <img src="../images/lock.svg" alt="Password" />
                </span>
              </div>
              <p className="error-msg">{step3Error.password?.message}</p>
            </div>
            <div className="form-group">
              <label className="mb-2">Confirm Password</label>
              <div className="form_icon">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="password"
                  placeholder="*********"
                  {...step3Reg("confirmpassword")}
                />
                <span className="icons-pos">
                  <img src="../images/lock.svg" alt="Confirm Password" />
                </span>
              </div>
              <p className="error-msg">{step3Error.confirmpassword?.message}</p>
            </div>

            <div className="login-links-section text-right">
              <button type="submit" className="primarynext-button w-auto">
                SUBMIT
              </button>
            </div>
          </form>
        )}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"One more step…Verify your email now to get started"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              A verification email has been sent to your registered email
              address. Please confirm by clicking the link provided. Check your
              Spam folder if needed. If you don't see it, request a resend
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={triggerEmail}>Resend E-mail</Button>
            <Button onClick={handleDialogClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Register;
