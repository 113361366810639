import http from "./HttpService";
import { authHeaders } from "../helper/AuthHelper";

const getAllRoles = () => {
  return http
    .get("getroles/", {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const getDashboardData = () => {
  return http
    .get("dashboard/", {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const addTenantMembers = (bodyFormData) => {
  return http
    .post("tenantmembers/", bodyFormData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const getAllTenants = (props) => {
  return http
    .get("tenantmembers/", {
      headers: authHeaders(),
      params: props,
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const removeTenant = (tenantId) => {
  return http
    .delete(`tenantmembers/${tenantId}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const resendMemberEmail = (postData) => {
  return http
    .post(`resend-tenant-invite/`, postData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

export default {
  getAllRoles,
  getDashboardData,
  addTenantMembers,
  getAllTenants,
  removeTenant,
  resendMemberEmail,
};
