import { styled } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import { Button } from "@mui/material";

const PrimaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(indigo[500]),
  backgroundColor: "#00023B",
  borderRadius: "24px",
  "&:hover": {
    backgroundColor: "#333462",
  },
  "&:disabled": {
    backgroundColor: "#899097",
    cursor:'pointer'
  },
}));

const SecondaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(indigo[500]),
  backgroundColor: "#6c757d",
  borderRadius: "24px",
  "&:hover": {
    backgroundColor: "#899097",
  },
}));

export { PrimaryButton, SecondaryButton };
