import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, Link, Stack } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dateTimeFormat } from '../../constants/Global.constants';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { ThreeP } from '@mui/icons-material';
import RFIService from './RFIService';
import projectService from '../../service/ProjectService';
import RFIAssignModel from './RFIAssignModel';
import RFISendBackModel from './RFISendBackModel';
import secureLocalStorage from 'react-secure-storage';
import { getUserInfoDetails } from '../../helper/AccessHelper';

const RFIDetails = () => {
    // Extracting project and RFI IDs from URL parameters
    let { project_id: projectId, rfi_id: rfiId } = useParams();
    const navigate = useNavigate();

    // Fetching user info from local storage
    const userParsed = getUserInfoDetails();

    // State variables
    const [isLoading, setLoading] = useState(false);
    const [rfiDetails, setRfiDetails] = useState({});
    const [buttonsDisabled, updateButtonStatus] = useState(true);
    const [btnAnwserDisabled, setBtnAnwserDisabled] = useState(true);
    const [openRFIAssignment, setOpenRFIAssignment] = useState(false);
    const [projectData, setProjectData] = useState({});
    const [openSendBack, setOpenSendBack] = useState(false);

    // Fetching RFI details and project details on component mount
    useEffect(() => {
        getRFIDetails();
        getProjectDetails();
    }, []);

    // Function to fetch RFI details
    const getRFIDetails = () => {
        RFIService.getRFIbyID(rfiId)
            .then((res) => {
                let refinedData = res;
                setRfiDetails(refinedData);
                // Enable action buttons based on user role and RFI status
                if (refinedData?.assigned_to[0]?.email === userParsed?.email && refinedData.status !== 5) {
                    updateButtonStatus(false);
                    setBtnAnwserDisabled(false);
                }
                if (refinedData?.requestor?.email === userParsed?.email && refinedData.status !== 5) {
                    setBtnAnwserDisabled(false)
                }
            })
            .catch((error) => {
                // Handle error
            });
    };

    // Function to close assignment and send back modals
    const handleClose = () => {
        setOpenRFIAssignment(false);
        setOpenSendBack(false);
        getRFIDetails(); // Refresh RFI details after modal close
    };

    // Function to fetch project details
    const getProjectDetails = () => {
        projectService.getProjectMembersById(projectId)
            .then((res) => {
                if (res?.data) {
                    let ProData = res?.data;
                    setProjectData(ProData);
                }
            })
            .catch((err) => {
                // Handle error
            });
    };

    // Function to close RFI
    const closeRFI = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("json_data", JSON.stringify({ status: 5 }));
        RFIService.updateRFIData(rfiId, formData)
            .then((response) => {
                if (response && response.data) {
                    setLoading(false);
                    handleClose(true);
                    updateButtonStatus(true);
                    setBtnAnwserDisabled(true);
                }
            })
            .catch((error) => {
                // Handle error
            });
    };

    // Render action buttons
    const renderActionBtn = () => {
        return (
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid
                    xs={12}
                    md={12}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 10 }}
                >
                    <Stack direction="row" spacing={2}>
                        <Button
                            className='mx-2'
                            variant="contained"
                            disabled={buttonsDisabled}
                            onClick={() => setOpenRFIAssignment(true)}
                        >
                            ReAssign
                        </Button>
                        <Button
                            variant="contained"
                            disabled={buttonsDisabled}
                            onClick={() => closeRFI()}
                        >
                            Close RFI
                        </Button>
                        <Button
                            variant="contained"
                            disabled={btnAnwserDisabled}
                            onClick={() => setOpenSendBack(true)}
                        >
                            Send Back/Answer
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            {/* Details RFI */}
            <div className="row mx-0 p-4">
                <div className="backgrd-white">
                    <div className="mb-2 d-flex">
                        <h3 className="submain-heading">Request for Information</h3>
                    </div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={`/rfi/${projectId}`}>
                            RFI Dashboard
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            href={`/rfi/${projectId}/${rfiId}`}
                            aria-current="page"
                        >
                            Details
                        </Link>
                    </Breadcrumbs>

                    <div className="row mx-0 mb-4">
                        {renderActionBtn()}

                        <VerticalTimeline className="vertical-timeline-custom-line">
                            {rfiDetails?.qa?.reverse().map((qa) => {
                            return (
                                <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentArrowStyle={{
                                    borderRight: "7px solid  rgb(33, 150, 243)",
                                }}
                                date={moment(qa.datetime).format(dateTimeFormat)}
                                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                                icon={<ThreeP />}
                                >
                                <h5 className="vertical-timeline-element-subtitle">
                                    {qa.user?.email}
                                </h5>

                                <p style={{ whiteSpace: "pre-line" }}>{qa.text}</p>
                                {qa.attachments.length > 0 && (
                                    <a href={qa.attachments[0]?.document} target="_blank" rel="noreferrer">
                                    Download Attachments
                                    </a>
                                )}
                                </VerticalTimelineElement>
                            );
                            })}
                        </VerticalTimeline>
                    </div>
                </div>
            </div>
            
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => {}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <RFIAssignModel
                show={openRFIAssignment}
                handleClose={handleClose}
                rfiData={rfiDetails}
                rfiID={rfiId}
                projectData={projectData}
                userParsed={userParsed}
            />
            <RFISendBackModel
                show={openSendBack}
                handleClose={handleClose}
                rfiID={rfiId}
                projectData={projectData}
            />
        </React.Fragment>
    );
};

export default RFIDetails;