import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function ExpectedOutcomeDialog({ handleDialogClose, show }) {
  const navigate = useNavigate();
  const [markup, updateMarkUp] = useState([
    { percent: "", data: moment().add(1, "y") },
    { percent: "", data: moment().add(1, "y") },
    { percent: "", data: moment().add(1, "y") },
  ]);
  const [outComeSelected, updateOutCome] = useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    updateOutCome({
      ...outComeSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const { gilad, jason, antoine } = outComeSelected;
  const error = [gilad, jason, antoine].filter((v) => v).length < 1;
  const outComeDialog = (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Welcome to BUILDIFY PRO"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            What is your desired outcome for using BUILDIFYPRO? at least one
            selection from below:
            <FormControl
              required
              error={error}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormLabel component="legend">Pick atleast one</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={gilad}
                      onChange={handleChange}
                      name="gilad"
                    />
                  }
                />
                Increase my profit to<TextField
                  variant="standard"
                /> percent on all my projects by the end of DATE
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jason}
                      onChange={handleChange}
                      name="jason"
                    />
                  }
                  label="Jason Killian"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={antoine}
                      onChange={handleChange}
                      name="antoine"
                    />
                  }
                  label="Antoine Llorca"
                />
              </FormGroup>
              <FormHelperText>You can display an error</FormHelperText>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Resend E-mail</Button>
          <Button onClick={handleDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  return outComeDialog;
}

export default ExpectedOutcomeDialog;
