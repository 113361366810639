import React, { useMemo, useRef, useState } from "react";
import GridTable from "../../components/DataGrid/GridTable";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Snackbar,
} from "@mui/material";

import {
  approvedCOStatus,
  orderChangeReasonEnum,
  orderStatusEnum,
  orderStatusList,
  rejectedCOStatus,
} from "../../constants/ChangeOrder.constant";
import { formatAmount } from "../../helper/ProjectHelper";
import { getUserInfoDetails } from "../../helper/AccessHelper";
import ChangeOrderService from "../../service/ChangeOrderService";
import PCORejectedDialog from "../PCO/PCORejectedDialog";

const COTable = ({ setLoading }) => {
  let { project_id: projectId } = useParams();
  const navigate = useNavigate();

  const childRef = useRef();

  // Fetching user info from local storage
  const userParsed = getUserInfoDetails();

  const [openRejcetDialog, setOpenRejcetDialog] = useState(false);
  const [selectionId, setSelectionId] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [PCODetails, setPCODetails] = useState({});
  const [statusType, setStatusType] = useState(0);
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);

  const handleStatusDialog = (status, id, pco) => {
    setStatusType(status);
    setOpenDeleteDialog(true);
    setSelectionId(id);
    setPCODetails(pco);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setSelectionId(0);
    setStatusType(0);
    setPCODetails({});
  };

  const handleClose = () => {
    setOpenRejcetDialog(false);
    handleCloseDelete();
  };

  const calculateTotalcost = (changeList) => {
    let changeCost = 0;
    changeList?.map((list) => {
      changeCost += parseFloat(list.amount);
    });
    return parseFloat(changeCost).toFixed(2);
  };

  const calculateApprovedTotalcost = (changeList, approvedCost) => {
    const propsCost = calculateTotalcost(changeList);
    const totalApprovedCost = Number(propsCost) + approvedCost;
    return formatAmount(totalApprovedCost);
  };

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "co_id",
        header: "CO ID#",
      },
      {
        accessorKey: "General.title",
        header: "CO Title",
      },
      {
        accessorKey: "id",
        header: "Status",
        Cell: ({ cell, row }) => {
          return orderStatusEnum[row?.original?.General?.status]?.name;
        },
        filterVariant: "select",
        filterSelectOptions: orderStatusList,
      },
      {
        accessorKey: "General.change_reason",
        header: "Change Reason",
        Cell: ({ cell, row }) => {
          console.log(row?.original?.General);
          return orderChangeReasonEnum[row?.original?.General?.change_reason]
            ?.name;
        },
      },
      {
        accessorKey: "assigned_to",
        header: "Appr Cost ",
        Cell: ({ cell, row }) => {
          return row.original?.General?.status === approvedCOStatus
            ? `${calculateApprovedTotalcost(
                row.original?.items,
                row.original?.Owner?.approved_cost
              )}`
            : "";
        },
      },
      {
        accessorKey: "assigned_toss",
        header: "Prop Cost ",
        Cell: ({ row }) =>
          formatAmount(calculateTotalcost(row.original?.items)),
      },
    ],
    []
  );

  // Define menu items for row actions
  const renderRowActionMenuItems = ({ closeMenu, row }) => [
    <MenuItem
      key={0}
      onClick={() => {
        navigate(
          `/editCO/${projectId}/${
            row.original?.Requestor?.role_on_the_project
          }/${Number(row.original.co_id)}`
        );
        closeMenu();
      }}
      sx={{ m: 0 }}
      disabled={
        row?.original?.General?.status === approvedCOStatus ||
        row?.original?.General?.status === rejectedCOStatus
      }
    >
      Edit
    </MenuItem>,
    <MenuItem
      key={0}
      onClick={() => {
        handleStatusDialog(3, Number(row?.original?.co_id), row.original);
        closeMenu();
      }}
      sx={{ m: 0 }}
      disabled={
        row?.original?.Requestor?.assigned_to.filter(
          (x) => x?.email === userParsed?.email
        ).length > 0 ||
        row?.original?.General?.status === approvedCOStatus ||
        row?.original?.General?.status === rejectedCOStatus
      }
    >
      Approve
    </MenuItem>,
    <MenuItem
      key={0}
      onClick={() => {
        handleStatusDialog(4, Number(row?.original?.co_id), row.original);
        closeMenu();
      }}
      sx={{ m: 0 }}
      disabled={
        row?.original?.General?.status === approvedCOStatus ||
        row?.original?.General?.status === rejectedCOStatus
      }
    >
      Reject
    </MenuItem>,
  ];

  // Function to fetch data from API
  const fetchDataFromAPI = async (params) => {
    return await ChangeOrderService.getAllPCO({
      ...params,
      is_co: "True",
      project_id: projectId,
    });
  };

  const handleStatusUpdate = (reason, isReason = false) => {
    if (selectionId && selectionId !== 0) {
      setLoading(true);
      let postData = { ...PCODetails };
      postData.General.status = statusType;
      postData.Requestor.assigned_to = [
        postData.Requestor.assigned_to[0].app_id,
      ];
      const postDataStatus = {
        status: statusType,
        is_co: "True",
      };
      if (isReason) {
        postDataStatus.reject_reason = reason;
        setOpenRejcetDialog(false);
      }
      const formData = new FormData();
      formData.append("json_data", JSON.stringify(postDataStatus));
      const co_id = PCODetails?.co_id || "";
      ChangeOrderService.updateChangeOrder(Number(co_id), formData)
        .then((response) => {
          setLoading(false);
          childRef.current.refreshTable();
          setOpenMessage({
            state: "success",
            message: response?.message,
            open: true,
          });
        })
        .catch((error) => {
          handleCloseDelete();
          setLoading(false);
          if (error)
            setOpenMessage({ state: "error", message: error, open: true });
        })
        .finally(() => {
          handleCloseDelete();
        });
    }
  };

  const handleConfirmChangeStatus = () => {
    if (statusType === 4) {
      setOpenDeleteDialog(false);
      setOpenRejcetDialog(true);
    } else {
      handleStatusUpdate();
    }
  };


  // Render the table component
  return (
    <>
      <GridTable
        columns={columns}
        renderRowActionMenuItems={renderRowActionMenuItems}
        apiCall={fetchDataFromAPI}
        ref={childRef}
      />
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      <PCORejectedDialog
        show={openRejcetDialog}
        handleClose={handleClose}
        handleSubmitUpdate={handleStatusUpdate}
        userParsed={userParsed}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {statusType == 3 ? "Approve" : "Reject"}{" "}
            the CO <b>"{PCODetails?.General?.title}"</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button className="cancel-button" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <button className="primarynext-button" onClick={handleConfirmChangeStatus}>
            OK
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default COTable;
