import React, { useState } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
import { dateTimeFormat } from "../../constants/Global.constants";
import moment from "moment";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  tableNoBorder: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
    marginTop: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColLog: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSmall: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol50: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol75: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColNoBorder: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol50NoBorder: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol100: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableCellBold: {
    margin: "auto",
    fontWeight: "bold",
    marginTop: 5,
    fontSize: 12,
  },
  tableCellId: {
    fontWeight: "bold",
    marginTop: 5,
    fontSize: 12,
    margin: "auto",
  },
});

const MyDoc = ({ pdfObj }) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        ~ buildifypro.com ~
      </Text>
      {/* <View style={{paddingBottom: 10}}>
        <Text style={styles.tableCellId}>RFI ID: <Text style={styles.tableCell}>{pdfObj?.app_id}</Text>
      </Text>
      </View> */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>REQUESTOR DETAILS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>RFI ID</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>RFI Title</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Company Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Submitted By</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Date Submitted</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>RFI-{pdfObj?.rfi_id}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.title}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.req_company_name}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.requestor?.email}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{moment(pdfObj?.submitted_on).format(dateTimeFormat)}</Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>RFI LOG</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColLog}>
            <Text style={styles.tableCellBold}>Date</Text>
          </View>
          <View style={styles.tableColLog}>
            <Text style={styles.tableCellBold}>User</Text>
          </View>
          <View style={styles.tableCol50}>
            <Text style={styles.tableCellBold}>
              Comment
            </Text>
          </View>
        </View>
        {pdfObj?.qa?.map((item) => {
          return <View style={styles.tableRow}>
            <View style={styles.tableColLog}>
              <Text style={styles.tableCell}>
                {moment(item.datetime).format(dateTimeFormat)}
              </Text>
            </View>
            <View style={styles.tableColLog}>
              <Text style={styles.tableCell}>{item.user?.email}</Text>
            </View>
            <View style={styles.tableCol50}>
              <Text style={styles.tableCell}>{item.text}</Text>
            </View>
          </View>
        })}
      </View>

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const PCOPDFGeneration = ({ pdfObj }) => <MyDoc pdfObj={pdfObj} />;
export default PCOPDFGeneration;
