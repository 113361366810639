import axios from "axios";
import { authHeaders } from "../helper/AuthHelper";

const API_URL = process.env.REACT_APP_WEATHER_API_URL;
const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;
const COUNTRY = 'USA';

const getWeatherCurrentByCountry = () => {
    const url = `${API_URL}current.json?key=${API_KEY}&q=${COUNTRY}&aqi=no`;
    return axios
        .get(url, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response?.data?.message;

        });
}

export {getWeatherCurrentByCountry};