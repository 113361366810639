import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  Grid,
  TextField,
  MenuItem,
  DialogActions,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { useEffect } from "react";
import TaskService from "../../service/TaskService";
import { projectStatusEnum } from "../../constants/Project.constants";
import { TaskPriorityEnum } from "../../constants/Task.constants";
import ProjectService from "../../service/ProjectService";
import { dateFormat } from "../../constants/Global.constants";
import { toast } from "react-toastify";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";
import ReactHookFormSelect from "../../components/bproSelect";

function TaskForm({
  handleClose,
  show,
  shouldResetForm,
  projectId,
  projectName,
  projStartDate,
  projEndDate,
  projectCount,
}) {
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [editId, setEditId] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [selectedProjectId, setProjectId] = useState();
  const [projectStartDate, setProjectStartDate] = useState(new Date());
  const [projectEndDate, setProjectEndDate] = useState(new Date());
  const schema = yup
    .object({
      task_name: yup.string().required("Task Name is required"),
      description: yup.string(),
      project_id: yup.string().required("Project is required"),
      start_date: yup
        .date()
        .default(() => new Date())
        .typeError("Invalid Date")
        .required("Start date is required"),
      due_date: yup
        .date()
        .typeError("Invalid Date")
        .required("Due date is required")
        .when(
          "start_date",
          (eventStartDate, schema) =>
            eventStartDate &&
            schema.min(
              eventStartDate,
              "Due date cannot be lesser than start date"
            )
        ),
      status: yup.string().required("Status is required"),
      priority: yup.string().required("Priority is required"),
      assigned_to: yup.string().required("Must Assign the task"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(shouldResetForm);
    if (shouldResetForm && shouldResetForm.project_id) {
      getProjectMembers(shouldResetForm.project_id);
      setProjectId(shouldResetForm?.project_id);
      setValue("project_id", shouldResetForm?.project_id);
      setProjectStartDate(shouldResetForm?.start_date);
      setProjectEndDate(shouldResetForm?.end_date);
      if (shouldResetForm.project_id != "") {
        getProjectMembers(shouldResetForm.project_id);
      }
      if (shouldResetForm.id) setEditId(shouldResetForm.id);
      else getProjectList();
    } else {
      getProjectList();
    }
  }, [shouldResetForm]);

  const getProjectList = () => {
    ProjectService.getAllProjects({ skip: 0, limit: 100 })
      .then((res) => {
        setProjectData(res?.data?.results);
        projectCount(res?.data?.results.length);
        setProjectId(shouldResetForm?.project_id);
        setValue("project_id", shouldResetForm?.project_id);
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const onSubmit = (data) => {
    if (editId) updateTaskData(data);
    else addnewTask(data);
  };

  const updateTaskData = (data) => {
    data.id = editId;
    data.start_date = moment(data?.start_date).format();
    data.due_date = moment(data?.due_date).format();
    TaskService.updateTask(data)
      .then((response) => {
        if (response && response.data) {
          reset();
          handleClose();
          setEditId(false);
          toast.info(response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        // setOpenMessage({ state: 'error', message: error, open: true })
      });
  };

  const addnewTask = (data) => {
    data.start_date = moment(data?.start_date).format();
    data.due_date = moment(data?.due_date).format();
    TaskService.addNewTask(data)
      .then((response) => {
        if (response && response.data) {
          reset();
          handleClose();
          toast.info(response?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        //setOpenMessage({ state: 'error', message: error, open: true })
      });
  };

  const getProjectMembers = (app_id) => {
    ProjectService.getProjectMembersById(app_id)
      .then((response) => {
        console.log(response);
        if (response && response.data) {
          if (response?.data.members && response?.data.members.length > 0) {
            const projectMembers = [];
            response.data.members.forEach((member) => {
              const currentMember = {
                app_id: member.user_id.app_id,
                email: member.user_id.email,
                first_name: member.user_id.first_name,
                last_name: member.user_id.last_name,
                role_name: member.role_id.role_name,
              };
              projectMembers.push(currentMember);
            });
            setMembersList(projectMembers);
            if (shouldResetForm && shouldResetForm?.assigned_to) {
              setTimeout(() => {
                setValue("assigned_to", shouldResetForm?.assigned_to);
              }, 1000);
            }
          }
          if (response.data?.project) {
            setProjectStartDate(response.data?.project?.start_date);
            setProjectEndDate(response.data?.project?.end_date);
          }
        }
      })
      .catch((error) => {
        //setOpenMessage({ state: 'error', message: error, open: true })
      });
  };

  const getProjectMembersFromDropdown = (event) => {
    setValue("project_id", event?.target?.value);
    setProjectId(event?.target?.value);
    if (event?.target?.value && !projectId)
      getProjectMembers(event?.target?.value);
    trigger("project_id");
  };

  const TaskForm = (
    <React.Fragment>
      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <h3 className="content-heading">
            {!editId ? `New Task` : `Edit Task`}
          </h3>
        </DialogTitle>
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <div className="private-form">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <form
                    className="form-private form-design"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-3">
                      <label for="task_name" className="mb-2">
                        Task Name<span>*</span>
                      </label>
                      <TextField
                        id="task_name"
                        placeholder="Task Name"
                        {...register("task_name")}
                      />
                      <p className="error-msg">{errors.task_name?.message}</p>
                    </div>
                    <div className="form-group mb-3">
                      <label for="description" className="mb-2">
                        Task description
                      </label>
                      <TextField
                        id="description"
                        className="form-control"
                        multiline
                        maxRows={4}
                        {...register("description")}
                      />
                      <p className="error-msg">{errors.description?.message}</p>
                    </div>
                    <div className="form-group select-box-field">
                      <label for="project_id" className="mb-2">
                        Project<span>*</span>
                      </label>
                      <TextField
                        id="project_id"
                        name="project_id"
                        select
                        onChange={getProjectMembersFromDropdown}
                        value={selectedProjectId}
                      >
                        {!projectId && projectData.length > 0 && (
                          <MenuItem value={""}>{""}</MenuItem>
                        )}
                        {projectId && (
                          <MenuItem value={projectId}>{projectName}</MenuItem>
                        )}
                        {!projectId &&
                          projectData.length > 0 &&
                          projectData.map((project) => {
                            return (
                              <MenuItem
                                key={project.app_id}
                                value={project.app_id}
                              >
                                {project.project_name}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                      <p className="error-msg">{errors.project_id?.message}</p>
                    </div>
                    <div className="form-group date-picker-input mb-3">
                      <label
                        for="start_date"
                        className="mb-2 mt-2 col-md-4 col-sm-12"
                      >
                        Task Start date<span>*</span>
                      </label>
                      <Controller
                        name="start_date"
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => {
                          return (
                            <DatePicker
                              minDate={moment(projectStartDate)}
                              maxDate={moment(projectEndDate)}
                              value={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                                trigger("start_date");
                              }}
                              variant="inline"
                              format={dateFormat}
                            />
                          );
                        }}
                      />
                      <p className="error-msg">{errors.start_date?.message}</p>
                    </div>
                    <div className="form-group date-picker-input mb-3">
                      <label
                        for="due_date"
                        className="mb-2 mt-2 col-md-4 col-sm-12"
                      >
                        Task Due date<span>*</span>
                      </label>
                      <Controller
                        name="due_date"
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => {
                          return (
                            <DatePicker
                              minDate={
                                getValues("start_date")
                                  ? getValues("start_date")
                                  : moment(projectStartDate)
                              }
                              maxDate={moment(projectEndDate)}
                              value={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              variant="inline"
                              format={dateFormat}
                            />
                          );
                        }}
                      />
                      <p className="error-msg">{errors.due_date?.message}</p>
                    </div>
                    {editId && (
                      <div className="form-group select-box-field">
                        <label for="exampleInputPassword1" className="mb-2">
                          Status<span>*</span>
                        </label>
                        <TextField
                          name="status"
                          {...register("status")}
                          select
                          defaultValue={shouldResetForm?.status}
                        >
                          {Object.keys(projectStatusEnum).map((type) => {
                            return (
                              <MenuItem
                                key={type}
                                value={projectStatusEnum[type].value}
                              >
                                {projectStatusEnum[type].name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <p className="error-msg">{errors.status?.message}</p>
                      </div>
                    )}
                    <div className="form-group select-box-field">
                      <label for="priority" className="mb-2">
                        Priority<span>*</span>
                      </label>
                      <TextField
                        id="priority"
                        name="priority"
                        {...register("priority")}
                        defaultValue={shouldResetForm?.priority}
                        select
                      >
                        <MenuItem value={""}>{""}</MenuItem>
                        {Object.keys(TaskPriorityEnum).map((type) => {
                          return (
                            <MenuItem
                              key={type}
                              value={TaskPriorityEnum[type].value}
                            >
                              {TaskPriorityEnum[type].name}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      <p className="error-msg">{errors.priority?.message}</p>
                    </div>
                    <div className="form-group select-box-field">
                      <label for="assigned_to" className="mb-2">
                        Assigend To<span>*</span>
                      </label>
                      <TextField
                        id="assigned_to"
                        name="assigned_to"
                        {...register("assigned_to")}
                        defaultValue={shouldResetForm?.assigned_to}
                        disabled={membersList.length === 0}
                        select
                      >
                        <MenuItem value={""}>{""}</MenuItem>
                        {membersList.map((member) => {
                          return (
                            <MenuItem key={member.app_id} value={member.app_id}>
                              {member.email}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <p className="error-msg">{errors.assigned_to?.message}</p>
                    </div>
                    <DialogActions>
                      <SecondaryButton type="button" onClick={handleClose}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton type="submit">{"Submit"}</PrimaryButton>
                    </DialogActions>
                  </form>
                </LocalizationProvider>
              </div>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return TaskForm;
}

export default TaskForm;
