import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  IconButton,
  Grid,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Paper,
  TableBody,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import UserService from "../../service/UserService";
import ProjectService from "../../service/ProjectService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import secureLocalStorage from "react-secure-storage";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";
const API_URL = process.env.REACT_APP_API_URL;

function ProjectMembersForm({ handleClose, show, shouldResetForm, projectId }) {
  const navigate = useNavigate();
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [tenantToken, setTenantToken] = useState("");
  const [tenantData, setTenantData] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [options, setOptions] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [memberError, setMemberError] = useState(true);
  const [selectedMember, setSelectedMember] = useState({
    email: "",
    app_id: "",
  });
  const previousController = useRef();
  const schema = yup
    .object({
      project_name: yup.string().required("Project Name is required"),
      members_list: yup.array(),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const token = secureLocalStorage.getItem("builderTenantId");
    setTenantToken(token);
    getRoleData();
  }, []);

  useEffect(() => {
    if (tenantToken) getTenantData();
  }, [tenantToken]);

  const getRoleData = () => {
    UserService.getAllRoles()
      .then((res) => {
        setRoleData(res.data);
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const getTenantData = () => {
    UserService.getAllTenants({ tenant_id: tenantToken })
      .then((res) => {
        let membersOnrolled = res?.data?.filter((mem) => mem.member !== null);
        let tenantDataFiltered = membersOnrolled.map((mem) => mem.member);
        setTenantData(tenantDataFiltered);
      })
      .catch((err) => {
        setOpenMessage({ state: "error", message: err, open: true });
      });
  };

  const validateForm = () => {
    if (membersList.length == 0) setMemberError("Select Project Members");
    else {
      let valid = true;
      membersList.forEach((member) => {
        if (!member.role || member.role == "") {
          valid = false;
        }
      });
      if (!valid) setMemberError("Project Members must have a role selected");
      else setMemberError(true);
    }
  };

  const onSubmit = () => {
    validateForm();
    if (memberError === true) {
      const postData = {
        project_id: projectId,
        members: membersList,
      };
      ProjectService.addProjectMembers(postData)
        .then((response) => {
          if (response && response.data) {
            handleClose();
            setOpenMessage({
              state: "success",
              message: response?.message,
              open: true,
            });
            //navigate(`/projectDetail/${response?.data?.project?.id}`)
          }
        })
        .catch((error) => {
          setOpenMessage({ state: "error", message: error, open: true });
        });
    }
  };

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    fetch(API_URL + `searchusers?search=` + searchTerm, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const updatedOptions = myJson.data.results.map((p) => {
          return { app_id: p.app_id, email: p.email };
        });
        setOptions(updatedOptions);
      });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setOptions([]);
    }
  };

  const onChange = (event, value, reason) => {
    if (value) {
      let existingList = membersList;
      let validateMember = existingList.filter(
        (list) => list.app_id === value.app_id
      );
      if (validateMember.length == 0) {
        existingList.push(value);
        setMembersList(existingList);
      }
      setSelectedMember({ email: "", app_id: "" });
    }
  };

  const updateMemberRole = (event, member_app_id) => {
    const updatedMemberRoleDate = membersList.map((obj) => {
      if (obj.app_id === member_app_id) {
        return { ...obj, role: event.target.value };
      }
      return obj;
    });

    setMembersList(updatedMemberRoleDate);
  };

  const chooseRole = (memberId) => {
    return (
      <TextField
        id="type"
        name="project_type"
        select
        size="small"
        sx={{'width':'70%'}}
        onChange={(event) => updateMemberRole(event, memberId)}
      >
        <MenuItem key={0} value={""}>
          {""}
        </MenuItem>
        {roleData.map((role) => {
          return (
            <MenuItem key={role.app_id} value={role.app_id}>
              {role.role_name}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const removeMember = (member_app_id) => {
    const updatedMemberRoleDate = membersList.filter(
      (obj) => obj.app_id !== member_app_id
    );
    setMembersList(updatedMemberRoleDate);
  };

  const ProjectMembersForm = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>

      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <h3 className="submain-heading">
            {tenantData.length > 0 ? `Add Project Members` : ""}
          </h3>
        </DialogTitle>
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description scrollbar-primary">
            <div className="col-lg-12 scrollbar-primary">
              {tenantData && tenantData.length === 0 && (
                <div className="row d-flex justify-content-between align-items-center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <p>No Team Members found to be added to the Project</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <PrimaryButton onClick={() => navigate(`/teams`)}>
                      Add Team Members
                    </PrimaryButton>
                  </div>
                </div>
              )}
              {tenantData && tenantData.length > 0 && (
                <div className="form-input-p">
                  <form className="form-sign-in pb-5">
                    <div className="form-group mb-3 search-member-form">
                      <h5 for="exampleInputEmail1" className="mb-2">
                        Search Member
                      </h5>
                      <Autocomplete
                        id="combo-box-demo"
                        options={tenantData}
                        fullWidth={true}
                        value={selectedMember}
                        //onInputChange={onInputChange}
                        onChange={onChange}
                        getOptionLabel={(option) => option.email}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                    <div className="member-selected-form">
                      <h5 className="mb-2">
                        Selected Members
                      </h5>
                      <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <h5>Member Email</h5>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <h5>Member Role</h5>
                              </TableCell>
                              <TableCell>
                                <h5>Action</h5>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {membersList.map((member) => {
                              return (
                                <TableRow key={member?.email}>
                                  <TableCell>
                                    <span className="email_align">
                                      {member?.email}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    {chooseRole(member?.app_id)}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      edge="end"
                                      className="btn_styles"
                                      aria-label="delete"
                                      onClick={() =>
                                        removeMember(member?.app_id)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <p className="error-msg">{memberError}</p>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <SecondaryButton type="button" onClick={handleClose}>
                          Cancel
                        </SecondaryButton>
                      </div>
                      <div className="col-6">
                        <PrimaryButton type="button" onClick={onSubmit}>
                          {"Submit"}
                        </PrimaryButton>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return ProjectMembersForm;
}

export default ProjectMembersForm;
