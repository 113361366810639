import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  projectTypeEnum,
  projectStatusEnum,
  memberTradeList,
} from "../../constants/Project.constants";
import { dateFormat } from "../../constants/Global.constants";
import UserService from "../../service/UserService";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

function TeamsMembersForm() {
  const navigate = useNavigate();
  let { member_type } = useParams();
  let { user_app_id } = useParams();
  console.log(member_type);
  const [tenantToken, setTenantToken] = useState("");
  const [memberTrade, setMemberTrade] = useState("");

  useEffect(() => {
    const token = secureLocalStorage.getItem("builderTenantId");
    setTenantToken(token);
  }, []);

  const schema = yup
    .object({
      first_name: yup
        .string()
        .min(3, "First Name must be at least 3 characters"),
      last_name: yup.string().min(3, "Last Name must be at least 3 characters"),
      invite_email: yup
        .string()
        .required("Email is required")
        .email("Email is invalid"),
      company_name: yup.string().required("Company Name is required"),
      company_address: yup.string().required("Company Address is required"),
      phone: yup
        .number()
        .typeError("Use only numbers")
        .required("Phone number is required")
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      title: yup.string().required("Title is required"),
      trade: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleTradeChange = (event) => {
    let newTradeVal = event.target.value;
    setValue("trade", newTradeVal);
    setMemberTrade(newTradeVal);
    trigger("trade");
  };

  const step1DataSubmit = async (data) => {
    const postData = {
      tenant_id: tenantToken,
      members: [
        {
          ...data,
          ...{
            is_internal: member_type === "addInternalMember" ? true : false,
          },
        },
      ],
    };
    let callFnService = UserService.addTenantMembers;
    if (user_app_id) {
      callFnService = UserService.addTenantMembers;
      data.id = user_app_id;
    }
    try {
      const getProjectRes = await callFnService(postData);
      console.log(getProjectRes);
      toast.info(getProjectRes?.data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "dark",
      });
      navigate("/teams");
      // if (
      //   getProjectRes.data &&
      //   getProjectRes.data.data &&
      //   getProjectRes.data.data.app_id
      // ) {
      //   navigate("projects");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const stepSubmit = (data) => {
    step1DataSubmit(data);
  };

  return (
    <div className="row mx-0 p-4">
      <div className="backgrd-white">
        <div className="mb-2 d-flex">
          <h3 className="submain-heading">Teams</h3>
        </div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/teams">Teams</Link>
          <p>
            {user_app_id ? "Edit" : "Create New"}{" "}
            {member_type === "addInternalMember" ? "Internal" : "External"}{" "}
            Member
          </p>
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className="mx-auto">
            <div className="row mx-auto">
              <div className="private-form">
                <h3 className="submain-heading">
                  {user_app_id ? "Edit" : "New"} {member_type === "addInternalMember" ? "Internal" : "External"}{" "} Member
                </h3>

                <form
                  className="form-private form-design"
                  onSubmit={handleSubmit(stepSubmit)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          First Name <i className="required">*</i>
                        </label>
                        <TextField
                          id="first_name-basic"
                          variant="outlined"
                          placeholder="First Name"
                          {...register("first_name")}
                        />
                        <p className="error-msg">
                          {errors.first_name?.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Last Name <i className="required">*</i>
                        </label>
                        <TextField
                          id="last_name"
                          variant="outlined"
                          placeholder="Last Name"
                          {...register("last_name")}
                        />
                        <p className="error-msg">{errors.last_name?.message}</p>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="form-group">
                    <div className="form-group">
                      <label className="mb-2">
                        Email address <i className="required">*</i>
                      </label>
                      <div className="form_icon">
                        <TextField
                          id="reg-invite_email"
                          variant="outlined"
                          {...register("invite_email")}
                        />
                      </div>
                      <p className="error-msg">
                        {errors.invite_email?.message}
                      </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label className="mb-2">
                        Company Name <i className="required">*</i>
                      </label>
                      <div className="form_icon">
                        <TextField
                          id="reg-company_name"
                          variant="outlined"
                          {...register("company_name")}
                        />
                      </div>
                      <p className="error-msg">
                        {errors.company_name?.message}
                      </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-2">
                      Company Address<i className="required">*</i>
                    </label>
                    <TextField
                      id="company_address"
                      variant="outlined"
                      placeholder="Address"
                      multiline
                      maxRows={4}
                      {...register("company_address")}
                    />
                    <p className="error-msg">
                      {errors.company_address?.message}
                    </p>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label className="mb-2">
                        Phone Number <i className="required">*</i>
                      </label>
                      <div className="form_icon">
                        <TextField
                          id="reg-phone"
                          variant="outlined"
                          {...register("phone")}
                        />
                      </div>
                      <p className="error-msg">{errors.phone?.message}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label className="mb-2">
                        Title/Function in Company <i className="required">*</i>
                      </label>
                      <div className="form_icon">
                        <TextField
                          id="reg-title"
                          variant="outlined"
                          {...register("title")}
                        />
                      </div>
                      <p className="error-msg">{errors.title?.message}</p>
                    </div>
                  </div>
                  {member_type === "addExternallMember" && (
                    <div className="form-group">
                      <InputLabel id="demo-simple-select-label">
                        Trade
                      </InputLabel>
                      <div className="select-dropdown">
                        <Select
                          labelId="trade_on_label"
                          id="trade_on"
                          label="Trade *"
                          value={memberTrade}
                          onChange={handleTradeChange}
                          variant="outlined"
                        >
                          {memberTradeList.map((trade) => {
                            return (
                              <MenuItem key={trade} value={trade}>
                                {trade}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                      <p className="error-msg">{errors.role?.message}</p>
                    </div>
                  )}
                  <div className="login-links-section text-right">
                    <button type="submit" className="primarynext-button w-auto">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default TeamsMembersForm;
