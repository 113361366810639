import React, { useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, Font, View, PDFViewer } from '@react-pdf/renderer';
import { dateFormat } from '../../../constants/Global.constants';
import moment from 'moment';
import { projectStatusEnum, projectTypeEnum } from "../../../constants/Project.constants";

function priceFormat(num) {
  return parseFloat(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
 // return `${parseFloat(num).toFixed(2)}`;
}

const calculateTotalcost = (changeList, isChangeOrder, percent, isTotalwPercent) => {
  let changeCost = 0;
  changeList?.map(list => {
    changeCost += parseFloat(list.amount);
  });
  if (isChangeOrder && percent > -1) {
    const markupValue = ((percent/ 100) * changeCost).toFixed(2);
    if (isTotalwPercent) {
      changeCost = changeCost + parseFloat(markupValue);
    }
  }
  return changeCost;
}

const calculateFeecost = (changeList, isChangeOrder, percent) => {
  let changeCost = 0;
  changeList?.map(list => {
    changeCost += parseFloat(list.amount);
  });
  let markupValue = 0;
  if (isChangeOrder && percent > -1) {
    markupValue = ((percent/ 100) * changeCost).toFixed(2);
  }
  return priceFormat(markupValue);
}

// const pdfObj = {
//   "project": "173bf14a-97c9-4c88-a16f-3bb9f9feff20",
//   "co_category": "56380ed2-8a1b-42d3-bb53-99f3ed7eeb9f",
//   "approved_by": [],
//   "projectDetails": {
//     "members": [
//       {
//         "user_id": {
//           "email": "aravind745698@yopmail.com",
//           "first_name": null,
//           "last_name": null,
//           "app_id": "5ff5a11b-2196-4dce-bade-8067fc38b0b7"
//         },
//         "role_id": {
//           "app_id": "13baa914-4b0a-4dad-bed2-652d3eb52e18",
//           "role_name": "Human Resources Manager",
//           "description": "Human Resources Manager role",
//           "added_at": "2023-09-30T00:00:00Z",
//           "updated_at": "2023-09-30T00:00:00Z"
//         }
//       },
//       {
//         "user_id": {
//           "email": "kalyanakumar741254578@yopmail.com",
//           "first_name": null,
//           "last_name": null,
//           "app_id": "0878de6d-2d7e-496f-a3a2-5cc67a9c3de2"
//         },
//         "role_id": {
//           "app_id": "9323f79d-99e1-49a7-871f-7865abd5eb3f",
//           "role_name": "Plumber",
//           "description": "Plumber",
//           "added_at": "2023-09-17T00:00:00Z",
//           "updated_at": "2023-09-17T00:00:00Z"
//         }
//       }
//     ],
//     "project": {
//       "app_id": "173bf14a-97c9-4c88-a16f-3bb9f9feff20",
//       "project_name": "Demo Files",
//       "description": "",
//       "start_date": "2023-12-15T15:04:42Z",
//       "end_date": "2023-12-31T15:04:42Z",
//       "status": 0,
//       "is_active": true,
//       "is_deleted": false,
//       "added_at": "2023-12-15T15:04:53.950742Z",
//       "updated_at": "2023-12-15T15:04:53.950742Z",
//       "project_type": "1",
//       "address": "Test",
//       "email_unique_id": null,
//       "client_id": "d57406ca-2cb0-4bc7-b07e-52305bddc0a2",
//       "project_leader": 2
//     }
//   },
//   "General": {
//     "title": "PCO Title",
//     "status": "1",
//     "change_reason": "1",
//     "description": "description",
//     "project_title": "Demo Files",
//     "project_site_address": "Test"
//   },
//   "Requestor": {
//     "company_name": "Company",
//     "submitted_by": "aravind@gmail.com",
//     "role_on_the_project": "56380ed2-8a1b-42d3-bb53-99f3ed7eeb9f",
//     "assigned_to": [
//       null
//     ],
//     "date_submitted": "01/07/2024",
//     "date_needed_by": "2024-01-30T18:30:00.000Z"
//   },
//   "Owner": {
//     "proposed_total_days_added": 12,
//     "approved_cost": 1245,
//     "owner_address": "Owner address",
//     "owner_phone": 78454,
//     "owner_name": "Owener Info"
//   },
//   "items": [
//     {
//       "id": 0,
//       "title": "tite",
//       "description": "desc",
//       "amount": 7458
//     }
//   ]
// };

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10
  },
  tableNoBorder: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
    marginTop: 20
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColSmall: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol50: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol75: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColNoBorder: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol50NoBorder: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol100: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10
  },
  tableCellBold: {
    margin: "auto",
    fontWeight: "bold",
    marginTop: 5,
    fontSize: 12
  }
});


const MyDoc = ({pdfObj, isChangeOrder}) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        ~ buildifypro.com ~
      </Text>
      <Text style={styles.title}>{pdfObj?.General?.title}</Text>
      <Text style={styles.author}>Project:{pdfObj?.projectDetails?.project?.project_name}</Text>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>PROJECT DETAILS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol50}>
            <Text style={styles.tableCellBold}>Project Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Project Type</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Date Created</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol50}>
            <Text style={styles.tableCell}>{pdfObj?.projectDetails?.project?.project_name}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{projectTypeEnum[pdfObj?.projectDetails?.project?.project_type]?.name}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{moment(pdfObj?.projectDetails?.project.added_at).format(dateFormat)}</Text>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>REQUESTOR DETAILS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>{isChangeOrder ? `CO` : `PCO`} Title</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Company Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Submitted By</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Date Submitted</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.General?.title}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.Requestor?.company_name}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.Requestor?.submitted_by}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.Requestor?.date_submitted}</Text>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>OWNER DETAILS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol50}>
            <Text style={styles.tableCellBold}>Owner Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Approved Cost</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>Estimated Days</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol50}>
            <Text style={styles.tableCell}>{pdfObj?.Owner?.owner_name}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{priceFormat(pdfObj?.Owner?.approved_cost)}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.Owner?.proposed_total_days_added}</Text>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>CHANGE ITEMS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColSmall}>
            <Text style={styles.tableCellBold}>Item Title</Text>
          </View>
          <View style={styles.tableColSmall}>
            <Text style={styles.tableCellBold}>Item Description</Text>
          </View>
          <View style={styles.tableColSmall}>
            <Text style={styles.tableCellBold}>Item Unit</Text>
          </View>
          <View style={styles.tableColSmall}>
            <Text style={styles.tableCellBold}>Item Rate</Text>
          </View>
          <View style={styles.tableColSmall}>
            <Text style={styles.tableCellBold}>Item Quantity</Text>
          </View>
          <View style={styles.tableColSmall}>
            <Text style={styles.tableCellBold}>Item Amount</Text>
          </View>
        </View>
        {pdfObj?.items?.map(item => {
          return <View style={styles.tableRow}>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCell}>{item?.title}</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCell}>{item?.description}</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCell}>{item?.unit}</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCell}>{priceFormat(item?.rate)}</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCell}>{item?.quantity}</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCell}>{priceFormat(item?.amount)}</Text>
            </View>
          </View>
        })}
        {isChangeOrder && <View style={styles.tableRow}>
          <View style={styles.tableCol75}>
            <Text style={styles.tableCellBold}>SubTotal</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>{priceFormat(calculateTotalcost(pdfObj.items, isChangeOrder, pdfObj.fee_mark_percent, false))}</Text>
          </View>
        </View>
        }
        {isChangeOrder && <View style={styles.tableRow}>
          <View style={styles.tableCol75}>
            <Text style={styles.tableCellBold}>Markup % ({pdfObj.fee_mark_percent}) Cost</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>{calculateFeecost(pdfObj.items, isChangeOrder, pdfObj.fee_mark_percent)}</Text>
          </View>
        </View>
        }
        <View style={styles.tableRow}>
          <View style={styles.tableCol75}>
            <Text style={styles.tableCellBold}>Proposed Additional Cost {isChangeOrder ? `(Subtotal)` : ``}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>{priceFormat(calculateTotalcost(pdfObj.items, isChangeOrder, pdfObj.fee_mark_percent, true))}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol75}>
            <Text style={styles.tableCellBold}>Project Approved Amount</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>{priceFormat(pdfObj?.Owner?.approved_cost)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol75}>
            <Text style={styles.tableCellBold}>Revised Contract Amount After Approval</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>{priceFormat(calculateTotalcost(pdfObj.items, isChangeOrder, pdfObj.fee_mark_percent, true) + parseFloat(pdfObj?.Owner?.approved_cost))}</Text>
          </View>
        </View>
        {
          (pdfObj?.items?.length === 0) &&
          <View style={styles.tableRow}>
            <View style={styles.tableCol50}>
              <Text style={styles.tableCell}>No Changes Found</Text>
            </View>
          </View>
        }
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
);

const PCOPDFGeneration = ({ pdfObj, isChangeOrder }) => (
  <MyDoc pdfObj={pdfObj} isChangeOrder={isChangeOrder} />
  // <div>
  //   <PDFDownloadLink document={<MyDoc pdfObj={pdfObj} />} fileName="somename.pdf">
  //     {({ blob, url, loading, error }) =>
  //       loading ? 'Loading document...' : 'Download now!'
  //     }
  //   </PDFDownloadLink>
  //   {/* <PDFViewer document={<MyDoc pdfObj={pdfObj} />} fileName="somename.pdf">
  //     {({ blob, url, loading, error }) =>
  //       loading ? 'Loading document...' : 'Download now!'
  //     }
  //   </PDFViewer> */}
  // </div>
);
export default PCOPDFGeneration;
