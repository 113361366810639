import React, { useState } from "react";
import "./login.css";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import  securesecureLocalStorage  from  "react-secure-storage";
import AuthService from "../../service/AuthService";

const schema = yup
  .object({
    email: yup.string().required("Email is required").email("Email is invalid"),
    password: yup.string().required("Password is required"),
  })
  .required();

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    AuthService.loginUser(data)
      .then((response) => {
        if (response && response.data) {
          let prevLogin = securesecureLocalStorage.getItem("tempRedirect");
          if (prevLogin) {
            navigate(prevLogin);
            securesecureLocalStorage.setItem("tempRedirect", false);
          } else
            setTimeout(() => {
              navigate("/projects");
            }, 1000);
        }
      })
      .catch((errors) => {});
  };
  return (
    <div className="row mx-auto h-100">
      <div className="public-form">
        <div className="text-center">
          <img src="/images/logo.svg" alt="BUILDIFYPRO" />
        </div>
        <h3>
          Welcome to BUILDIFY<span>PRO</span>
        </h3>
        <h6>
          Welcome back! login with your data that you entered during
          registration.
        </h6>
        <form
          className="form-signin form-design"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-group">
            <label className="mb-2">Email address</label>
            <div className="form_icon">
              <TextField
                id="login_email"
                variant="outlined"
                {...register("email")}
              />
              <span className="icons-pos">
                <img src="../images/at.svg" alt="Password" />
              </span>
            </div>
            <p className="error-msg">{errors.email?.message}</p>
          </div>
          <div className="form-group">
            <label className="mb-2">Password</label>
            <div className="form_icon">
              <TextField
                id="login_password"
                type="password"
                variant="outlined"
                {...register("password")}
              />
              <span className="icons-pos">
                <img src="../images/lock.svg" alt="Password" />
              </span>
            </div>
            <p className="error-msg">{errors.password?.message}</p>
          </div>
          <div className="row mb-3 mx-0 d-flex justify-content-between align-center">
            <div className="form-checkbox col p-0">
              {/* <Checkbox defaultChecked className="form-check-input mb-4" />
                            <label className="form-check-label" htmlFor="exampleCheck1"><span></span>Remember me</label> */}
            </div>
            <div className="forgotpasswd col p-0">
              <Link to="/forgotPassword">Forget your Password?</Link>
            </div>
          </div>
          <div className="login-links-section">
            <button type="submit" className="primary-button">
              LOGIN
            </button>
            <div className="signuplinks">
              Not registered yet? <Link to="/register">Create an Account</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
