const orderStatusEnum = {
    1: { name: "Submitted", value: 1 },
    2: { name: "Pending", value: 2 },
    3: { name: "Approved", value: 3 },
    4: { name: "Rejected", value: 4 },
    5: { name: "Deleted", value: 5 }
}

const orderStatusList = [
    { label: "Submitted", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Approved", value: 3 },
    { label: "Rejected", value: 4 },
    { label: "Deleted", value: 5 }
]

const approvedCOStatus = 3;
const rejectedCOStatus = 4;

const orderChangeReasonEnum = {
    1: { name: "Additions", value: 1 },
    2: { name: "Building Codes violations", value: 2 },
    3: { name: "Changes in Construction Sequence", value: 3 },
    4: { name: "Changes in Design", value: 4 },
    5: { name: "Changes in Materials", value: 5 },
    6: { name: "Changes in Methods", value: 6 },
    7: { name: "Changes in Project Schedule", value: 7 },
    8: { name: "Deletions", value: 8 },
    9: { name: "Design Flaws", value: 9 },
    10: { name: "Discrepancies in Contract", value: 10 },
    11: { name: "Impacts from other Traders or Labor", value: 11 },
    12: { name: "Owner Changes", value: 10 },
    13: { name: "Scope Change", value: 13 },
    14: { name: "Unforeseen Conditions", value: 14 },
    15: { name: "Weather", value: 15 },
}

const ItemUnits = ['Each', 'Square feet', 'Linear feet', 'Cubic yards']


export { orderStatusEnum, orderChangeReasonEnum, ItemUnits, approvedCOStatus, rejectedCOStatus, orderStatusList };