import React, { Children, useEffect, useState } from "react";
import "./sidebar.css";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import securesecureLocalStorage from "react-secure-storage";
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';

function SideBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("/");

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  const handleLogout = () => {
    securesecureLocalStorage.clear();
    navigate("/login");
  };
  return (
    <div className="row flex-nowrap p-0 m-0">
      <div className="sidebars p-0">
        <div className="d-flex flex-column align-items-center align-items-sm-start text-white sidebars-fixed">
          <span className="d-flex w-100">
            <a href="/" className="logo-img w-100">
              <img
                src="/images/logo.svg"
                alt="BUILDIFYPRO"
                width="180px"
                className="weblogo"
              />
              <img
                src="/images/logo-mobile.svg"
                alt="BUILDIFYPRO"
                width="36px"
                className="mobilelogo"
              />
            </a>
          </span>
          <div className="d-flex flex-column w-100 flex-column align-items-center justify-content-between h-100">
            <div className="w-100">
              <ul
                className="sidebar-menu flex-column mb-sm-auto mb-0 w-100 align-items-center align-items-sm-start w-100"
                id="menu"
              >
                <li className={
                    currentLocation.includes("dashboard") ? "active" : ""
                  }>
                  <Link to="/dashboard" className="nav-link px-0 align-middle">
                    <span className="menu-icons dashboard-icon"></span>
                    <span className="menu-text">Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    currentLocation.includes("projects") ? "active" : ""
                  }
                >
                  <Link to="/projects" className="nav-link px-0 align-middle">
                    <span className="menu-icons projects-icon"></span>
                    <span className="menu-text">Projects</span>
                  </Link>
                </li>
                <li
                  className={currentLocation.includes("tasks") ? "active" : ""}
                >
                  <Link to="/tasks" className="nav-link px-0 align-middle">
                    <span className="menu-icons tasks-icon"></span>
                    <span className="menu-text">Tasks</span>
                  </Link>
                </li>
                <li
                  className={currentLocation.includes("teams") ? "active" : ""}
                >
                  <Link to="/teams" className="nav-link px-0 align-middle">
                    <span className="menu-icons team-icon"></span>
                    <span className="menu-text">Team</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/dashboard" className="nav-link px-0 align-middle">
                    <span className="menu-icons schedules-icon"></span>
                    <span className="menu-text">Schedules</span>
                  </Link>
                </li> */}
                <li
                  className={
                    currentLocation.includes("communication") ? "active" : ""
                  }
                >
                  <Link
                    to="/communication"
                    className="nav-link px-0 align-middle"
                  >
                    <span className="menu-icons communications-icon"></span>
                    <span className="menu-text">Communications</span>
                  </Link>
                </li>
                <li
                  className={
                    currentLocation.includes("gallery") ? "active" : ""
                  }
                >
                  <Link
                    to="/gallery"
                    className="nav-link px-0 align-middle"
                  >
                    <CollectionsOutlinedIcon sx={{pr:1.7, pl:1.1  }}/>
                    <span className="menu-text">  Gallery</span>
                  </Link>
                </li>
                {/* <li>
                  <a href="#" className="nav-link px-0 align-middle">
                    <span className="menu-icons settings-icon"></span>
                    <span className="menu-text">Settings</span>
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="w-100">
              <ul className="sidebar-menu flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100">
                <li onClick={() => handleLogout()}>
                  <a href="#" className="nav-link px-0 align-middle">
                    <span className="menu-icons logout-icon"></span>
                    <span className="menu-text">Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="rightside p-0">
        <div className="row mb-4 mx-0 px-4 d-flex justify-content-between align-items-end">
          <div>
            <h6 className="header-names w-100 mb-2 mt-3">Hi, </h6>
            <h3 className="header-welcome w-100">
              Welcome to BUILDIFY<span>PRO</span>
            </h3>
          </div>
          <div className="right-header">
            <div className="dropdown">
              {/* <a
                href="#"
                className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/images/User.png"
                  alt="user"
                  width="68"
                  height="68"
                  className="rounded-circle"
                />
              </a> */}
            </div>
          </div>
        </div>

        {props.MainContent}
      </div>
    </div>
  );
}

export default SideBar;
