import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const defaultFileType = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "text/csv": [".csv"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/msword": [".doc"],
  "application/pdf": [".pdf"],
};

function StyledDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    props.uploadFiles(acceptedFiles);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: props?.accptedFilesTypes ? props?.accptedFilesTypes : defaultFileType,
    multiple: false,
    maxSize: "7340032", //7 mb
    onDrop: onDrop,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const UplodedFileItems =
    Array.isArray(props.fileData) &&
    props.fileData?.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

  const oldFiles = props.pcoData?.attachments?.map((file) => (
    <li key={file.document}>{file.document}</li>
  ));

  const uploadedFiles = props.uploadedDocuments?.map((file) => (
    <li key={file.document}>{file.document}</li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop a file here, or click to select file</p>
        {!props?.accptedFilesTypes || ("application/pdf" in props?.accptedFilesTypes) ? (
          <em>(supported format '.png','.jpeg','.pdf','.doc')</em>
        ) : (
          <em>(supported format '.png','.jpeg')</em>
        )}
        <ul>
          {props.pcoData?.attachments && props.pcoData?.attachments.length > 0
            ? oldFiles
            : ""}
          {UplodedFileItems.length > 0 ? UplodedFileItems : acceptedFileItems}
        </ul>
        <ul>
          {props.uploadedDocuments && props.uploadedDocuments.length > 0
            ? oldFiles
            : ""}
          {uploadedFiles}
        </ul>
      </div>
    </div>
  );
}

export default StyledDropzone;
