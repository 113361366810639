import React, { useMemo, useRef, useState } from "react";
import ProjectService from "../../service/ProjectService";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import {
  projectStatusEnum,
  projectStatusList,
  projectTypeEnum,
  projectTypeList,
} from "../../constants/Project.constants";
import { dateFormat } from "../../constants/Global.constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import GridTable from "../../components/DataGrid/GridTable";
import { Button } from "@mui/base";
import { toast } from "react-toastify";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";

const ProjectTable = ({ setProjectCount }) => {
  const navigate = useNavigate();
  const childRef = useRef();
  const [selectionId, setSelectionId] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});

  const handleDeleteDialog = (id, project) => {
    setOpenDeleteDialog(true);
    setSelectionId(id);
    setProjectDetails(project);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setSelectionId(0);
    setProjectDetails({});
  };

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "project_name",
        header: "PROJECT TITLE",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span
              className="pointer-cus"
              onClick={(event) =>
                navigate(`/projects/detail/${row?.original.app_id}`)
              }
            >
              {renderedCellValue}
            </span>
          );
        },
      },
      {
        accessorKey: "project_type",
        header: "PROJECT TYPE",
        Cell: ({ cell }) => {
          return projectTypeEnum[cell.getValue()]?.name;
        },
        filterVariant: "select",
        filterSelectOptions: projectTypeList,
      },
      {
        accessorKey: "status",
        header: "STATUS",
        Cell: ({ cell }) => {
          return (
            <Chip
              color={projectStatusEnum[cell.getValue()]?.className}
              label={projectStatusEnum[cell.getValue()]?.name}
            />
          );
        },
        filterVariant: "select",
        filterSelectOptions: projectStatusList,
      },
      {
        accessorKey: "start_date",
        header: "START DATE",
        Cell: ({ cell }) => moment(cell.getValue()).format(dateFormat),
      },
      {
        accessorKey: "end_date",
        header: "END DATE",
        Cell: ({ cell }) => moment(cell.getValue()).format(dateFormat),
      },
    ],
    []
  );

  // Define menu items for row actions
  const renderRowActionMenuItems = ({ closeMenu, row }) => [
    <MenuItem
      key={0}
      onClick={() => {
        navigate(`/projects/${row.id}`);
      }}
      sx={{ m: 0 }}
    >
      Edit
    </MenuItem>,
    <MenuItem
      key={1}
      onClick={() => {
        // navigate(`/projects/${row.id}`);
        handleDeleteDialog(row.original.app_id, row.original);
      }}
      sx={{ m: 0 }}
    >
      Delete
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        navigate(`/projectFiles/${row.id}`);
      }}
      sx={{ m: 0 }}
    >
      Project files
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        navigate(`/communication`);
      }}
      sx={{ m: 0 }}
    >
      Communications
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        navigate(`/pco/${row.id}`);
      }}
      sx={{ m: 0 }}
    >
      Potential Change Order
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        navigate(`/co/${row.id}`);
      }}
      sx={{ m: 0 }}
    >
      Change Order
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        navigate(`/rfi/${row.id}`);
      }}
      sx={{ m: 0 }}
    >
      Request For Information
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        navigate(`/dailylogs/${row.id}`);
      }}
      sx={{ m: 0 }}
    >
      Daily logs
    </MenuItem>,
  ];

  // Function to fetch data from API
  const fetchDataFromAPI = async (params) => {
    let totalData = await ProjectService.getAllProjects(params);
    setProjectCount(totalData?.data?.count);
    return totalData;
  };

  const handleDelete = () => {
    if (selectionId && selectionId !== 0) {
      handleCloseDelete();
      ProjectService.removeProject(selectionId)
        .then((response) => {
          toast.info(response?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
          childRef.current.refreshTable();
        })
        .catch((error) => {});
    }
  };

  // Render the table component
  return (
    <React.Fragment>
      <GridTable
        columns={columns}
        renderRowActionMenuItems={renderRowActionMenuItems}
        apiCall={fetchDataFromAPI}
        ref={childRef}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="submain-heading">
          {"Delete Project"}
        </DialogTitle>
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the Project{" "}
            <b>"{projectDetails.project_name}"</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleCloseDelete}>
            Cancel
          </SecondaryButton>
          <PrimaryButton  onClick={handleDelete}>
            OK
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ProjectTable;
