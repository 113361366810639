import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthService from "../../service/AuthService";
import { toast } from "react-toastify";

const schema = yup
  .object({
    password: yup.string().required("New Password is required").min(6),
    confirmpassword: yup
      .string()
      .required("Confirm Password is required")
      .min(6)
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    username: yup.string(),
    forget_pass_uuid: yup.string(),
  })
  .required();

function ResetPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const email = queryParams.get("user_email");
    let formData = {
      email: email,
      password: data.password,
      confirm_password: data.confirmpassword,
      token: token,
    };
    AuthService.changePassword(formData)
      .then((response) => {
        toast.info(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      })
      .catch((err) => {
        setOpenMessage({ state: "error", message: err, open: true });
      });
  };

  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);

  return (
    <div className="row mx-auto h-100">
      <div className="public-form">
        <div className="text-center">
          <img src="/images/logo.svg" alt="BUILDIFYPRO" />
        </div>
        <h3>
          Welcome to BUILDIFY<span>PRO</span>
        </h3>
        <h6>Enter your desired Password</h6>
        <form
          className="form-signin form-design"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-group">
            <label className="mb-2">New Password</label>
            <div className="form_icon">
              <TextField
                id="reset-pass"
                variant="outlined"
                type="password"
                {...register("password")}
              />
            </div>
            <p className="error-msg">{errors.password?.message}</p>
          </div>
          <div className="form-group">
            <label className="mb-2">Confirm Password</label>
            <div className="form_icon">
              <TextField
                id="confirm-pass"
                variant="outlined"
                type="password"
                {...register("confirmpassword")}
              />
            </div>
            <p className="error-msg">{errors.confirmpassword?.message}</p>
          </div>
          {/* <div className="row mb-3 mx-0 d-flex justify-content-between align-center">
            <div className="forgotpasswd col p-0">
              <Link to="/login" className="forgotpasswd col p-0">
                Return to Login
              </Link>
            </div>
          </div> */}
          <div className="login-links-section">
            <button type="submit" className="primary-button">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
