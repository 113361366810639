import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent, DialogContentText, Grid, Card, CardActionArea, CardHeader } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "../../service/UserService";

function PCOSelectionCard({ handleClose, show, projectId }) {
    const navigate = useNavigate();
    const [roleData, setRoleData] = useState([]);
    const definedRoleCards = ['Owner', 'General Contractor', 'Contractor'];
    useEffect(() => {
        getRoleData();
    }, []);

    const newPCO = (type) => {
        navigate(`/pco/${projectId}/create/${type}`);
    }

    const getRoleData = () => {
        UserService.getAllRoles().then((res) => {
            setRoleData(res.data.filter(data => definedRoleCards.indexOf(data.role_name) > -1));
        })
            .catch((err) => {
                if (err && err.msg) {
                }
            });
    }

    const PCOSelectionCardData = (<React.Fragment>
        <Dialog
            open={show}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle><h4 className="content-heading">{`New Potential Change Order`}</h4></DialogTitle>
            <DialogContent className="dialog-delete-block">
                <DialogContentText id="alert-dialog-description scrollbar-primary">
                    <div className="col-lg-12 scrollbar-primary">
                        <Grid
                            container
                            spacing={5}
                            sx={{ mb: 10 }}
                        >
                            {roleData.map(role => {
                                return <Grid item xs={12} md={4} >
                                    <Card elevation={3} sx={{ backgroundColor: "#EFF7FF" }}>
                                        <CardActionArea onClick={() => newPCO(role.app_id)}>
                                            <CardHeader
                                                title={role.role_name}
                                                subheader={role.description}
                                            // avatar={<PersonPinIcon sx={{ fontSize: 50 }} />}
                                            />
                                        </CardActionArea>
                                    </Card >
                                </Grid>
                            })}
                        </Grid>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </React.Fragment>)

    return (PCOSelectionCardData);
}

export default PCOSelectionCard;
