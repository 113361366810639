import React, { useEffect, useState } from "react";
import "./Conversation.css";
import {
  Alert,
  Avatar,
  Breadcrumbs,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import FileSystemService from "../../service/FileSystemService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dateTimeFormat } from "../../constants/Global.constants";
import ProjectService from "../../service/ProjectService";

const messageStyle = {
  messageBlue: {
    position: "relative",
    marginLeft: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#A8DDFD",
    width: "100%",
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #97C6E3",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #A8DDFD",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      left: "-15px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid #97C6E3",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      left: "-17px",
    },
  },
};

const ConversationDashboard = () => {
  const rowsPerPage = 20;
  const navigate = useNavigate();
  let { projectId } = useParams();
  const [selectedProject, setSelectedProject] = useState(null);
  const [page, setPage] = useState(1);
  const [projectPage, setProjectPage] = useState(0);
  const [loadMoreConversation, setLoadMoreConversation] = useState(true);
  const [loadMoreProject, setLoadMoreProject] = useState(true);
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [conversationList, setConversationList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    if (projectId) {
      getProjectConversation(projectId);
      getProjectDetails();
    } else {
      getAllProjects();
    }
  }, []);


  const getProjectDetails = () => {
    ProjectService.getProjectById(projectId)
      .then((res) => {
        if (res?.data) {
          setLoadMoreProject(false);
          setProjectList([
            {
              project_name: res.data.project_name,
              app_id: res.data.app_id,
              active: true,
            },
          ]);
        }
      })
      .catch((error) => {
        if (error)
          setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  const getAllProjects = () => {
    ProjectService.getAllProjects({
      limit: rowsPerPage,
      skip: rowsPerPage * projectPage,
      status: null,
    })
      .then((res) => {
        if (res?.data) {
          console.log(res?.data);
          if (res?.data?.count > 0) {
            setProjectPage(page + 1);
            setLoadMoreProject(true);
            setProjectList([...projectList, ...res.data.results]);
          } else setLoadMoreProject(false);
        } else {
          setLoadMoreProject(false);
        }
      })
      .catch((error) => {
        setLoadMoreProject(false);
        if (error)
          setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  const fetchMoreData = () => {
    if (projectId) getProjectConversation(projectId);
    else if (selectedProject) getProjectConversation(selectedProject);
  };

  const fetchMoreProject = () => { };

  const loadNewConversation = (project_id) => {
    setSelectedProject(project_id);
    setPage(1);
    setConversationList([]);
    getProjectConversation(project_id);
  };

  const getProjectConversation = (projId) => {
    FileSystemService.getProjectConversation({
      page: page,
      limit: rowsPerPage,
      project_id: projId,
    })
      .then((res) => {
        if (res?.data) {
          if (res?.data?.count > 0) {
            setPage(page + 1);
            // loadMoreConversation(true);
            setConversationList([...conversationList, ...res.data.results]);
          } else setLoadMoreConversation(false);
        } else {
          setLoadMoreConversation(false);
        }
      })
      .catch((error) => {
        setLoadMoreConversation(false);
        if (error)
          setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  const getSelName = () => {
    let section = projectList.filter((proj) => proj.app_id === selectedProject);
    if (section.length > 0) return section[0].project_name;
  };

  const chatDesktop = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      <div className="row mx-0 p-4">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={4} className="mx-auto">
            <div className="backgrd-white border-1">
              <h3 className="submain-heading">Communications</h3>
              {projectId && (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/projects" className="pointer-cus">
                    Projects
                  </Link>
                  <Link to={'/projects/detail/' + projectId} className="pointer-cus">
                    Project details
                  </Link>
                  <p>
                    {projectList[0]?.project_name}
                  </p>
                </Breadcrumbs>
              )}
              <div className="mt-3 row chat-details">
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    position: "relative",
                    // overflow: 'auto',
                    // maxHeight: '69vh',
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <InfiniteScroll
                    dataLength={projectList.length}
                    next={fetchMoreProject}
                    hasMore={loadMoreProject}
                    height={400}
                    loader={
                      projectList.length > 0 ? <h4>Loading...</h4> : <p></p>
                    }
                    endMessage={
                      projectList.length > 0 ? (
                        <p style={{ textAlign: "center" }}>
                          <b>No more Project to load</b>
                        </p>
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          <b>No Data found</b>
                        </p>
                      )
                    }
                  >
                    {projectList.map((project) => (
                      <div className="userchatbox">
                        <ListItem
                          key={`item-${project?.app_id}`}
                          onClick={() => {
                            loadNewConversation(project?.app_id);
                          }}
                        >
                          <ListItemButton
                            autoFocus={true}
                            divider={true}
                            selected={true}
                          >
                            <div className="d-flex flex-row chatboxdts activechat">
                              <div className="chatlist-image">
                                <img src="../images/Mask group.svg" alt="" />
                              </div>
                              <div className="chatlist-names">
                                <h3>
                                  <ListItemText
                                    primary={`${project?.project_name}`}
                                  />
                                </h3>
                                <h5>#${project?.address}</h5>
                              </div>
                            </div>
                          </ListItemButton>
                        </ListItem>
                      </div>
                    ))}
                  </InfiniteScroll>
                </List>


              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                position: "relative",
                // overflow: 'auto',
                // maxHeight: '70vh',
                "& ul": { padding: 0 },
              }}
              subheader={<li />}
            >
              <div className="d-flex flex-row chatboxdts separatechatbot p-4">
                <div className="d-flex flex-row align-items-center">
                  <div className="chatlist-image">
                    <img src="../images/Mask group.svg" alt="" />
                  </div>
                  <div className="chatlist-names">
                    <h3>{getSelName()}</h3>
                  </div>
                </div>
                <div className="chatlist-times">
                  {/* <span> <img src="../images/info-icon.svg" alt="" /></span> */}
                  <span className="d-inline-block">&nbsp;</span>
                </div>
              </div>
              <div className="todays-chat">
                <h3>Today</h3>
              </div>
              <InfiniteScroll
                dataLength={conversationList.length}
                next={fetchMoreData}
                hasMore={loadMoreConversation}
                height={300}
                loader={
                  conversationList.length > 0 ? <h4>Loading...</h4> : <p></p>
                }
                endMessage={
                  conversationList.length > 0 ? (
                    <p style={{ textAlign: "center" }}>
                      <b>No more Message to load</b>
                    </p>
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      <b>No Data found</b>
                    </p>
                  )
                }
              >
                {conversationList.map((message, index) => {
                  return (
                    <div className="communi-box">
                      <div className="blue-chat-border">
                        <ListItem
                          key={index}
                          style={messageStyle.messageBlue}
                          divider={true}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="d-flex flex-row mb-20">
                                <div className="chatlist-image">
                                  <img
                                    src="../images/Mask group.svg"
                                    width="26px"
                                    height="26px"
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="chat-msg">
                                    <ListItemText
                                      align="left"
                                      primary={message?.body}
                                    ></ListItemText>
                                  </div>
                                  <div className="date_time">
                                    <ListItemText
                                      align="left"
                                      secondary={moment(
                                        message.created_at
                                      ).format(dateTimeFormat)}
                                    ></ListItemText>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {/* <ListItem
                          key={index}
                          style={messageStyle.messageBlue}
                          divider={true}
                          className="rightchat-section"
                        >
                          <Grid cont-ner>
                            <Grid item xs={12}>
                              <div className="d-flex flex-row mb-20 rightchatdivs">
                                <div className="d-flex flex-column">
                                  <div className="chat-msg">
                                    <ListItemText
                                      align="left"
                                      primary={message?.body}
                                    ></ListItemText>
                                  </div>
                                  <div className="date_time">
                                    <ListItemText
                                      align="left"
                                      secondary={moment(
                                        message.created_at
                                      ).format(dateTimeFormat)}
                                    ></ListItemText>
                                  </div>
                                </div>
                                <div className="chatlist-image">
                                  <img
                                    src="../images/Mask group.svg"
                                    width="26px"
                                    height="26px"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </ListItem> */}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
              {/* <div className="chating-box">
              <textarea className="message-input" placeholder="Type a message..."></textarea>
              <div className="icons-set-btns">
                <button className="icon-button"><img src="../images/send-icon.svg" width="26px" height="26px" alt="" /></button>
                <button className="icon-button"><img src="../images/attach-icon.svg" width="26px" height="26px" alt="" /></button>
                <button className="icon-button"><img src="../images/check.svg" width="26px" height="26px" alt="" /></button>
              </div>
            </div> */}
            </List>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );

  return <div>{chatDesktop}</div>;
};

export default ConversationDashboard;
