import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { dateFormat } from "../../../constants/Global.constants";
import moment from "moment";
import UserService from "../../../service/UserService";
import { useParams } from "react-router-dom";

const PCOStep2 = ({ step, setStep, PCOData, setPCOData, isChangeOrder }) => {
  let { type: typeId } = useParams();
  const [roleData, setRoleData] = useState([]);
  const [projectRole, setProjectRole] = useState("");
  const schema = yup
    .object({
      date_submitted: yup.string(),
      date_needed_by: yup.object().required("Date Needed By is Required"),
      company_name: yup.string().required("Company Name is Required"),
      submitted_by: yup.string().required("Submitted By is Required"),
      role_on_the_project: yup
        .string()
        .required("Role On The Project is Required"),
      assigned_to_list: yup.array().required("Assigned To is Required"),
    })
    .required();
  const [value, setValueD] = useState([
    { id: "10", text: "One" },
    { id: "20", text: "Two" },
  ]);

  const constrequestorValue = PCOData?.Requestor;
  if (constrequestorValue?.submitted_by?.email) {
    constrequestorValue.submitted_by = constrequestorValue?.submitted_by?.email;
  }
  const formdefaultValue = constrequestorValue;

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formdefaultValue,
  });

  useEffect(() => {
    getRoleData();
    setValue("date_submitted", moment().format(dateFormat));
  }, []);

  const getRoleData = () => {
    UserService.getAllRoles()
      .then((res) => {
        setRoleData(res.data);
        if (typeId) {
          if (isChangeOrder && PCOData?.Requestor?.role_on_the_project) {
            setProjectRole(PCOData?.Requestor?.role_on_the_project);
            setValue(
              "role_on_the_project",
              PCOData?.Requestor?.role_on_the_project
            );
          } else {
            setProjectRole(typeId);
            setValue("role_on_the_project", typeId);
          }
          trigger("role_on_the_project");
        }
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const handleRoleChange = (event) => {
    let newRoleVal = event.target.value;
    setValue("role_on_the_project", newRoleVal);
    setProjectRole(newRoleVal);
    trigger("role_on_the_project");
  };

  const onSubmit = (data) => {
    setPCOData({
      type: "UPDATE_REQUESTOR_INFO",
      payload: data,
    });
    setStep(step + 1);
  };

  const disableFields = () => {
    if (PCOData?.General?.status >= 3) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <form className="form-sign-in pb-5" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="date_submitted"
                  placeholder="Date Submitted *"
                  label="Date Submitted *"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={disableFields()}
                  defaultValue={moment().format(dateFormat)}
                  variant="outlined"
                  {...register("date_submitted")}
                />
                <p className="error-msg">{errors.date_submitted?.message}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="date_needed_by"
                  control={control}
                  defaultValue={PCOData?.Requestor?.date_needed_by}
                  render={({ field, ...props }) => {
                    return (
                      <DatePicker
                        value={field.value}
                        minDate={moment().add(1, "days")}
                        placeholder="Date needed By *"
                        label="Date needed By *"
                        slotProps={{ textField: { fullWidth: true } }}
                        onChange={(date) => {
                          field.onChange(date);
                          trigger("date_needed_by");
                        }}
                        disabled={disableFields()}
                        variant="outlined"
                        format={dateFormat}
                      />
                    );
                  }}
                />
                <p className="error-msg">{errors.date_needed_by?.message}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="company_name"
                  label="Company Name *"
                  disabled={disableFields()}
                  placeholder="Company Name *"
                  {...register("company_name")}
                  fullWidth
                  variant="outlined"
                />
                <p className="error-msg">{errors.company_name?.message}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="submitted_by"
                  label="Submitted By *"
                  placeholder="Submitted By *"
                  {...register("submitted_by")}
                  fullWidth
                  variant="outlined"
                  disabled
                />
                <p className="error-msg">{errors.submitted_by?.message}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="role_on_the_project_label">
                    Role on the Project *
                  </InputLabel>
                  <Select
                    labelId="role_on_the_project_label"
                    id="role_on_the_project"
                    label="Role on the Project *"
                    value={projectRole}
                    disabled={disableFields()}
                    onChange={handleRoleChange}
                    variant="outlined"
                  >
                    {roleData.map((role) => {
                      return (
                        <MenuItem key={role.app_id} value={role.app_id}>
                          {role.role_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <p className="error-msg">
                  {errors.role_on_the_project?.message}
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="assigned_to_list"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <Autocomplete
                        label="Assigned To *"
                        multiple
                        options={PCOData?.projectDetails?.members}
                        getOptionLabel={(option) => option?.email}
                        placeholder="Assigned To *"
                        variant="outlined"
                        defaultValue={PCOData?.Requestor?.assigned_to_list}
                        //defaultValue={value}
                        getOptionSelected={(option, value) => {
                          return option.user_id?.email === value.email;
                        }}
                        disabled={disableFields()}
                        //getOptionSelected={(option, value) => value.id === option.id}
                        onChange={(event, value) => {
                          field.onChange(value);
                          trigger("assigned_to_list");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Assigned To *" />
                        )}
                      />
                    );
                  }}
                />
                <p className="error-msg">{errors.assigned_to_list?.message}</p>
              </Grid>
            </Grid>
            <Grid
              sx={{ mt: 4 }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="row">
                <div className="col-6">
                  <button
                    id="pco_step2_previous"
                    type="submit"
                    className="secondary-button"
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    {"Previous"}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    id="pco_step2_submit"
                    type="submit"
                    className="primarynext-button"
                  >
                    {"Next"}
                  </button>
                </div>
              </div>
            </Grid>
          </form>
        </LocalizationProvider>
      </Container>
    </React.Fragment>
  );
};

export default PCOStep2;
