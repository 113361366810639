const rfiStatusEnum = {
    1: { name: "New", value: 1 },
    2: { name: "SEND BACK", value: 2 },
    3: { name: "APPROVED", value: 3 },
    4: { name: "REJECTED", value: 4 },
    5: { name: "CLOSED", value: 5 }
}

const rfiStatusList = [
    { label: "New", value: 1 },
    { label: "SEND BACK", value: 2 },
    { label: "APPROVED", value: 3 },
    { label: "REJECTED", value: 4 },
    { label: "CLOSED", value: 5 }
]

export { rfiStatusEnum, rfiStatusList };