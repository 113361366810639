import {
  Autocomplete,
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { dateFormat } from "../../constants/Global.constants";
import StyledDropzone from "../../components/DropZone/DropZone";
import UserService from "../../service/UserService";
import projectService from "../../service/ProjectService";
import RFIService from "./RFIService";
import { getUserInfoDetails } from "../../helper/AccessHelper";

const RFIForm = () => {
  // Extracting project_id from URL params
  let { project_id: projectId } = useParams();
  const navigate = useNavigate();

  // Fetching user info from local storage
  const userParsed = getUserInfoDetails();

  // State variables
  const [savedFile, setSavedFile] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [projectData, setProjectData] = useState({ members: [] });
  const [isLoading, setLoading] = useState(false);

  // Form validation schema
  const schema = yup
    .object({
      title: yup.string().required("RFI title is required"),
      requestor_name: yup.string(),
      requestor_email: yup.string(),
      req_company_name: yup.string(),
      reqestor_role: yup.string().required("Role On The Project is Required"),
      requestor_phone_no: yup
        .number()
        .typeError("Use only numbers")
        .nullable()
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      project_site_address: yup.string(),
      assigned_to: yup.object().required("Assigned To is Required"),
      resp_needed_by: yup.object().required("Date Needed By is Required"),
      question: yup.string().required("Question is required"),
    })
    .required();

  // React Hook Form initialization
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Effect hook to fetch project details on component mount
  useEffect(() => {
    if (projectId) {
      getProjectDetails();
    }
  }, []);

  // Function to fetch role data
  const getRoleData = (ProData) => {
    UserService.getAllRoles()
      .then((res) => {
        setRoleData(res.data);
        let userRole = ProData?.members.filter(
          (mem) => mem?.user_id?.email === userParsed.email
        );
        if (userRole.length > 0) {
          const roleAppId = userRole[0]?.role_id?.app_id;
          setValue("reqestor_role", roleAppId);
          trigger("reqestor_role");
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          // Handle error
        }
      });
  };

  // Function to fetch project details
  const getProjectDetails = () => {
    projectService
      .getProjectMembersById(projectId)
      .then((res) => {
        if (res?.data) {
          let ProData = res?.data;
          setProjectData(ProData);
          setValue("project_name", ProData?.project?.project_name);
          setValue("project_address", ProData?.project?.address);
          setValue("requestor_email", userParsed?.email);
          getRoleData(ProData);
        }
      })
      .catch((err) => {
        if (err && err.msg) {
          // Handle error
        }
      });
  };

  // Function to handle file uploads
  const uploadFiles = (file) => {
    setSavedFile((preState) => [...preState, ...file]);
  };

  // Function to handle form submission
  const onSubmit = (data) => {
    data.requestor = userParsed?.user_id;
    data.assigned_to = [data.assigned_to?.user_id?.app_id];
    data.qa = [
      {
        text: data.question,
        is_question: true,
      },
    ];
    data.project = projectId;
    data.is_deleted = false;
    data.status = 1;
    delete data.requestor_name;
    const formData = new FormData();
    formData.append("json_data", JSON.stringify(data));
    if (savedFile.length > 0) {
      savedFile.map((file) => {
        formData.append("attachments", file, file.name);
      });
    }
    setLoading(true);

    // api call
    RFIService.createRFI(formData)
      .then((response) => {
        if (response && response.data) {
          setLoading(false);
          navigate(`/rfi/${projectId}`);
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  return (
    <div className="row mx-0 p-4">
      {/* Breadcrumb navigation */}
      <div className="backgrd-white">
        <div className="mb-2 d-flex">
          <h3 className="submain-heading">Request for Information</h3>
        </div>
        <Breadcrumbs aria-label="breadcrumb">
          {/* Breadcrumb links */}
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className="mx-auto">
            <div className="row mx-auto">
              {/* RFI form */}
              <div className="private-form w-50" style={{ flex: "0.5" }}>
                {/* <h3 className="submain-heading">New RFI</h3> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <form
                    className="form-private form-design"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <h4 className="mb-2"> Project Information </h4>
                    <div className="form-group">
                      <InputLabel>Project Title</InputLabel>
                      <Controller
                        name="project_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="title"
                            variant="outlined"
                            placeholder="Project Name"
                            {...register("project_name")}
                          />
                        )}
                      />
                      <p className="error-msg">
                        {errors.project_name?.message}
                      </p>
                    </div>

                    <div className="form-group">
                      <label className="mb-2">
                        Project Address<i className="required">*</i>
                      </label>
                      <Controller
                        name="project_address"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled
                            id="project-address"
                            variant="outlined"
                            placeholder="Address"
                            multiline
                            maxRows={4}
                            {...register("project_address")}
                          />
                        )}
                      />
                      <p className="error-msg">
                        {errors.project_address?.message}
                      </p>
                    </div>

                    <h4 className="mb-2"> Requestor Information </h4>

                    <div className="form-group">
                      <InputLabel>
                        Title <i className="required">*</i>
                      </InputLabel>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="title"
                            variant="outlined"
                            placeholder="Title"
                            {...register("title")}
                          />
                        )}
                      />
                      <p className="error-msg">{errors.title?.message}</p>
                    </div>
                    <div className="form-group">
                      <InputLabel>
                        Requestor Name
                      </InputLabel>
                      <Controller
                        name="requestor_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="requestor_name"
                            variant="outlined"
                            placeholder="Requestor Name"
                            value={value}
                            {...register("requestor_name")}
                          />
                        )}
                      />
                      <p className="error-msg">
                        {errors.requestor_name?.message}
                      </p>
                    </div>

                    <div className="form-group">
                      <InputLabel>
                        Requestor Email <i className="required">*</i>
                      </InputLabel>
                      <Controller
                        name="requestor_email"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="requestor_email"
                            disabled
                            variant="outlined"
                            placeholder="Requestor Email"
                            value={value}
                            {...register("requestor_email")}
                          />
                        )}
                      />
                      <p className="error-msg">
                        {errors.requestor_email?.message}
                      </p>
                    </div>

                    <div className="form-group">
                      <InputLabel>Company Name</InputLabel>
                      <Controller
                        name="req_company_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="req_company_name"
                            variant="outlined"
                            placeholder="Requestor Company Name"
                            value={value}
                            {...register("req_company_name")}
                          />
                        )}
                      />
                      <p className="error-msg">
                        {errors.req_company_name?.message}
                      </p>
                    </div>

                    <div className="form-group">
                      <InputLabel>
                        Role on the Project <i className="required">*</i>
                      </InputLabel>
                      <div className="select-dropdown">
                        <Controller
                          name="reqestor_role"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              labelId="requestor_role_label_label"
                              id="reqestor_role"
                              select
                              value={value}
                              {...register("reqestor_role")}
                            >
                              {roleData.map((role) => {
                                return (
                                  <MenuItem
                                    key={role.app_id}
                                    value={role.app_id}
                                  >
                                    {role.role_name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          )}
                        />
                        <p className="error-msg">
                          {errors.reqestor_role?.message}
                        </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <InputLabel>Phone</InputLabel>
                      <Controller
                        name="requestor_phone_no"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="requestor_phone_no"
                            variant="outlined"
                            placeholder="Requestor Phone"
                            value={value}
                            {...register("requestor_phone_no")}
                          />
                        )}
                      />
                      <p className="error-msg">
                        {errors.requestor_phone_no?.message}
                      </p>
                    </div>

                    <div className="form-group">
                      <InputLabel>
                        Assigned To <i className="required">*</i>
                      </InputLabel>
                      <Controller
                        name="assigned_to"
                        control={control}
                        render={({ field, ...props }) => {
                          return (
                            <Autocomplete
                              // label="Assigned To *"
                              options={projectData?.members}
                              getOptionLabel={(option) =>
                                option?.user_id?.email
                              }
                              placeholder="Assigned To *"
                              variant="outlined"
                              getOptionSelected={(option, value) => {
                                return option.user_id?.email === value.email;
                              }}
                              onChange={(event, value) => {
                                field.onChange(value);
                                trigger("assigned_to");
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          );
                        }}
                      />
                      <p className="error-msg">{errors.assigned_to?.message}</p>
                    </div>

                    <div className="form-group">
                      <label className="mb-2">
                        Date needed By <i className="required">*</i>
                      </label>
                      <Controller
                        name="resp_needed_by"
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => {
                          return (
                            <DatePicker
                              id="resp_needed_by"
                              value={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                                trigger("resp_needed_by");
                              }}
                              variant="inline"
                              format={dateFormat}
                            />
                          );
                        }}
                      />
                      <p className="error-msg">
                        {errors.resp_needed_by?.message}
                      </p>
                    </div>

                    <div className="form-group">
                      <label className="mb-2">
                        Question <i className="required">*</i>
                      </label>
                      <Controller
                        name="question"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="question"
                            variant="outlined"
                            placeholder="Question"
                            multiline
                            maxRows={4}
                            fullWidth
                            value={value}
                            {...register("question")}
                          />
                        )}
                      />
                      <p className="error-msg">{errors.question?.message}</p>
                    </div>

                    <div className="form-group">
                      <label for="attachments" className="mb-2">
                        Attachments
                      </label>
                      <StyledDropzone
                        id="attachments"
                        uploadFiles={uploadFiles}
                        fileData={savedFile}
                      />
                    </div>

                    <div className="login-links-section text-right">
                      <button
                        type="button"
                        className="secondary-button w-auto mr-3"
                        onClick={() => navigate(`/rfi/${projectId}`)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="primarynext-button w-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </LocalizationProvider>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* Loading indicator */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default RFIForm;
