import React, { useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./dashboard.css";
import {
  BarChart,
  LineChart,
  PieChart,
  mangoFusionPalette,
} from "@mui/x-charts";
import moment from "moment";
import TaskMiniTable from "./TaskMiniTable";
import UserService from "../../service/UserService";
import TeamsInternalTable from "../Teams/TeamsInternalTable";
import TeamsExternalTable from "../Teams/TeamsExternalTable";
import COMiniTable from "./COMiniTable";
import RFIMiniTable from "./RFIMiniTable";
import PCOMiniTable from "./PCOMiniTable";

const valueFormatter = (value) => `$${value}`;

const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState("one");

  const navigate = useNavigate();

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    UserService.getDashboardData()
      .then((response) => {
        if (response && response.data) {
          console.log(response);
          setDashboardData(response.data);
        }
      })
      .catch((error) => {});
  };

  const renderTotalProjects = (label, obj) => {
    return (
      <div className="project-count">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col">
            <p>{label}</p>
            <h4>{obj}</h4>
          </div>
          <div className="col">
            <div className="chart-bar">
              <img src="../images/Bar 3.png" alt="img" />
              <img src="../images/Bar 4.png" alt="img" />
              <img src="../images/Bar 5.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTotalTasks = (label, obj) => {
    return (
      <div className="project-count">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-center align-items-sm-start task-img">
              <img
                src="/images/Vector (3).png"
                alt="user"
                width=""
                height=""
                className=""
              />
            </div>
            <div className="tasks-div">
              <p>{label}</p>
              <h4>{obj}</h4>
            </div>
          </div>
          <div className="col">
            <div className="chart-bar">
              <img
                src="/images/Graph.png"
                alt="user"
                width=""
                height=""
                className=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const projectPortfolioChart = () => {
    return (
      <PieChart
        series={[
          {
            data: [
              {
                id: 0,
                value:
                  dashboardData?.project_profit_portfolio?.total_loss_projects,
                label: "Non-Profit",
                color: "red",
              },
              {
                id: 1,
                value:
                  dashboardData?.project_profit_portfolio
                    ?.total_profit_projects,
                label: "Profit",
                color: "green",
              },
            ],
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -180,
            endAngle: 180,
            maintainAspectRatio: false,
            // cx: 150,
            // cy: 100,
            highlightScope: {
              faded: "global",
              highlighted: "item",
            },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: "gray",
            },
          },
        ]}
        // width={800}
        height={300}
        slotProps={{
          legend: {
            direction: "row",
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
          },
        }}
      />
    );
  };

  const projectCompletionRateChart = () => {
    return (
      <PieChart
        series={[
          {
            data: [
              {
                id: 0,
                value:
                  dashboardData?.project_completion_rate
                    ?.completed_after_deadline,
                label: "Missed Deadline",
                color: "red",
              },
              {
                id: 1,
                value:
                  dashboardData?.project_completion_rate?.completed_on_deadline,
                label: "Completed",
                color: "green",
              },
            ],
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -180,
            endAngle: 180,
            //cx: 150,
            //cy: 100,
            highlightScope: {
              faded: "global",
              highlighted: "item",
            },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: "gray",
            },
          },
        ]}
        height={300}
        slotProps={{
          legend: {
            direction: "row",
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
          },
        }}
      />
    );
  };

  const projectVarianceAnalysisChart = () => {
    return (
      <BarChart
        dataset={[
          {
            value:
              dashboardData?.project_variance_analysis?.total_project_cost || 0,
            cost: "Actual Cost",
          },
          {
            value:
              dashboardData?.project_variance_analysis?.total_approved_budget ||
              0,
            cost: "Budgeted Cost",
          },
        ]}
        margin={{ left: 100 }}
        yAxis={[
          {
            scaleType: "band",
            dataKey: "cost",
          },
        ]}
        xAxis={[
          {
            label: "Budget",
            valueFormatter,
          },
        ]}
        series={[{ dataKey: "value", valueFormatter }]}
        layout="horizontal"
        height={300}
      />
    );
  };

  const projectChangeOrderChart = () => {
    return (
      <LineChart
        xAxis={[
          {
            id: "Months",
            data:
              dashboardData?.change_order_rate?.project_created_by_month.map(
                (x) => new Date(x.month)
              ) || [],
            scaleType: "time",
            valueFormatter: (date) => moment(date).format("MMM YY"),
          },
        ]}
        series={[
          {
            id: "1",
            data:
              dashboardData?.change_order_rate?.change_order_created_by_month.map(
                (x) => x.project_count
              ) || [],
            area: false,
            label: "No. of Change Orders",
          },
          {
            id: "2",
            data:
              dashboardData?.change_order_rate?.project_created_by_month.map(
                (x) => x.project_count
              ) || [],
            area: false,
            label: "No. of Projects",
          },
        ].map((item, index) => ({
          ...item,
          color: mangoFusionPalette("light")[index],
        }))}
        height={400}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="row mx-0 p-4">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} className="mx-auto">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <div className="nobreaktext">
                  {renderTotalTasks(
                    "Total Projects",
                    dashboardData?.total_projects
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                {" "}
                {renderTotalTasks("Total Task", dashboardData?.total_tasks)}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                {renderTotalTasks("Total CO", dashboardData?.total_cos)}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                {renderTotalTasks("Total RFI", dashboardData?.total_rfis)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className="mx-auto">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} className="mx-auto">
                <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
                  <div className="">
                    <h3>Project Portfolio</h3>
                    {dashboardData?.project_profit_portfolio
                      ?.total_completed_projects == 0 && (
                      <div className="d-flex align-items-center">
                        <p>-No Projects Completed-</p>
                      </div>
                    )}
                    {dashboardData?.project_profit_portfolio
                      ?.total_completed_projects > 0 && projectPortfolioChart()}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="mx-auto">
                <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
                  <div className="">
                    <h3>Project Completion Rate</h3>
                    {dashboardData?.project_profit_portfolio
                      ?.total_completed_projects == 0 && (
                      <div className="d-flex align-items-center">
                        <p>-No Projects Completed-</p>
                      </div>
                    )}
                    {dashboardData?.project_profit_portfolio
                      ?.total_completed_projects > 0 &&
                      projectCompletionRateChart()}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className="mx-auto">
            <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
              <div className="">
                <h3>My Upcoming Tasks</h3>

                <TaskMiniTable data={dashboardData?.upcoming_tasks || []} />
              </div>
            </div>
            <div className="backgrd-white boxborder-1 dashbooard-title mb-4">
              <h3>My Pending Approvals</h3>
              <Tabs value={currentTabIndex} onChange={handleTabChange}>
                <Tab value="one" label="Change Order" />
                <Tab value="two" label="Potential Change Order" />
                <Tab value="three" label="RFI" />
              </Tabs>
              {currentTabIndex == "one" && (
                <COMiniTable data={dashboardData?.pending_cos || []} />
              )}
              {currentTabIndex == "two" && (
                <PCOMiniTable data={dashboardData?.pending_pcos || []} />
              )}
              {currentTabIndex == "three" && (
                <RFIMiniTable data={dashboardData?.pending_rfis || []} />
              )}
            </div>
            <div className="backgrd-white boxborder-1 dashbooard-title mb-4">
              <h3>Project Variance Analysis</h3>
              <Grid item>{projectVarianceAnalysisChart()}</Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="row mx-0 p-4">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6} className="mx-auto">
            <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
              <h5>All Change Order Rate per Project</h5>
              {projectChangeOrderChart()}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className="mx-auto">
            <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
              <h5>Projects Email Communication and Collaboration</h5>
              <div className="email-collab-box">
                <div className="d-flex flex-row justify-content-between">
                  <div className="project-count">
                    <div className="project-email-text">
                      Average Time to Resolve RFIs
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="project-hrs">
                        {dashboardData?.average_time_taken_to_resolve?.rfi !== 0
                          ? dashboardData?.average_time_taken_to_resolve?.rfi
                          : "No Data Available"}
                      </span>
                      <span className="chart-bar">
                        <img src="../images/Graph.png" alt="chart-icon" />
                      </span>
                    </div>
                  </div>
                  <div className="project-count mr-0">
                    <div className="project-email-text">
                      Average Time to Resolve CO/s
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="project-hrs">
                        {dashboardData?.average_time_taken_to_resolve?.co !== 0
                          ? dashboardData?.average_time_taken_to_resolve?.co
                          : "No Data Available"}
                      </span>
                      <span className="chart-bar">
                        <img src="../images/Graph.png" alt="chart-icon" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
