import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton, SecondaryButton } from "./bproButtons";

export default function NoAccessModal({ openDialog, setOpenDialog }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        size={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="submain-heading">
          {"Warning!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You must upgrade to add more projects or users
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <SecondaryButton onClick={handleClose}>
            Close
          </SecondaryButton> */}
          <PrimaryButton onClick={handleClose}>
            Subscribe Now to Continue
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
