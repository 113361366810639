import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Button } from '@mui/material';
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";

import NoAccessModal from '../../components/NoAccessModal';
import { hasSubscription } from '../../helper/AccessHelper';
import DailyLogTable from './DailyLogTable';
import { PrimaryButton } from '../../components/bproButtons';

const DLDashboard = () => {
    let { project_id: projectId } = useParams();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <React.Fragment>
            {/* Create New RFI button */}
            <div className="row mx-0 mt-5 mb-3 d-flex justify-content-end">
                <div className="col-auto">
                    <PrimaryButton
                        startIcon={hasSubscription() ? <AddIcon /> : <LockIcon />}
                        onClick={() => hasSubscription() ? navigate(`/dailylogs/${projectId}/create`) : setOpenDialog(true)}
                    >
                        Create New Daily log
                    </PrimaryButton>
                </div>
            </div>
            
            {/* Request for Information section */}
            <div className="row mx-0 p-4">
                <div className="backgrd-white">
                    <div className="mb-4 d-flex">
                        <h3 className="submain-heading">Daily logs</h3>
                    </div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/projects" className="pointer-cus">
                            Projects
                        </Link>
                        <Link to={'/projects/detail/' + projectId} className="pointer-cus">
                            Project details
                        </Link>
                        <p>
                            Daily logs dashboard
                        </p>
                    </Breadcrumbs>
                    <div className="w-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{ minHeight: 400, width: "100%" }}>
                                {/* DailyLogTable component */}
                                <DailyLogTable />
                                
                                {/* No Access Modal */}
                                <NoAccessModal openDialog={openDialog} setOpenDialog={setOpenDialog}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DLDashboard;
