import axios from "axios";
import { toast } from "react-toastify";
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import  securesecureLocalStorage  from  "react-secure-storage";


// axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const handleLogout = () => {
  securesecureLocalStorage.clear();
  Navigate("/login");
};

instance.interceptors.response.use(
  (response) => {
    console.log(response);
    const expectedResult = response.data && response.status == 200;

    // if (expectedResult) {
    //   let displayMsg = response.data.message ? response.data.message : "";
    //   toast.info(displayMsg, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      handleLogout();
      return Promise.reject(error);
    }
    const expectedError =
      error.response.status >= 400 && error.response.status < 500;

    let erroMsg = error?.response?.data?.message
      ? error?.response?.data?.message
      : "An unexpected error occured!";
    toast.error(erroMsg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return Promise.reject(error);
  }
);

export default {
  get: instance.get,
  put: instance.put,
  post: instance.post,
  delete: instance.delete,
};
