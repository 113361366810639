
import  securesecureLocalStorage  from  "react-secure-storage";
const authHeaders = (isFile) => {
    const token = securesecureLocalStorage.getItem("builderAccessToken");
    let header = { "Content-Type": "application/json" }
    if (isFile)
        header = { "Content-Type": "multipart/form-data" }
    if (token)
        header['authorization'] = `Bearer ${token}`
    return header;
}

export { authHeaders }