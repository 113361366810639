import React, { useEffect, useMemo, useRef, useState } from "react";
import { Chip } from "@mui/material";
import {
  TaskPriorityEnum,
  TaskPriorityList,
} from "../../constants/Task.constants";
import {
  projectStatusEnum,
  projectStatusList,
} from "../../constants/Project.constants";
import moment from "moment";
import { dateFormat } from "../../constants/Global.constants";
import GridTableStatic from "../../components/DataGrid/GridTableStatic";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/bproButtons";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const TaskMiniTable = ({ data }) => {
  const navigate = useNavigate();
  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "task_name",
        header: "Title",
        Cell: ({ renderedCellValue, row }) => renderedCellValue,
      },
      {
        accessorKey: "project_id.project_name",
        header: "Project",
      },
      {
        accessorKey: "priority",
        header: "Priority",
        Cell: ({ cell }) => {
          return (
            <Chip
              color={TaskPriorityEnum[cell.getValue()]?.className}
              label={TaskPriorityEnum[cell.getValue()]?.name}
            />
          );
        },
        filterVariant: "select",
        filterSelectOptions: TaskPriorityList,
      },
      {
        accessorKey: "status",
        header: "STATUS",
        Cell: ({ cell }) => {
          return (
            <Chip
              color={projectStatusEnum[cell.getValue()]?.className}
              label={projectStatusEnum[cell.getValue()]?.name}
            />
          );
        },
        filterVariant: "select",
        filterSelectOptions: projectStatusList,
      },
      {
        accessorKey: "assigned_to.email",
        header: "Assigned To",
      },
      {
        accessorKey: "due_date",
        header: "DUE DATE",
        Cell: ({ cell }) => moment(cell.getValue()).format(dateFormat),
      },
    ],
    []
  );

  // Render the table component
  return (
    <React.Fragment>
      <GridTableStatic data={data} columns={columns} />
      <div className="row justify-content-end mt-3">
        <PrimaryButton
          variant="contained"
          onClick={() => navigate("/tasks")}
          endIcon={<KeyboardDoubleArrowRightIcon />}
        >
          View More
        </PrimaryButton>
      </div>
    </React.Fragment>
  );
};

export default TaskMiniTable;
