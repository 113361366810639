import { authHeaders } from "../../helper/AuthHelper";
import http from "../../service/HttpService";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Function to create a new Potential change order (PCO).
 * @param {FormData} formData - The form data containing RFI information.
 * @returns {Promise<Object>} The response data containing the created RFI.
 * @throws {string} Throws an error message if the request fails.
 */
const createPCO = (formData) => {
  return http
    .post(API_URL + `/project-change-order`, formData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to fetch RFIs by project ID.
 * @param {string} projectId - The ID of the project to fetch RFIs for.
 * @param {Object} params - Additional query parameters for the request.
 * @returns {Promise<Object>} The response data containing the RFIs for the project.
 * @throws {string} Throws an error message if the request fails.
 */
const getPCObyProjectID = (projectId, params) => {
  return http
    .get(API_URL + `project-change-order/?project_id=${projectId}`, {
      headers: authHeaders(),
      params: params
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to update PCO data.
 * @param {string} PCO_ID - The ID of the PCO to update.
 * @param {FormData} bodyFormData - The form data containing the updated RFI information.
 * @returns {Promise<Object>} The response data containing the updated RFI.
 * @throws {string} Throws an error message if the request fails.
 */
const updatePCOData = (PCO_ID, bodyFormData) => {
  return http
    .put(API_URL + `project-change-order/${PCO_ID}/`, bodyFormData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

/**
 * Function to fetch an PCO by its ID.
 * @param {string} PCO_id - The ID of the RFI to fetch.
 * @returns {Promise<Object>} The response data containing the requested RFI.
 * @throws {string} Throws an error message if the request fails.
 */
const getPCObyID = (PCO_id) => {
  return http
    .get(API_URL + `project-change-order/${PCO_id}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};


export default { createPCO, getPCObyProjectID, getPCObyID, updatePCOData };
