import React, { useState, useEffect } from "react";
import { Breadcrumbs, Grid, InputAdornment } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { projectTypeEnum } from "../../../constants/Project.constants";
import { dateFormat } from "../../../constants/Global.constants";
import { NumericFormatCustom } from "../../../components/InputMask";

function ProjectStep3({ step3DataSubmit, updateStep, shouldResetForm }) {
  const schema = yup
    .object({
      materials_total_cost: yup.string().required("This Field is Required"),
      insurance_costs: yup.string().required("This Field is Required"),
      labor_total_cost: yup.string().required("This Field is Required"),
      equipment_total_cost: yup.string().required("This Field is Required"),
      permits_and_fees: yup.string().required("This Field is Required"),
      subcontractor_total_costs: yup.string().required("This Field is Required"),
      utilities_and_services: yup.string().required("This Field is Required"),
      transportation_costs: yup.string().required("This Field is Required"),
      land_acquisition_costs: yup.string().required("This Field is Required"),
      contingency_fund: yup.string().required("This Field is Required"),
      overhead_costs: yup.string().required("This Field is Required"),
      safety_measures: yup.string().required("This Field is Required"),
      consultant_fees: yup.string().required("This Field is Required"),
      technology_and_software_costs: yup.string().required("This Field is Required"),
      marketing_and_communication: yup.string().required("This Field is Required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  useEffect(() => {
    reset(shouldResetForm)
  }, [shouldResetForm])

  const step3Submit = (data) => {
    step3DataSubmit(data);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <form
        className="form-private form-design"
        onSubmit={handleSubmit(step3Submit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Material total cost</label>
              <Controller
              name="materials_total_cost"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                id="materials_total_cost"
                variant="outlined"
                placeholder="0.00"
                value={value}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("materials_total_cost")}
              />)} />
              <p className="error-msg">{errors.materials_total_cost?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Insurance cost</label>
              <Controller
              name="insurance_costs"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                id="insurance_costs"
                variant="outlined"
                placeholder="0.00"
                value={value}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("insurance_costs")}
              />)} />
              <p className="error-msg">{errors.insurance_costs?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Labor total cost</label>
              <Controller
              name="labor_total_cost"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                id="labor_total_cost"
                variant="outlined"
                placeholder="0.00"
                value={value}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("labor_total_cost")}
              />)} />
              <p className="error-msg">{errors.labor_total_cost?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Utilities and Services</label>
              <Controller
              name="utilities_and_services"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                id="utilities_and_services"
                variant="outlined"
                placeholder="0.00"
                value={value}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("utilities_and_services")}
              />)} />
              <p className="error-msg">{errors.utilities_and_services?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Equipment total cost</label>
              <Controller
              name="equipment_total_cost"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="equipment_total_cost"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("equipment_total_cost")}
              />)} />
              <p className="error-msg">{errors.equipment_total_cost?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Transportation cost</label>
              <Controller
              name="transportation_costs"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="transportation_costs"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("transportation_costs")}
              />)} />
              <p className="error-msg">{errors.transportation_costs?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Permits and Fees</label>
              <Controller
              name="permits_and_fees"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="permits_and_fees"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("permits_and_fees")}
              />)} />
              <p className="error-msg">{errors.permits_and_fees?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Land Acquisition Costs</label>
              <Controller
              name="land_acquisition_costs"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="land_acquisition_costs"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("land_acquisition_costs")}
              />)} />
              <p className="error-msg">{errors.land_acquisition_costs?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Subcontractor Total Costs</label>
              <Controller
              name="subcontractor_total_costs"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="subcontractor_total_costs"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("subcontractor_total_costs")}
              />)} />
              <p className="error-msg">{errors.subcontractor_total_costs?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Contingency Fund</label>
              <Controller
              name="contingency_fund"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="contingency_fund"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("contingency_fund")}
              />)} />
              <p className="error-msg">{errors.contingency_fund?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Overhead Costs</label>
              <Controller
              name="overhead_costs"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="overhead_costs"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("overhead_costs")}
              />)} />
              <p className="error-msg">{errors.overhead_costs?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Safety Measures</label>
              <Controller
              name="safety_measures"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="outlined-basic"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("safety_measures")}
              />)} />
              <p className="error-msg">{errors.safety_measures?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Consultant Fees</label>
              <Controller
              name="consultant_fees"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="consultant_fees"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("consultant_fees")}
              />)} />
              <p className="error-msg">{errors.consultant_fees?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Technology and Software Costs</label>
              <Controller
              name="technology_and_software_costs"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="technology_and_software_costs"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("technology_and_software_costs")}
              />)} />
              <p className="error-msg">{errors.technology_and_software_costs?.message}</p>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">Marketing and Communication</label>
              <Controller
              name="marketing_and_communication"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                value={value}
                id="marketing_and_communication"
                variant="outlined"
                placeholder="0.00"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...register("marketing_and_communication")}
              />)} />
              <p className="error-msg">{errors.marketing_and_communication?.message}</p>
            </div>
          </Grid>
        </Grid>
        <div className="login-links-section text-right">
        <button type="button" class="secondary-button w-auto mr-3" onClick={()=>updateStep(1)}>
            Previous
          </button>
          <button type="submit" class="primarynext-button w-auto">
            Submit
          </button>
        </div>
      </form>
    </LocalizationProvider>
  );
}

export default ProjectStep3;
