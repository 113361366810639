import { authHeaders } from "../helper/AuthHelper";
import http from "./HttpService";

// Function to add a new project
const addNewProject = (bodyFormData, id = "") => {
  return http
    .post("projects/", bodyFormData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        let msg = "Try again later!";
        if (error?.response?.data?.status || error?.response?.data?.description)
          msg = "Please fill up the Mandatory fields";
        throw msg;
      }
    });
};

// Function to update a project
const updateProject = (bodyFormData, id) => {
  return http
    .put(`projects/${id}/`, bodyFormData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        let msg = "Try again later!";
        if (error?.response?.data?.status[0])
          msg = error?.response?.data?.status[0];
        throw msg;
      }
    });
};

// Function to get all projects
const getAllProjects = (params) => {
  return http
    .get("projects/", {
      headers: authHeaders(),
      params: params,
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        let msg = "Try again later!";
        if (error?.response?.data?.status[0])
          msg = error?.response?.data?.status[0];
        throw msg;
      }
    });
};

// Function to get a project by its ID
const getProjectById = (id) => {
  return http
    .get(`projects/${id}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        if (error && error.response && error.response.data)
          throw error.response?.data?.message;
      }
    });
};

// Function to get project tasks by project ID
const getProjectTaskById = (id) => {
  return http
    .get(`getprojecttasks?project_id=${id}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        if (error && error.response && error.response.data)
          throw error.response?.data?.message;
      }
    });
};

// Function to remove a project
const removeProject = (projectId) => {
  return http
    .delete(`projects/${projectId}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

// Function to add project members
const addProjectMembers = (bodyFormData) => {
  return http
    .post("projectmembers/", bodyFormData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

// Function to get project members by project ID
const getProjectMembersById = (id) => {
  return http
    .get(`projectmembers?project_id=${id}`, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const getGalleryByProjectId = (params) => {
  return http
    .get("gallery/", {
      headers: authHeaders(),
      params: params,
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const projectService = {
  addNewProject,
  getAllProjects,
  removeProject,
  updateProject,
  getProjectById,
  addProjectMembers,
  getProjectMembersById,
  getProjectTaskById,
  getGalleryByProjectId,
};

export default projectService;
