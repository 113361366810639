import moment from "moment";
import { TaskPriorityEnum } from "../constants/Task.constants";

export function initTasks() {
  const currentDate = new Date();
  const tasks = [
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9),
      name: "Planning",
      id: "ProjectSample",
      // progress: 25,
      type: "project",
      //hideChildren: false
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2,
        12,
        28
      ),
      name: "Idea",
      id: "Task 0",
      styles: {
        backgroundColor: "cyan"
      },
      // progress: 45,
      type: "task",
      project: "ProjectSample"
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
      name: "Research",
      id: "Task 1",
      // progress: 25,
      dependencies: ["Task 0"],
      type: "task",
      project: "ProjectSample"
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
      name: "Discussion with team",
      id: "Task 2",
      // progress: 10,
      dependencies: ["Task 1"],
      type: "task",
      project: "ProjectSample"
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: "Developing",
      id: "Task 3",
      // progress: 2,
      // dependencies: ["Task 2"],
      type: "task",
      project: "ProjectSample"
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Execution",
      id: "ProjectSample2",
      // progress: 25,
      type: "project",
      hideChildren: false
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
      name: "Review",
      id: "Task 4",
      type: "task",
      // progress: 70,
      // dependencies: ["Task 2"],
      project: "ProjectSample2"
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Release",
      id: "Task 6",
      // progress: currentDate.getMonth(),
      type: "task",
      // dependencies: ["Task 4"],
      project: "ProjectSample2"
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
      name: "Party Time",
      id: "Task 9",
      // progress: 0,
      // isDisabled: true,
      type: "task",
      project: "ProjectSample2"
    }
  ];
  return tasks;
}
// export function getStartEndDateForProject(tasks, projectId) {
//   const projectTasks = tasks.filter((t) => t.project === projectId);
//   let start = projectTasks[0].start;
//   let end = projectTasks[0].end;
//   for (let i = 0; i < projectTasks.length; i++) {
//     const task = projectTasks[i];
//     if (start.getTime() > task.start.getTime()) {
//       start = task.start;
//     }
//     if (end.getTime() < task.end.getTime()) {
//       end = task.end;
//     }
//   }
//   return [start, end];
// }

export function formTaskFromData(taskSet) {
  let formatedTask = []
  taskSet.map((task) => {
    formatedTask.push({
      'start': new Date(moment(task?.start_date).format('YYYY,MM,DD')),
      'end': new Date(moment(task?.due_date).format('YYYY,MM,DD')),
      'name': task?.task_name,
      "assigned_to": task?.assigned_to?.email,
      'id': task?.app_id,
      'taskDetails': task,
      styles: {
        backgroundColor: TaskPriorityEnum[task?.priority].color,
        backgroundSelectedColor: TaskPriorityEnum[task?.priority].color,
        color:'black'
      },
      // progress: 25,
      'type': "project",
      //hideChildren: false
    });
  });
  return formatedTask
}

export function formatAmount(price) {
  let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
  });
  return USDollar.format(price);
}
