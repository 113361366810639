import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import StyledDropzone from "../../../components/DropZone/DropZone";
import { useNavigate, useParams } from "react-router-dom";
import ProjectService from "../../../service/ProjectService";
import {
  orderChangeReasonEnum,
  orderStatusEnum,
} from "../../../constants/ChangeOrder.constant";
import moment from "moment";
import { dateFormat } from "../../../constants/Global.constants";

const PCOStep1 = ({ step, setStep, PCOData, setPCOData, isChangeOrder }) => {
  const navigate = useNavigate();
  let { project_id: projectId, pco_id: pcoId } = useParams();
  const [projectData, setProjectData] = useState({});
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const schema = yup
    .object({
      title: yup.string().required("Change order title is required"),
      project_title: yup.string(),
      project_site_address: yup.string(),
      status: yup.string().required("Status is required"),
      change_reason: yup.string().required("Change reason is required"),
      description: yup.string().required("Description is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: PCOData?.General,
  });

  useEffect(() => {
    if (projectId) getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    ProjectService.getProjectMembersById(projectId)
      .then((res) => {
        if (res?.data) {
          let ProData = res?.data;
          ProData.members = res?.data.members.map((mem) => mem.user_id);
          setProjectData(ProData);
          setPCOData({
            type: "UPDATE_PROJECT_DATA",
            payload: ProData,
          });
          setValue("project_title", ProData?.project?.project_name);
          setValue("project_site_address", ProData?.project?.address);
          setProjectAddress(ProData?.project?.address);
          setProjectName(ProData?.project?.project_name);
        }
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const onSubmit = (data) => {
    setPCOData({
      type: "UPDATE_GENERAL_INFO",
      payload: data,
    });
    setStep(step + 1);
  };

  const uploadFiles = (file) => {
    setPCOData({
      type: "UPDATE_FILE",
      payload: file,
    });
  };

  const disableFields = () => {
    if (PCOData?.General?.status >= 3) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <form className="form-sign-in pb-5" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                id="title"
                placeholder={`${
                  isChangeOrder ? `` : `Potential`
                } Change order title *`}
                label={`${
                  isChangeOrder ? `` : `Potential`
                } Change order title *`}
                fullWidth
                disabled={disableFields()}
                variant="outlined"
                {...register("title")}
              />
              <p className="error-msg">{errors.title?.message}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="project_title"
                label="Project title *"
                placeholder="Project title *"
                value={projectName}
                disabled
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                fullWidth
                variant="outlined"
              />
              <p className="error-msg">{errors.project_title?.message}</p>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="project_site_address"
                label="Project Address *"
                placeholder="Project Address *"
                disabled
                value={projectAddress}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e) => {
                  setProjectAddress(e.target.value);
                }}
                multiline
                maxRows={4}
                fullWidth
                variant="outlined"
              />
              <p className="error-msg">
                {errors.project_site_address?.message}
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="status"
                label="Status *"
                fullWidth
                variant="outlined"
                defaultValue={PCOData?.General?.status}
                disabled={disableFields()}
                select
                {...register("status")}
              >
                {Object.keys(orderStatusEnum).map((type) => {
                  return (
                    <MenuItem key={type} value={orderStatusEnum[type].value}>
                      {orderStatusEnum[type].name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <p className="error-msg">{errors.status?.message}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="change_reason"
                label="Change Reason *"
                fullWidth
                {...register("change_reason")}
                disabled={disableFields()}
                defaultValue={PCOData?.General?.change_reason}
                variant="outlined"
                select
              >
                {Object.keys(orderChangeReasonEnum).map((type) => {
                  return (
                    <MenuItem
                      key={type}
                      value={orderChangeReasonEnum[type].value}
                    >
                      {orderChangeReasonEnum[type].name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <p className="error-msg">{errors.change_reason?.message}</p>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="description"
                label="Description *"
                placeholder="Description *"
                {...register("description")}
                disabled={disableFields()}
                multiline
                maxRows={4}
                fullWidth
                variant="outlined"
              />
              <p className="error-msg">{errors.description?.message}</p>
            </Grid>
            {!disableFields() && (
              <Grid item xs={12}>
                <label for="attachments" className="mb-2">
                  Attachments
                </label>

                <StyledDropzone
                  id="attachments"
                  uploadFiles={uploadFiles}
                  fileData={PCOData.file}
                  pcoData={PCOData}
                  disabled={disableFields()}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            sx={{ mt: 4 }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="row">
              <div className="col-6">
                <button
                  id="pco_cancel"
                  type="button"
                  className="secondary-button"
                  onClick={() => {
                    navigate(`/${isChangeOrder ? "co" : "pco"}/${projectId}`);
                  }}
                >
                  {"Cancel"}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  id="pco_step2_submit"
                  type="submit"
                  className="primarynext-button"
                >
                  {"Next"}
                </button>
              </div>
            </div>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default PCOStep1;
