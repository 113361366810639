import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const GridTable = forwardRef(
  (
    {
      apiCall,
      columns,
      renderRowActionMenuItems,
      tenent = false,
      enableRowSelection = false,
      onRowSelectionChange,
    },
    ref
  ) => {
    // State variables for managing data, loading, errors, filters, sorting, and pagination
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    // as the second argument
    useImperativeHandle(ref, () => ({
      refreshTable() {
        fetchData();
      },
    }));

    // Function to fetch data from API
    const fetchData = async () => {
      // Set loading states
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        // Fetch data from API
        let res = await apiCall({
          limit: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
          status: null,
          filters: JSON.stringify(columnFilters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          is_internal: tenent === "External" ? false : true,
        });

        if (tenent != false) {
          setData(res?.data);
          if (pagination.pageIndex * pagination.pageSize == 0)
            setRowCount(res?.data?.length);
        } else {
          console.log(pagination.pageIndex, pagination.pageSize);
          setData(res?.data?.results);
          if (pagination.pageIndex * pagination.pageSize == 0)
            setRowCount(res?.data?.count);
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
      } catch (error) {
        // Handle errors
        setIsError(true);
        setIsLoading(false);
        setIsRefetching(false);
        console.error(error);
      }
    };

    // Effect to fetch data when dependencies change
    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

    // Initialize material-react-table hook
    const table = useMaterialReactTable({
      columns: columns || [],
      data,
      enableMultiRowSelection: false,
      onRowSelectionChange: onRowSelectionChange || undefined,
      getRowId: (row) => row.app_id,
      initialState: {
        showColumnFilters: false,
        showGlobalFilter: false,
        columnPinning: {
          right: ["mrt-row-actions"],
        },
      },
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
      muiToolbarAlertBannerProps: isError
        ? {
            color: "error",
            children: "Error loading data",
          }
        : undefined,
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      enableRowActions: true,
      rowCount,
      state: {
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      },
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableRowSelection: enableRowSelection,
      muiPaginationProps: {
        color: "secondary",
        rowsPerPageOptions: [1, 10, 20, 30],
        shape: "rounded",
        variant: "outlined",
      },
      renderRowActionMenuItems: renderRowActionMenuItems || undefined,
    });

    // Render MaterialReactTable component
    return <MaterialReactTable table={table} />;
  }
);

export default GridTable;
