import React, { useMemo } from "react";
import GridTable from "../../components/DataGrid/GridTable";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import { ListItemIcon, MenuItem } from "@mui/material";
import { PictureAsPdf } from "@mui/icons-material";
import DLService from "../../service/DLService";

import { rfiStatusEnum, rfiStatusList } from "../../constants/RFI.constant";
import DailyLogTablePDFGeneration from "./DailyLogTablePDFGeneration";
import moment from "moment";
import { dateFormat } from "../../constants/Global.constants";

const DailyLogTable = () => {
  let { project_id: projectId } = useParams();
  const navigate = useNavigate();

  const generatePdfDocument = async (documentData) => {
    console.log("documentData", documentData);
    const blob = await pdf(
      <DailyLogTablePDFGeneration
        title="Daily logs PDF"
        pdfObj={documentData}
      />
    ).toBlob();
    saveAs(blob, "BuildifyPro-logs.pdf");
  };

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "log_id",
        header: "Log ID",
        Cell: ({ cell, row }) => (
          <span
            className="pointer-cus"
            onClick={(event) => {
              generatePdfDocument(row.original);
            }}
          >
            DL-${cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: "created_date",
        header: "Date Submitted",
        Cell: ({ cell }) => moment(cell.getValue()).format(dateFormat),
      },
      {
        accessorKey: "contractor.email",
        header: "Contractor",
      },
      {
        accessorKey: "superintendent.email",
        header: "Superintendent",
      },
      {
        accessorKey: "weather_status",
        header: "Weather status",
      },
    ],
    []
  );

  // Define menu items for row actions
  const renderRowActionMenuItems = ({ closeMenu, row }) => [
    <MenuItem
      key={0}
      onClick={() => {
        generatePdfDocument(row.original);
        closeMenu();
      }}
      sx={{ m: 0 }}
    >
      <ListItemIcon>
        <PictureAsPdf />
      </ListItemIcon>
      Download Daily Log PDF
    </MenuItem>,
  ];

  // Function to fetch data from API
  const fetchDataFromAPI = async (params) => {
    const data = await DLService.getDailyLogbyProjectID({
      project_id: projectId,
      ...params,
    });
    return data;
  };

  // Render the table component
  return (
    <GridTable
      columns={columns}
      renderRowActionMenuItems={renderRowActionMenuItems}
      apiCall={fetchDataFromAPI}
    />
  );
};

export default DailyLogTable;
