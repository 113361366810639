import React, { useState } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import { dateTimeFormat } from "../../constants/Global.constants";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  tableNoBorder: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
    marginTop: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSmall: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol50: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol75: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColNoBorder: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol50NoBorder: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol100: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
  },
  tableCol100NoBG: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableCellBold: {
    margin: "auto",
    fontWeight: "bold",
    marginTop: 5,
    fontSize: 12,
  },
});

const TaskPriorityEnum = {
  0: { className: "green-text", name: "Low" },
  1: { className: "yellow-text", name: "Medium" },
  2: { className: "orange-text", name: "High" },
};

const getPriority = (id) => {
  return (
    TaskPriorityEnum[id]?.name
  );
};

const MyDoc = ({ pdfObj }) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        ~ buildifypro.com ~
      </Text>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>GENERAL INFO</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>PROJECT TITLE</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>DATE SUBMITTED</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>CONTRACTOR</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>SUPERINTENDENT</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{pdfObj?.project_title}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {moment().format(dateTimeFormat)}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {pdfObj?.contractor?.user_id?.email}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {pdfObj?.superintendent?.user_id?.email}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>WORK NOTES</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100NoBG}>
            <Text style={styles.tableCell}>{pdfObj?.work_notes}</Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol100}>
            <Text style={styles.tableCellBold}>COMPLETED TASKS</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>TASK TITLE</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellBold}>DUE DATE</Text>
          </View>
          <View style={styles.tableCol50}>
            <Text style={styles.tableCellBold}>PRIORITY</Text>
          </View>
        </View>
        {pdfObj?.taskList?.map((item) => {
          return (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.task_name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {moment(item.due_date).format(dateTimeFormat)}
                </Text>
              </View>
              <View style={styles.tableCol50}>
                <Text style={styles.tableCell}>
                  {getPriority(item.priority)}
                </Text>
              </View>
            </View>
          );
        })}
      </View>

      {pdfObj?.site_visitors?.length > 0 &&
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol100}>
              <Text style={styles.tableCellBold}>SITE VISITORS</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellBold}>VISITOR NAME</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellBold}>VISITOR EMAIL</Text>
            </View>
            <View style={styles.tableCol50}>
              <Text style={styles.tableCellBold}>VISITOR PH.NO.</Text>
            </View>
          </View>
          {pdfObj?.site_visitors?.map((item) => {
            return (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.email}
                  </Text>
                </View>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell}>
                    {item.ph_no}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      }

      {pdfObj?.has_safety_issues &&
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol100}>
              <Text style={styles.tableCellBold}>SAFETY ISSUES</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol100NoBG}>
              <Text style={styles.tableCell}>{pdfObj?.health_safety_issue_desc}</Text>
            </View>
          </View>
        </View>
      }

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const DailyLogPDFGeneration = ({ pdfObj }) => <MyDoc pdfObj={pdfObj} />;
export default DailyLogPDFGeneration;
