import React, { useMemo } from 'react';
import GridTable from '../../components/DataGrid/GridTable';
import { useNavigate, useParams } from 'react-router-dom';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import { Link, ListItemIcon, MenuItem } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import RFIService from './RFIService';

import { rfiStatusEnum, rfiStatusList } from '../../constants/RFI.constant';
import RFIPDFGeneration from './RFIPDFGeneration';

const RFITable = () => {
    let { project_id: projectId } = useParams();
    const navigate = useNavigate();

  const generatePdfDocument = async (documentData) => {
    const blob = await pdf((
        <RFIPDFGeneration
            title='RFI PDF'
            pdfObj={documentData}
        />
    )).toBlob();
    saveAs(blob, "BuildifyPro-RFI.pdf");
};
    
    // Define columns for the table
    const columns = useMemo(
        () => [
            {
                accessorKey: 'rfi_id',
                header: 'RFI ID',
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span
                            className="pointer-cus"
                            onClick={(event) => navigate(`/rfi/${projectId}/${row?.original.rfi_id}`)}
                        >
                            {renderedCellValue}
                        </span>
                    );
                },
                // Cell: ({ cell }) => 
                //     <Link href={`${projectId}/${parseInt(cell.getValue())}`}>{cell.getValue()}</Link>,
            },
            {
                accessorKey: 'title',
                header: 'Title',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                Cell: ({ cell }) => { return rfiStatusEnum[cell.getValue()]?.name },
                filterVariant: 'select',
                filterSelectOptions: rfiStatusList,
            },
            {
                accessorKey: 'requestor.email',
                header: 'Requestor',
            },
            {
                accessorKey: 'assigned_to',
                header: 'Assigned To',
                Cell: ({ cell }) => cell.getValue()[0].email
            },
        ],
        [],
    );

    // Define menu items for row actions
    const renderRowActionMenuItems = ({ closeMenu, row }) => [
        <MenuItem
            key={0}
            onClick={() => {
                generatePdfDocument(row.original);
                closeMenu();
            }}
            sx={{ m: 0 }}
        >
            <ListItemIcon>
                <PictureAsPdf />
            </ListItemIcon>
            Download RFI PDF
        </MenuItem>
    ];

    // Function to fetch data from API
    const fetchDataFromAPI = async (params) => {
        return await RFIService.getRFIbyProjectID(projectId, params);
    };

    // Render the table component
    return <GridTable columns={columns} renderRowActionMenuItems={renderRowActionMenuItems} apiCall={fetchDataFromAPI} />;
};

export default RFITable;