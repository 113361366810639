import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import SignIn from "../views/Login/Login";
import Register from "../views/Register/Register";
import ProjectDashboard from "../views/Project/ProjectDashboard";
import ProjectFormLanding from "../views/Project/ProjectForm/ProjectFormLanding";
import ProjectDetail from "../views/Project/ProjectDetails";
import ConversationDashboard from "../views/Conversation/ConversationDashboard";
import RFIDashboard from "../views/RFI/RFIDashboard";
import RFIForm from "../views/RFI/RFIForm";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import ResetPassword from "../views/ForgotPassword/ResetPassword";
import RFIDetails from "../views/RFI/RFIDetails";
import PCODashboard from "../views/PCO/PCODashboard";
import TaskDashboard from "../views/Tasks/TaskDashboard";
import DailyLogDashboard from "../views/DailyLogs/DailyLogDashboard";
import DirectorySystemLanding from "../views/FileSystem/DirectorySystemLanding";
import TeamsDashboard from "../views/Teams/TeamsDashboard";
import TeamsMembersForm from "../views/Teams/TeamsMembersForm";
import DailyLogForm from "../views/DailyLogs/DailyLogForm";
import PCOForm from "../views/PCO/form/PCOForm";
import CODashboard from "../views/ChangeOrder/CODashboard";
import Dashboard from "../views/Dashboard";
import GalleryDashboard from "../views/Gallery/GalleryDashboard";
import GalleryView from "../views/Gallery/GalleryView";
import AdminDashboard from "../views/Dashbord/AdminDashboard";
import DashboardRoute from "../views/Dashbord/DashboardRoute";

function RouteSwitch() {
  return (
    <BrowserRouter base="/">
      <Routes>
        <Route
          path="*"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/"
          element={<Navigate to="/projects" replace />}
        />
        <Route exact path='/' element={<PrivateRoute />}>
        <Route exact path='/dashboard' element={<DashboardRoute />} />
        <Route exact path="/projects" element={<ProjectDashboard />} />
        <Route exact path="/projects/newProject" element={<ProjectFormLanding />} />
        <Route exact path="/projects/:project_app_id" element={<ProjectFormLanding />} />
        <Route exact path="/projects/detail/:projectId" element={<ProjectDetail />} />
        <Route exact path="/communication" element={<ConversationDashboard />} />
        <Route exact path='/communication/:projectId' element={<ConversationDashboard />} />
        <Route exact path='/rfi/:project_id' element={<RFIDashboard />}/>
        <Route exact path='/rfi/:project_id/create' element={<RFIForm />} />
        <Route exact path='/rfi/:project_id/:rfi_id' element={<RFIDetails />} />
        <Route exact path='/pco/:project_id/' element={<PCODashboard />} />
        <Route exact path='/pco/:project_id/create/:type' element={<PCOForm />} />
        <Route exact path='/editPCO/:project_id/:type/:pco_id' element={<PCOForm />} />
        <Route exact path='/tasks' element={<TaskDashboard />} />
        <Route exact path='/projectFiles/:project_id' element={<DirectorySystemLanding />} />
        <Route exact path='/dailylogs/:project_id' element={<DailyLogDashboard />} />
        <Route exact path='/teams' element={<TeamsDashboard />} />
        <Route exact path='/teams/:member_type' element={<TeamsMembersForm />} />
        <Route exact path='/dailylogs/:project_id/create' element={<DailyLogForm />} />

        <Route exact path='/co/:project_id' element={<CODashboard />}/>
        <Route exact path='/createCO/:project_id/:type' element={<PCOForm isChangeOrder={true} />} />
        <Route exact path='/createCO/:project_id/:type/:pco_id' element={<PCOForm isChangeOrder={true} />} />
        <Route exact path='/editCO/:project_id/:type/:pco_id' element={<PCOForm isChangeOrder={true} />} />

        <Route exact path='/gallery' element={<GalleryDashboard />} />
        <Route exact path='/gallery/:projectId' element={<GalleryView />} />
        </Route>
        <Route exact path='/' element={<PublicRoute />}>
          <Route exact path='/login' element={<SignIn />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgotPassword' element={<ForgotPassword />} />
          <Route exact path='/resetPassword' element={<ResetPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RouteSwitch;
