import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  IconButton,
  Grid,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
  InputLabel,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

function PCORejectedDialog({ handleClose, show, handleSubmitUpdate, userParsed }) {
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [isLoading, setLoading] = React.useState(false);
  const schema = yup
    .object({
        rejected_reason: yup.string().required("Reason is Required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    handleSubmitUpdate(data.rejected_reason, true);
    setValue('rejected_reason', "");
  };

  const RejectedReasonForm = (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <h3 className="content-heading">{`Reject Reason`}</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="col-lg-12 ">
              <div>
                <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 10 }}>
                  <Grid item xs={12} md={6} className="mt-2">
                    <InputLabel>Reason *</InputLabel>
                    <Controller
                      name="rejected_reason"
                      control={control}
                      render={({ field, ...props }) => {
                        return (
                          <TextField
                            multiline={true}
                            minRows={2}
                            id="rejected_reason"
                            placeholder={`Type your reason ...`}
                            fullWidth
                            variant="outlined"
                            {...register('rejected_reason')}
                            />
                        );
                      }}
                    />
                    <p className="error-msg">{errors.rejected_reason?.message}</p>
                  </Grid>
                  <div className="row justify-content-end">
                    <div className="col-12 text-end ">
                      <button
                        id="reject_submit"
                        type="submit"
                        className="primarynext-button"
                      >
                        {"Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return RejectedReasonForm;
}

export default PCORejectedDialog;
