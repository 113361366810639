import React, { useEffect, useMemo, useRef, useState } from "react";
import GridTableStatic from "../../components/DataGrid/GridTableStatic";
import { useNavigate } from "react-router-dom";
import { rfiStatusEnum } from "../../constants/RFI.constant";

const RFIMiniTable = ({ data }) => {
  const navigate = useNavigate();

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "rfi_id",
        header: "RFI ID",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span
              className="pointer-cus"
              onClick={(event) =>
                navigate(`/rfi/${row?.original.project_id}/${row?.original.rfi_id}`)
              }
            >
              {renderedCellValue}
            </span>
          );
        },
      },
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          return rfiStatusEnum[cell.getValue()]?.name;
        }
      },
      {
        accessorKey: "requestor.email",
        header: "Requestor",
      },
    ],
    []
  );

  // Render the table component
  return (
    <React.Fragment>
      <GridTableStatic data={data} columns={columns} />
    </React.Fragment>
  );
};

export default RFIMiniTable;
