import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";

import NoAccessModal from '../../components/NoAccessModal';
import { hasSubscription } from '../../helper/AccessHelper';
import TaskTable from './TaskTable';
import TaskForm from './TaskForm';
import moment from 'moment';
import { PrimaryButton } from '../../components/bproButtons';

const TaskDashboard = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [openTaskDialog, setOpenTaskDialog] = useState(false);
    const [totalTaskCount, setTaskCount] = useState(0);
    const [formData, resetForm] = useState({});
    const [updateTask, setUpdateTask] = useState(true);
    const [projectCount, setProjectCount] = useState(0);

    const handleClose = () => {
        setOpenTaskDialog(false);
        setUpdateTask(!updateTask);
    };

    const createNewTask = () => {
        let defaultData = {
            task_name: "",
            description: "",
            project_id: "",
            start_date: moment(),
            due_date: moment(),
            priority: 0,
            status: 0
        }
        resetForm(defaultData);
        setOpenTaskDialog(true);
    }

    const editTask = (taskData) => {
        let editData = {
            task_name: taskData.task_name,
            description: taskData.description,
            project_id: taskData.project_id?.app_id,
            start_date: moment(taskData.start_date),
            due_date: moment(taskData.due_date),
            priority: taskData.priority,
            status: taskData.status,
            id: taskData.app_id,
            assigned_to: taskData.assigned_to?.app_id
        }
        resetForm(editData);
        setOpenTaskDialog(true);
    }

    return (
        <React.Fragment>
            {/* Create New RFI button */}
            <div className="row mx-0 mt-5 mb-3 d-flex justify-content-end">
                <div className="col-auto">
                    <PrimaryButton
                        startIcon={hasSubscription() ? <AddIcon /> : <LockIcon />}
                        onClick={() => hasSubscription() ? createNewTask() : setOpenDialog(true)}
                    >
                        Create New Task
                    </PrimaryButton>
                </div>
            </div>

            {/* Request for Information section */}
            <div className="row mx-0 p-4">
                <div className="backgrd-white">
                    <div className="mb-4 d-flex">
                        <h3 className="submain-heading">Tasks</h3>
                    </div>
                    <div className="w-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{ minHeight: 400, width: "100%" }}>
                                {/* TaskTable component */}
                                <TaskTable refresh={updateTask} editTask={editTask} setTaskCount={setTaskCount} />

                                {/* No Access Modal */}
                                <NoAccessModal openDialog={openDialog} setOpenDialog={setOpenDialog} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TaskForm show={openTaskDialog} handleClose={handleClose} shouldResetForm={formData} projectId={projectId} projectCount={setProjectCount} />
        </React.Fragment>
    );
}

export default TaskDashboard;
