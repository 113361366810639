import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  Grid,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
  InputLabel,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import RFIService from "./RFIService";
import { PrimaryButton } from "../../components/bproButtons";

function RFIAssignModel({ handleClose, show, rfiID, projectData, rfiData, userParsed }) {
  // Initial state for message and loading status
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [isLoading, setLoading] = useState(false);
  const [listMembers, setListMembers] = useState([]);

  // Form schema for validation
  const schema = yup
    .object({
      assigned_to: yup.object().required("Assigned To is Required"),
    })
    .required();

  // React hook form methods
  const {
    register,
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Effect hook to update list of project members
  useEffect(() => {
    const membersList = projectData?.members || [];
    const filteredList = membersList.filter((item) => item.user_id.email !== userParsed?.email)
    if (filteredList && filteredList.length > 0) {
      setListMembers(filteredList);
    }
  }, [projectData.members]);

  // Form submission handler
  const onSubmit = (data) => {
    setLoading(true);
    data.assigned_to = [data.assigned_to?.user_id?.app_id];
    const formData = new FormData();
    formData.append("json_data", JSON.stringify(data));
    RFIService.updateRFIData(rfiID, formData)
      .then((response) => {
        if (response && response.data) {
          setLoading(false);
          setOpenMessage({
            state: "success",
            message: response?.message,
            open: true,
          });
          handleClose(true);
        }
      })
      .catch((error) => {
        setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  // JSX for the RFI assign form
  const RFIAssignForm = (
    <React.Fragment>
      {/* Snackbar for displaying messages */}
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      {/* Backdrop for loading indicator */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialog for RFI assign */}
      <Dialog open={show} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <h3 className="submain-heading">{`Reassign RFI ticket`}</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="col-lg-12 ">
              <div>
                {/* RFI assign form */}
                <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 10 }}>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      {/* Autocomplete input for assigned to */}
                      <InputLabel className="mb-2">Assigned To *</InputLabel>
                      <Controller
                        name="assigned_to"
                        control={control}
                        render={({ field, ...props }) => {
                          return (
                            <Autocomplete
                              label="Assigned To *"
                              options={listMembers}
                              getOptionLabel={(option) => option?.user_id?.email}
                              placeholder="Assigned To *"
                              variant="outlined"
                              getOptionSelected={(option, value) => option.user_id?.email === value.email}
                              onChange={(event, value) => {
                                field.onChange(value);
                                trigger("assigned_to");
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          );
                        }}
                      />
                      <p className="error-msg">{errors.assigned_to?.message}</p>
                    </div>
                  </Grid>
                  <div className="row justify-content-end">
                    <div className="col-6 text-end">
                      {/* Submit button */}
                      <PrimaryButton
                        id="rfi_reassign_submit"
                        type="submit"
                        className="primarynext-button w-auto"
                      >
                        {"Submit"}
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return RFIAssignForm;
}

export default RFIAssignModel;
