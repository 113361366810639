import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Backdrop, Breadcrumbs, Button, CircularProgress } from '@mui/material';
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";

import NoAccessModal from '../../components/NoAccessModal';
import { hasSubscription } from '../../helper/AccessHelper';
import PCOTable from './PCOTable';
import PCOSelectionCard from './PCOSelectionCard';
import { PrimaryButton } from '../../components/bproButtons';

const PCODashboard = () => {
    let { project_id: projectId } = useParams();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSelectionDialog, setOpenSelectionDialog] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    return (
        <React.Fragment>
            {/* Create New RFI button */}
            <div className="row mx-0 mt-5 mb-3 d-flex justify-content-end">
                <div className="col-auto">
                    <PrimaryButton
                        startIcon={hasSubscription() ? <AddIcon /> : <LockIcon />}
                        onClick={() => hasSubscription() ? setOpenSelectionDialog(true) : setOpenDialog(true)}
                    >
                        Create New PCO
                    </PrimaryButton>
                </div>
            </div>

            {/* Request for Information section */}
            <div className="row mx-0 p-4">
                <div className="backgrd-white">
                    <div className="mb-4 d-flex">
                        <h3 className="submain-heading">Potential Change order</h3>
                    </div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/projects" className="pointer-cus">
                            Projects
                        </Link>
                        <Link to={'/projects/detail/' + projectId} className="pointer-cus">
                            Project details
                        </Link>
                        <p>
                            Potential Change order dashboard
                        </p>
                    </Breadcrumbs>
                    <div className="w-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{ minHeight: 400, width: "100%" }}>
                                {/* RFITable component */}
                                <PCOTable setLoading={setIsLoading} />

                                {/* No Access Modal */}
                                <NoAccessModal openDialog={openDialog} setOpenDialog={setOpenDialog} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PCOSelectionCard show={openSelectionDialog} handleClose={() => setOpenSelectionDialog(false)} projectId={projectId} />

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}

export default PCODashboard;
