import React, { useState } from "react";
import ProjectTable from "./ProjectTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoAccessModal from "../../components/NoAccessModal";
import { hasSubscription } from "../../helper/AccessHelper";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";
import ExpectedOutcomeDialog from "../ExpectedOutcomeDialog";
import { PrimaryButton } from "../../components/bproButtons";
import secureLocalStorage from "react-secure-storage";

const ProjectsDashboard = () => {
  const navigate = useNavigate();
  const userRaw = secureLocalStorage.getItem("userInfo");
  const userParsed = JSON.parse(userRaw);
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstTimeLogin = searchParams.get("firstTimeLogin");
  const [openDialog, setOpenDialog] = useState(false);
  const [totalProjectCount, setProjectCount] = useState(0);
  //const [dialogExpOpen, setExpDialogOpen] = useState(isFirstTimeLogin ? true : false);
  const [dialogExpOpen, setExpDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setExpDialogOpen(false);
  };

  const determineHasAccess = () => {
    return hasSubscription() || totalProjectCount === 0;
  };

  return (
    <React.Fragment>
      {userParsed?.user_type === "admin" && (
        <div className="row mx-0 mt-5 mb-3 d-flex justify-content-end">
          <div className="col-auto">
            <PrimaryButton
              startIcon={
                determineHasAccess() === true ? <AddIcon /> : <LockIcon />
              }
              onClick={() =>
                determineHasAccess() === true
                  ? navigate("/projects/newProject")
                  : setOpenDialog(true)
              }
            >
              Add new project
            </PrimaryButton>
          </div>
        </div>
      )}
      <div className="row mx-0 p-4">
        <div className="backgrd-white">
          <div className="mb-4 d-flex">
            <h3 className="submain-heading">Projects</h3>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ minHeight: 400, width: "100%" }}>
                <ProjectTable setProjectCount={setProjectCount} />
                <NoAccessModal
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                />
                <ExpectedOutcomeDialog
                  show={dialogExpOpen}
                  setOpenDialog={handleDialogClose}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectsDashboard;
