import React, { useEffect, useMemo, useRef, useState } from "react";
import GridTableStatic from "../../components/DataGrid/GridTableStatic";
import {
  approvedCOStatus,
  orderChangeReasonEnum,
  orderStatusEnum,
} from "../../constants/ChangeOrder.constant";
import { formatAmount } from "../../helper/ProjectHelper";

const COMiniTable = ({ data }) => {
  const calculateTotalcost = (changeList) => {
    let changeCost = 0;
    changeList?.map((list) => {
      changeCost += parseFloat(list.amount);
    });
    return parseFloat(changeCost).toFixed(2);
  };

  const calculateApprovedTotalcost = (changeList, approvedCost) => {
    const propsCost = calculateTotalcost(changeList);
    const totalApprovedCost = Number(propsCost) + approvedCost;
    return formatAmount(totalApprovedCost);
  };
  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "co_id",
        header: "CO ID#",
      },
      {
        accessorKey: "General.title",
        header: "CO Title",
      },
      {
        accessorKey: "id",
        header: "Status",
        Cell: ({ cell, row }) => {
          return orderStatusEnum[row?.original?.General?.status]?.name;
        },
      },
      {
        accessorKey: "General.change_reason",
        header: "Change Reason",
        Cell: ({ cell, row }) => {
          return orderChangeReasonEnum[row?.original?.General?.change_reason]
            ?.name;
        },
      },
      {
        accessorKey: "assigned_to",
        header: "Appr Cost ",
        Cell: ({ cell, row }) => {
          return row.original?.General?.status === approvedCOStatus
            ? `${calculateApprovedTotalcost(
                row.original?.items,
                row.original?.Owner?.approved_cost
              )}`
            : "";
        },
      },
      {
        accessorKey: "assigned_toss",
        header: "Prop Cost ",
        Cell: ({ row }) =>
          formatAmount(calculateTotalcost(row.original?.items)),
      },
    ],
    []
  );

  // Render the table component
  return (
    <React.Fragment>
      <GridTableStatic data={data} columns={columns} />
    </React.Fragment>
  );
};

export default COMiniTable;
