import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import projectService from "../../service/ProjectService";
import { Gallery } from "react-grid-gallery";
import FileSystemService from "../../service/FileSystemService";
import FileUpload from "../FileSystem/FileUpload";
import { PrimaryButton, SecondaryButton } from "../../components/bproButtons";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { toast } from "react-toastify";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const GalleryView = () => {
  const navigate = useNavigate();
  let { projectId } = useParams();
  const [projectData, setProjectData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const [currentSelection, setSelection] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [parentId, setProjectParentId] = useState('');

  const handleImageClick = (index, item) => setIndex(index);

  useEffect(() => {
    if (projectId) getProjectGallery(projectId);
  }, []);

  const handleCloseUpload = () => {
    setOpenUploadFile(false);
  };

  const handleSelect = (index) => {
    const nextImages = projectData.map((image, i) =>
      i === index
        ? { ...image, isSelected: !image.isSelected }
        : { ...image, isSelected: false }
    );
    setSelection(currentSelection == index ? null : index);
    setProjectData(nextImages);
  };

  const uploadFile = async (uploadData) => {
    const { filename: fileName, files } = uploadData;
    if (files && files.length > 0) {
      const file = files[0];
      var formData = new FormData();
      const filename = file.name;
      let last_dot = filename.lastIndexOf(".");
      let ext = filename.slice(last_dot + 1);
      let name = filename.slice(0, last_dot);
      formData.append("document", file, fileName + "." + ext);

      formData.append("project_id", projectId);
      formData.append("parent_id", parentId);
      formData.append("isDir", false);
      formData.append("name", fileName);
      formData.append("sub_dir", "photos");
      formData.append("document_type", "photos");
      setLoading(true);
      await FileSystemService.uploadDocument(formData)
        .then((response) => {
          toast.info(response?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
          getProjectGallery(projectId);
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          // Set loading to false when the file processing is complete
          setLoading(false);
        });
    }
  };

  const getProjectGallery = (projectId) => {
    projectService
      .getGalleryByProjectId({
        skip: 0,
        limit: 100,
        page: 1,
        project_id: projectId,
      })
      .then((res) => {
        let filteredProj = res?.data?.results.filter(
          (res) => res.thumbnail != null
        );
        let imageArr = [];
        imageArr = filteredProj.map((proj) => {
          return {
            src: proj.document,
            width: 400,
            height: 300,
            isSelected: false,
            fileId: proj?.app_id,
          };
        });
        setProjectName(res?.data?.project_name);
        setProjectParentId(res?.data?.project_root_dir_app_id);
        setProjectData(imageArr);
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const handleDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    if (currentSelection && currentSelection !== null) {
      setLoading(true);
      const selectedIndex = projectData.filter(
        (image, i) => i === currentSelection
      );

      FileSystemService.removeFile(selectedIndex[0]?.fileId)
        .then((response) => {
          handleCloseDelete();
          toast.info(response?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
          getProjectGallery(projectId);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="row mx-0 p-4">
        <div className="backgrd-white">
          <div className="mb-4 d-flex">
            <h3 className="submain-heading">Gallery</h3>
          </div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/gallery" className="pointer-cus">
              Gallery
            </Link>
            <p>{projectName}</p>
          </Breadcrumbs>
          <div className="row mx-0 mt-5 mb-3 d-flex justify-content-end">
            <div className="mb-2">
              {currentSelection != null && (
                <PrimaryButton
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDeleteDialog(currentSelection)}
                  disabled={currentSelection == null}
                >
                  Delete
                </PrimaryButton>
              )}
              {"  "}
              {projectData.length > 0 && (
                <SecondaryButton
                  startIcon={<SlideshowIcon />}
                  onClick={() => handleImageClick(0)}
                >
                  Start Slide Show
                </SecondaryButton>
              )}
              {"  "}
              <PrimaryButton
                startIcon={<AddIcon />}
                onClick={() => setOpenUploadFile(true)}
              >
                Upload New File
              </PrimaryButton>
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ minHeight: 400, width: "100%" }}>
                <Gallery
                  images={projectData}
                  enableImageSelection={true}
                  margin={10}
                  onClick={handleSelect}
                  maxRows={2}
                />
                {projectData.length === 0 && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <h3 className="my-2">No Files Found</h3>
                  </Grid>
                )}
                <FileUpload
                  open={openUploadFile}
                  handleCloseUpload={handleCloseUpload}
                  uploadFile={uploadFile}
                  onlyMedia={true}
                />
                <Lightbox
                  slides={projectData}
                  open={index >= 0}
                  index={index}
                  close={() => setIndex(-1)}
                />
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                  onClick={() => {}}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                  open={openDeleteDialog}
                  onClose={handleCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent className="dialog-delete-block">
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the File?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <SecondaryButton onClick={handleCloseDelete}>
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton onClick={handleDelete}>OK</PrimaryButton>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GalleryView;
