import axios from 'axios';
import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../components/PrivateSideBar';
import  securesecureLocalStorage  from  "react-secure-storage";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  const token = securesecureLocalStorage.getItem("builderAccessToken");
  if(!token){
    securesecureLocalStorage.setItem("tempRedirect", location.pathname);
    return <Navigate to="/login" />;
  }
    

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return token ? <SideBar MainContent={<Outlet />}></SideBar> : <Navigate to="/login" />;
}

export default PrivateRoute;