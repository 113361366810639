import React, { useMemo } from "react";
import styles from "./task-list-table.module.css";
import moment from "moment";
import { dateFormat } from "../../constants/Global.constants";

const localeDateStringCache = {};
const toLocaleDateStringFactory =
    (locale) =>
        (date, dateTimeOptions) => {
            const key = date.toString();
            let lds = localeDateStringCache[key];
            if (!lds) {
                //lds = date.toLocaleDateString(locale, dateTimeOptions);
                lds = moment(date).format(dateFormat)
                localeDateStringCache[key] = lds;
            }
            return lds;
        };
const dateTimeOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const TaskListTable = ({
    rowHeight,
    rowWidth,
    tasks,
    fontFamily,
    fontSize,
    locale,
    onExpanderClick,
    editCurrentTask
}) => {
    const toLocaleDateString = useMemo(
        () => toLocaleDateStringFactory(locale),
        [locale]
    );

    return (
        <div
            className={styles.taskListWrapper}
            style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
            }}
        >
            {tasks.map(t => {
                let expanderSymbol = "";
                if (t.hideChildren === false) {
                    expanderSymbol = "▼";
                } else if (t.hideChildren === true) {
                    expanderSymbol = "▶";
                }

                return (
                    <div
                        className={styles.taskListTableRow}
                        style={{ height: rowHeight }}
                        key={`${t.id}row`}
                    >
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                            title={t.name}
                        >
                            <div className={styles.taskListNameWrapper}>
                                <div
                                    className={
                                        expanderSymbol
                                            ? styles.taskListExpander
                                            : styles.taskListEmptyExpander
                                    }
                                    onClick={() => onExpanderClick(t)}
                                >
                                    {expanderSymbol}
                                </div>
                                <div>
                                    {t.name}
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                        >
                            &nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
                        </div>
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                        >
                            &nbsp;{toLocaleDateString(t.end, dateTimeOptions)}
                        </div>
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                        >
                            &nbsp;{t.assigned_to}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};