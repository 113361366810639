import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs, Grid, Paper, Stack, Typography } from "@mui/material";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import projectService from "../../service/ProjectService";

const GalleryDashboard = () => {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = () => {
    projectService
      .getAllProjects({ skip: 0, limit: 100 })
      .then((res) => {
        setProjectData(res?.data?.results);
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const projectFolderList = () => {
    return projectData?.map((proj) => {
      return (
        <React.Fragment>
          <Grid item md={3}>
            <Stack direction="column" alignItems="center" gap={1}>
              <FolderRoundedIcon
                onClick={() => {
                  navigate(`/gallery/${proj.app_id}`);
                }}
                sx={{
                  fontSize: 200,
                  color: "#00023B",
                  "&:hover": {
                    color: "#333462",
                    cursor: "pointer",
                  },
                }}
              />

              <h3>{proj?.project_name}</h3>
            </Stack>
          </Grid>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="row mx-0 p-4">
        <div className="backgrd-white">
          <div className="mb-4 d-flex">
            <h3 className="submain-heading">Gallery</h3>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} className="mx-auto"></Grid>
            {projectFolderList()}
          </Grid>
          {/* <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ minHeight: 400, width: "100%" }}>
                <Stack direction="row" alignItems="center" gap={1}>
                  {projectFolderList()}
                </Stack>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default GalleryDashboard;
