import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Gantt } from "gantt-task-react";
import "./ProjectDetails.css";
import { formTaskFromData, initTasks } from "../../helper/ProjectHelper";
import {
  Alert,
  Badge,
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "gantt-task-react/dist/index.css";
import ProjectService from "../../service/ProjectService";
import moment from "moment";
// import TaskForm from "../Tasks/TaskForm";
import {
  projectStatusEnum,
  projectTypeEnum,
} from "../../constants/Project.constants";
import { dateFormat } from "../../constants/Global.constants";
import { TaskListHeader } from "../../components/gantt/TaskListHeader";
import { TaskListTable } from "../../components/gantt/TaskListTable";
import { GanttTooltipContent } from "../../components/gantt/GanttTooltip";
// import ProjectMembersForm from "./ProjectMembersForm";
import CloudCircleOutlinedIcon from "@mui/icons-material/CloudCircleOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ChatIcon from "@mui/icons-material/Chat";
import ProjectMembersForm from "./ProjectMembersForm";
import { PrimaryButton } from "../../components/bproButtons";
import TaskForm from "../Tasks/TaskForm";

function ProjectDetail() {
  const navigate = useNavigate();
  let { projectId } = useParams();
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [openMemDialog, setOpenMemDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [membersArray, setMembersArray] = useState([]);
  const [formData, resetForm] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (projectId) getProjectDetails();
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
    setOpenProjectDialog(false);
    setOpenMemDialog(false);
    resetForm({});
    getProjectDetails();
  };

  const createNewTask = () => {
    let defaultData = {
      task_name: "",
      description: "",
      project_id: projectId,
      id: projectId,
      start_date: moment(),
      due_date: moment(),
      priority: 0,
      status: 0,
    };
    resetForm(defaultData);
    setOpenDialog(true);
  };

  const getProjectDetails = () => {
    ProjectService.getProjectTaskById(projectId)
      .then((res) => {
        if (res?.data) {
          setProjectDetails(res?.data);
          if (res?.data.tasks && res?.data.tasks.length > 0)
            setTasks(formTaskFromData(res?.data.tasks));
          if (res?.data.members && res?.data.members.length > 0) {
            const projectMembers = [];
            res.data.members.forEach((member) => {
              const currentMember = {
                email: member.user_id.email,
                first_name: member.user_id.first_name,
                last_name: member.user_id.last_name,
                role_name: member.role_id.role_name,
              };
              projectMembers.push(currentMember);
            });
            setMembersArray(projectMembers);
          }
        }
      })
      .catch((err) => {
        if (err && err.msg) {
        }
      });
  };

  const editProject = (projectData) => {
    let editData = {
      project_name: projectData.project_name,
      description: projectData.description,
      project_type: projectData.project_type,
      address: projectData.address,
      start_date: moment(projectData.start_date),
      end_date: moment(projectData.end_date),
      status: projectData.status,
      id: projectData.app_id,
      project_id: projectData.app_id,
    };
    resetForm(editData);
    setOpenProjectDialog(true);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(false);
    ProjectService.removeProject(projectId)
      .then((response) => {
        setOpenMessage({
          state: "success",
          message: response?.message,
          open: true,
        });
        setTimeout(() => {
          navigate("/projectDashboard");
        }, 2000);
      })
      .catch((error) => {
        if (error)
          setOpenMessage({ state: "error", message: error, open: true });
      });
  };

  const editCurrentTask = (taskInfo) => {
    let taskData = taskInfo?.taskDetails;
    let editData = {
      task_name: taskData.task_name,
      description: taskData.description,
      project_id: taskData.project_id?.app_id,
      start_date: moment(taskData.start_date),
      due_date: moment(taskData.due_date),
      priority: taskData.priority,
      status: taskData.status,
      id: taskData.app_id,
      assigned_to: taskData.assigned_to?.app_id,
    };
    resetForm(editData);
    setOpenDialog(true);
  };

  const dashboardContent = (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/projects" className="pointer-cus">
          Projects
        </Link>
        <p>Project details</p>
      </Breadcrumbs>
      <div className="bg-white rounded-4 p-4">
        <div className="row mb-4 d-flex justify-content-between align-items-center">
          {/* <div className="col-auto">
            <div className="dropdown three_dot_icons">
              <a
                href="#"
                className="d-flex align-items-center text-decoration-none dropdown-toggle justify-content-center"
                id="dropdown-item"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreHorizIcon color="primary" />
              </a>
              <ul className="dropdown-menu text-small shadow">
                <li onClick={() => editProject(projectDetails)}>
                  <a className="dropdown-item" href="#">
                    <span>
                      <ModeIcon color="action" sx={{ fontSize: 14 }} />
                    </span>
                    Edit{" "}
                  </a>
                </li>
                <li onClick={() => setOpenDeleteDialog(true)}>
                  <a className="dropdown-item" href="#">
                    <span>
                      <DeleteIcon color="action" sx={{ fontSize: 14 }} />
                    </span>
                    Delete{" "}
                  </a>
                </li>
                <li onClick={() => createNewTask()}>
                  <a className="dropdown-item" href="#">
                    <span>
                      <AddTaskIcon color="action" sx={{ fontSize: 14 }} />
                    </span>
                    Add Task{" "}
                  </a>
                </li>
                <li onClick={(event) => navigate(`/projectFiles/${projectId}`)}>
                  <a className="dropdown-item" href="#">
                    <span>
                      <CloudCircleOutlinedIcon
                        color="action"
                        sx={{ fontSize: 14 }}
                      />
                    </span>
                    Project Files{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="d-flex align-items-center mb-20">
            <div className="blue-slant-button">
              <Button onClick={() => navigate(`/projectFiles/${projectId}`)}>
                <div className="button-bg">
                  <span className="btn-icons-align">
                    <img
                      src="/images/docs-icon.svg"
                      alt="Docs"
                      width="22"
                      height="22"
                      className="mr-2"
                    />
                  </span>
                  <span>Docs</span>
                </div>
              </Button>
              <Button onClick={() => navigate(`/pco/${projectId}`)}>
                <div className="button-bg">
                  <span className="btn-icons-align">
                    <img
                      src="/images/potential-change-order-icon.svg"
                      alt="Potential-change-order-icon"
                      width="22"
                      height="22"
                      className="mr-2"
                    />
                  </span>
                  <span>POTENTIAL CHANGE ORDER</span>
                </div>
              </Button>
              <Button onClick={() => navigate(`/co/${projectId}`)}>
                <div className="button-bg">
                  <span className="btn-icons-align">
                    <img
                      src="/images/change-order-icon.svg"
                      alt="Potential-change-order-icon"
                      width="22"
                      height="22"
                      className="mr-2"
                    />
                  </span>
                  <span>CHANGE ORDER</span>
                </div>
              </Button>
              <Button onClick={() => navigate(`/rfi/${projectId}`)}>
                <div className="button-bg">
                  <span className="btn-icons-align">
                    <img
                      src="/images/rfi-icon.svg"
                      alt="Potential-change-order-icon"
                      width="22"
                      height="22"
                      className="mr-2"
                    />
                  </span>
                  <span>RFI</span>
                </div>
              </Button>
              <Button onClick={() => navigate(`/dailylogs/${projectId}`)}>
                <div className="button-bg">
                  <span className="btn-icons-align">
                    <img
                      src="/images/daily-logs-icon.svg"
                      alt="Potential-change-order-icon"
                      width="22"
                      height="22"
                      className="mr-2"
                    />
                  </span>
                  <span>DAILY LOGS</span>
                </div>
              </Button>
              <Button onClick={() => navigate(`/communication/${projectId}`)}>
                <div className="button-bg">
                  <span className="btn-icons-align">
                    <img
                      src="/images/communications-icon.svg"
                      alt="Potential-change-order-icon"
                      width="22"
                      height="22"
                      className="mr-2"
                    />
                  </span>
                  <span>COMMUNICATIONS</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="prj-title-tags-box">
            <h4 className="prj-title">{projectDetails?.project_name}</h4>
            <span className="prj-tags">{projectDetails?.address}</span>
          </div>
          <div className="resident-links">
            {projectDetails?.project_type &&
              projectDetails?.project_type == 1 && (
                <a href="#">
                  <img
                    src="/images/residential-icon.svg"
                    alt="Dots icon"
                    width="22"
                    height="22"
                    className="mr-10"
                  />
                  Residential
                </a>
              )}
            {projectDetails?.project_type &&
              projectDetails?.project_type == 2 && (
                <a href="#">
                  <img
                    src="/images/residential-icon.svg"
                    alt="Dots icon"
                    width="22"
                    height="22"
                    className="mr-10"
                  />
                  Commercial
                </a>
              )}
          </div>
        </div>
        <div className="beige-bg-lite">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="inner-small-heading">Project Details</h4>
            <a href="" className="pending-bg">
              {projectStatusEnum[projectDetails?.status]?.name}
            </a>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-20">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6} className="mx-auto">
                <div className="d-flex flex-column justify-content-between ">
                  <div className="label-value-styles">
                    <label>Assigned Start date :</label>
                    <span>
                      {moment(projectDetails?.start_date).format(dateFormat)}
                    </span>
                  </div>
                  <div className="label-value-styles">
                    <label>Start date :</label>
                    <span>
                      {moment(projectDetails?.start_date).format(dateFormat)}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className="mx-auto">
                <div className="d-flex flex-column justify-content-between right-align-desk align-items-end">
                  <div className="label-value-styles">
                    <label>Completion date :</label>
                    <span>
                      {moment(projectDetails?.end_date).format(dateFormat)}
                    </span>
                  </div>
                  <div className="label-value-styles">
                    <label>Estimated days :</label>
                    <span>
                      {moment(projectDetails?.end_date).diff(
                        moment(projectDetails?.start_date),
                        "days"
                      )}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb-20 w-100">
            <div className="mb-20 w-100 d-flex justify-content-between align-items-center">
              <h4 className="inner-small-heading mb-5">Project Members</h4>
              {membersArray && membersArray.length > 0 && (
                <div>
                  <PrimaryButton
                    onClick={() => setOpenMemDialog(true)}
                    startIcon={<AddIcon />}
                  >
                    Add Members
                  </PrimaryButton>
                </div>
              )}
            </div>
            <div className="project-users-icons-set">
              {membersArray.map((member) => {
                return (
                  <div className="prj-title-tags-box">
                    <h4 className="prj-title">
                      {member?.first_name
                        ? `${member?.first_name} ${member?.last_name}`
                        : member?.email}
                    </h4>
                    <span className="prj-tags">{member?.role_name}</span>
                  </div>
                );
              })}
            </div>
            {membersArray && membersArray.length == 0 && (
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <div className="center-element">
                  <div>
                    <PrimaryButton
                      onClick={() => setOpenMemDialog(true)}
                      startIcon={<AddIcon />}
                    >
                      Add Members
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {tasks &&
          tasks.length > 0 &&
          membersArray &&
          membersArray.length > 0 && (
            <React.Fragment>
              <div className="mb-20 w-100 d-flex justify-content-between align-items-center">
                <h4 className="inner-small-heading mb-5">Schedule</h4>
                {membersArray && membersArray.length > 0 && (
                  <div>
                    <PrimaryButton
                      onClick={() => createNewTask(true)}
                      startIcon={<AddIcon />}
                    >
                      Add Task
                    </PrimaryButton>
                  </div>
                )}
              </div>
              <div className="mx-0 mb-4">
                <div className="mb-4">
                  <Gantt
                    tasks={tasks}
                    TaskListHeader={TaskListHeader}
                    TaskListTable={TaskListTable}
                    TooltipContent={GanttTooltipContent}
                    onClick={editCurrentTask}
                    rowHeight={40}
                    fontSize={12}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        {tasks &&
          tasks.length == 0 &&
          membersArray &&
          membersArray.length > 0 && (
            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="center-element">
                <PrimaryButton onClick={() => createNewTask()}>
                  <span>
                    <img
                      src="/images/plus-icon.svg"
                      alt="plus"
                      width="19"
                      height="19"
                      className="rounded-circle"
                    />
                  </span>
                  &nbsp;&nbsp;Add Task
                </PrimaryButton>
              </div>
            </div>
          )}
      </div>
      <TaskForm
        show={openDialog}
        handleClose={handleClose}
        shouldResetForm={formData}
        projectId={projectId}
        projectName={projectDetails?.project_name}
        projStartDate={projectDetails?.start_date}
        projEndDate={projectDetails?.end_date}
      />
      <ProjectMembersForm
        show={openMemDialog}
        handleClose={handleClose}
        projectId={projectId}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the Project{" "}
            <b>"{projectDetails.project_name}"</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancel-button"
            onClick={() => setOpenDeleteDialog(false)}
          >
            Cancel
          </Button>
          <Button className="cancel-button" onClick={handleDelete}>
            OK
          </Button>
        </DialogActions>
      </Dialog>{" "}
    </React.Fragment>
  );

  return (
    <div className="row mx-0 p-4">
      <div className="backgrd-white">
        <div className="mb-4 d-flex">
          <h3 className="submain-heading">Project Details</h3>
        </div>
        <div className="w-100">{dashboardContent}</div>
      </div>
    </div>
  );
}

export default ProjectDetail;
