import { Grid } from '@mui/material';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import  securesecureLocalStorage  from  "react-secure-storage";

const PublicRoute = () => {
  const token = securesecureLocalStorage.getItem("builderAccessToken");

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return !token ? <Grid container spacing={2}>
    <Grid item xs={12} md={5} id="order_mobile2">
      <div className="blue-bg public-contents">
        <p>From seamless project coordination to swift change order management, experience a transformative tool that empowers construction professionals like you to conquer challenges and achieve unprecedented success</p>
        <ul>
          <li>
            <h4>Efficient Document Management :</h4>
            Streamline document organization for easy access and collaboration.
          </li>
          <li>
            <h4>Comprehensive Project Management:</h4>
            Ensure smooth project execution with robust management tools.</li>
          <li><h4>Simplified Change Orders:</h4>
            Manage changes seamlessly, reducing project disruptions.</li>
          <li><h4>RFI Handling:</h4>
            Quick and organized handling of Requests for Information.</li>
          <li><h4>Centralized Email Communication:</h4>
            Keep all project-related emails in one central location for clarity.</li>
          <li><h4>Photo Management:</h4>
            Easily manage and share project photos for visual documentation.</li>
          <li><h4>Team Management:</h4>
            Efficiently organize and coordinate project teams for optimal productivity.</li>
        </ul>
      </div>
    </Grid>
    <Grid item xs={12} md={7} id="order_mobile1"><Outlet /> </Grid>
  </Grid> : <Navigate to="/projects" />;
}

export default PublicRoute;