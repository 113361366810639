import React, { useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./dashboard.css";
import {
  BarChart,
  LineChart,
  PieChart,
  mangoFusionPalette,
} from "@mui/x-charts";
import moment from "moment";
import TaskMiniTable from "./TaskMiniTable";
import UserService from "../../service/UserService";
import TeamsInternalTable from "../Teams/TeamsInternalTable";
import TeamsExternalTable from "../Teams/TeamsExternalTable";
import COMiniTable from "./COMiniTable";
import RFIMiniTable from "./RFIMiniTable";
import PCOMiniTable from "./PCOMiniTable";

const valueFormatter = (value) => `$${value}`;

const TenantDashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState("one");

  const navigate = useNavigate();

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    UserService.getDashboardData()
      .then((response) => {
        if (response && response.data) {
          console.log(response);
          setDashboardData(response.data);
        }
      })
      .catch((error) => {});
  };


  return (
    <React.Fragment>
      <div className="row mx-0 p-4 pt-10">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3} className="mx-auto">
            <div className="blue-smallchart">
              <div className="assigned-tasks">
                <div className="project-count">
                  <div className="project-email-text">
                    <p>Total Number of My Assigned Tasks</p>
                    <h4>{dashboardData?.total_tasks}</h4>
                  </div>
                  <div className="project-email-text">
                    <div className="chart-bar">
                      <img src="../images/Bar 3.png" alt="img" />
                      <img src="../images/Bar 4.png" alt="img" />
                      <img src="../images/Bar 5.png" alt="img" />
                      <img src="../images/Bar 6.png" alt="img" />
                      <img src="../images/Bar 7.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={9} className="mx-auto">
            <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
              <div className="">
                <h3>My Pending Approvals</h3>
                <Tabs value={currentTabIndex} onChange={handleTabChange}>
                  <Tab value="one" label="Change Order" />
                  <Tab value="two" label="Potential Change Order" />
                  <Tab value="three" label="RFI" />
                </Tabs>
                {currentTabIndex == "one" && (
                  <COMiniTable data={dashboardData?.pending_cos || []} />
                )}
                {currentTabIndex == "two" && (
                  <PCOMiniTable data={dashboardData?.pending_pcos || []} />
                )}
                {currentTabIndex == "three" && (
                  <RFIMiniTable data={dashboardData?.pending_rfis || []} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} className="mx-auto">
            <div className="backgrd-white boxborder-1 dashbooard-title mb-2">
              <h3>My Overdue Tasks</h3>
              <TaskMiniTable data={dashboardData?.upcoming_tasks || []} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="mx-auto">
            <div className="backgrd-white boxborder-1 dashbooard-title mb-2">
              <h3>My Upcoming Tasks</h3>
              <TaskMiniTable data={dashboardData?.upcoming_tasks || []} />
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <div className="row mx-0 p-4 pt-0">
        <div className="backgrd-white boxborder-1 dashbooard-title mb-4 ">
          <h3>My Invoices</h3>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default TenantDashboard;
