import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Container, Grid, InputAdornment, TextField } from "@mui/material";
import {
  NumericFormatCustom,
  TextMaskCustom,
} from "../../../components/InputMask";

const PCOStep3 = ({ step, setStep, PCOData, setPCOData }) => {
  const schema = yup
    .object({
      owner_name: yup.string().required("Owner Name is required"),
      owner_phone: yup
        .number()
        .typeError("Use only numbers")
        .required("Owner Phone is required")
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      owner_address: yup.string().required("Owner Address is required"),
      approved_cost: yup.string(),
      // .typeError("Use only numbers")
      // .required("Approved Cost is required")
      // .min(0, 'Min value 0')
      // .test(
      //     "noEOrSign", // type of the validator (should be unique)
      //     "Number had an 'e' or sign.", // error message
      //     (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
      // ),
      proposed_total_days_added: yup
        .number()
        .typeError("Use only numbers")
        .required("Proposed Total Days Added is required")
        .min(0, "Min value 0.")
        .max(1000, "Max value 1000.")
        .test(
          "noEOrSign", // type of the validator (should be unique)
          "Number had an 'e' or sign.", // error message
          (value) =>
            typeof value === "number" && !/[eE+-]/.test(value.toString())
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: PCOData?.Owner,
  });

  const disableFields = () => {
    if (PCOData?.General?.status >= 3) return true;
    return false;
  };

  const onSubmit = (data) => {
    data.approved_cost = data.approved_cost.replace(/\D/g, "");
    setPCOData({
      type: "UPDATE_OWNER_INFO",
      payload: data,
    });
    setStep(step + 1);
  };
  return (
    <React.Fragment>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <form className="form-sign-in pb-5" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                id="owner_name"
                placeholder="Owner Name *"
                label="Owner Name *"
                fullWidth
                variant="outlined"
                disabled={disableFields()}
                {...register("owner_name")}
              />
              <p className="error-msg">{errors.owner_name?.message}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="owner_phone"
                label="Owner Phone *"
                placeholder="Owner Phone *"
                {...register("owner_phone")}
                fullWidth
                disabled={disableFields()}
                variant="outlined"
              />
              <p className="error-msg">{errors.owner_phone?.message}</p>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="owner_address"
                label="Owner Address *"
                placeholder="Owner Address *"
                {...register("owner_address")}
                multiline
                maxRows={4}
                fullWidth
                disabled={disableFields()}
                variant="outlined"
              />
              <p className="error-msg">{errors.owner_address?.message}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="approved_cost"
                label="Original Approved Cost *"
                placeholder="Original Approved Cost *"
                defaultValue={PCOData?.Owner?.approved_cost}
                {...register("approved_cost")}
                fullWidth
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  //startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                variant="outlined"
              />
              <p className="error-msg">{errors.approved_cost?.message}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="proposed_total_days_added"
                label="Proposed Total Days *"
                placeholder="Proposed Total Days *"
                {...register("proposed_total_days_added")}
                fullWidth
                disabled={disableFields()}
                variant="outlined"
              />
              <p className="error-msg">
                {errors.proposed_total_days_added?.message}
              </p>
            </Grid>
          </Grid>
          <Grid
            sx={{ mt: 4 }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="row">
              <div className="col-6">
                <button
                  id="pco_step2_previous"
                  type="submit"
                  disabled={disableFields()}
                  className="secondary-button"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  {"Previous"}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  id="pco_step2_submit"
                  type="submit"
                  className="primarynext-button"
                >
                  {"Next"}
                </button>
              </div>
            </div>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default PCOStep3;
