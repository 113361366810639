import React, { useEffect, useMemo, useRef, useState } from "react";
import GridTable from "../../components/DataGrid/GridTable";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import TaskService from "../../service/TaskService";
import {
  TaskPriorityEnum,
  TaskPriorityList,
} from "../../constants/Task.constants";
import {
  projectStatusEnum,
  projectStatusList,
} from "../../constants/Project.constants";
import moment from "moment";
import { dateFormat } from "../../constants/Global.constants";
import { Button } from "@mui/base";
import { toast } from "react-toastify";

const TaskTable = ({ refresh, editTask, setTaskCount }) => {
  const navigate = useNavigate();
  const childRef = useRef();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectionId, setSelectionId] = useState(0);
  const [taskDetails, setTaskDetails] = useState({});

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "task_name",
        header: "Title",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <span>
              <Checkbox
                onClick={() => completeTask(row.original)}
                checked={row.original.status == 2}
              />
              <span
                className="pointer-cus"
                onClick={(event) => editTask(row.original)}
              >
                {renderedCellValue}
              </span>
            </span>
          );
        },
      },
      {
        accessorKey: "project_id.project_name",
        header: "Project",
      },
      {
        accessorKey: "priority",
        header: "Priority",
        Cell: ({ cell }) => {
          return (
            <Chip
              color={TaskPriorityEnum[cell.getValue()]?.className}
              label={TaskPriorityEnum[cell.getValue()]?.name}
            />
          );
        },
        filterVariant: "select",
        filterSelectOptions: TaskPriorityList,
      },
      {
        accessorKey: "status",
        header: "STATUS",
        Cell: ({ cell }) => {
          return (
            <Chip
              color={projectStatusEnum[cell.getValue()]?.className}
              label={projectStatusEnum[cell.getValue()]?.name}
            />
          );
        },
        filterVariant: "select",
        filterSelectOptions: projectStatusList,
      },
      {
        accessorKey: "assigned_to.email",
        header: "Assigned To",
      },
      {
        accessorKey: "due_date",
        header: "DUE DATE",
        Cell: ({ cell }) => moment(cell.getValue()).format(dateFormat),
      },
    ],
    []
  );

  useEffect(() => {
    childRef.current.refreshTable();
  }, [refresh]);

  const handleDeleteDialog = (id, task) => {
    setOpenDeleteDialog(true);
    setSelectionId(id);
    setTaskDetails(task);
  };

  const completeTask = (task) => {
    let newTaskData = {
      id: task.app_id,
      app_id: task.app_id,
      status: task.status == 2 ? 1 : 2,
    };
    newTaskData.id = task.app_id;
    TaskService.updateTask(newTaskData)
      .then((response) => {
        if (response && response.data) {
          childRef.current.refreshTable();
          toast.info(response?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
        }
      })
      .catch((error) => {});
  };

  // Define menu items for row actions
  const renderRowActionMenuItems = ({ closeMenu, row }) => [
    <MenuItem
      key={0}
      onClick={() => {
        editTask(row.original);
      }}
      sx={{ m: 0 }}
    >
      Edit
    </MenuItem>,
    <MenuItem
      key={0}
      onClick={() => {
        handleDeleteDialog(row.original?.app_id, row.original);
      }}
      sx={{ m: 0 }}
    >
      Delete
    </MenuItem>,
  ];

  // Function to fetch data from API
  const fetchDataFromAPI = async (params) => {
    return await TaskService.getAllTasks(params);
  };

  const onRowSelectionChange = ({ closeMenu, row }) => {
    console.log(row, closeMenu);
    //completeTask(row.original)
  };

  const handleDelete = () => {
    if (selectionId) {
      TaskService.removeTask(selectionId)
        .then((response) => {
          //getTaskList();
          toast.info(response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "dark",
          });
          handleCloseDelete();
          childRef.current.refreshTable();
        })
        .catch((err) => {
          if (err && err.msg) {
          }
        });
    }
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setSelectionId(0);
    setTaskDetails({});
  };

  // Render the table component
  return (
    <React.Fragment>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-delete-block">
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the task{" "}
            <b>"{taskDetails.task_name}"</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button className="cancel-button" onClick={handleDelete}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <GridTable
        columns={columns}
        renderRowActionMenuItems={renderRowActionMenuItems}
        onRowSelectionChange={onRowSelectionChange}
        enableRowSelection={false}
        apiCall={fetchDataFromAPI}
        ref={childRef}
      />
    </React.Fragment>
  );
};

export default TaskTable;
