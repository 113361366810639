import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const GridTableStatic = ({columns, data}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    
  });

  return <MaterialReactTable table={table} />;
};

export default GridTableStatic;
