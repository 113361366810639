import React from 'react'
import PCOStep1 from './PCOStep1';
import PCOStep2 from './PCOStep2';
import PCOStep3 from './PCOStep3';
import PCOStep4 from './PCOStep4';

const PCOSteps = (step, setStep, PCOData, setPCOData,  isChangeOrder) => [
  {
    id: '1',
    title: 'General Information',
    content: (
      <PCOStep1
        setStep={setStep}
        step={step}
        PCOData={PCOData}
        setPCOData={setPCOData}
        isChangeOrder={isChangeOrder}
      />
    ),
  },
  {
    id: '2',
    title: 'Requestor Information',
    content: (
      <PCOStep2
        setStep={setStep}
        step={step}
        PCOData={PCOData}
        setPCOData={setPCOData}
        isChangeOrder={isChangeOrder}
      />
    ),
  },
  {
    id: '3',
    title: 'Owner Information',
    content: (
      <PCOStep3
        setStep={setStep}
        step={step}
        PCOData={PCOData}
        setPCOData={setPCOData}
        isChangeOrder={isChangeOrder}
      />
    ),
  },
  {
    id: '4',
    title: 'Change Information',
    content: (
      <PCOStep4
        setStep={setStep}
        step={step}
        PCOData={PCOData}
        setPCOData={setPCOData}
        isChangeOrder={isChangeOrder}
      />
    ),
  }
]
export default PCOSteps