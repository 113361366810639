import axios from "axios";
import { authHeaders } from "../helper/AuthHelper";

const API_URL = process.env.REACT_APP_API_URL;

const createDailyLog = (formData) => {
  return axios
    .post(API_URL + `daily-logs/`, formData, {
      headers: authHeaders(true),
    })
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const getDailyLogbyProjectID = (params) => {
  return axios
    .get(API_URL + "daily-logs/", {
      headers: authHeaders(),
      params
    })
    .then((response) => {
      console.log('response', response);
      if (response && response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        let msg = "Try again later!";
        if (error?.response?.data?.status[0])
          msg = error?.response?.data?.status[0];
        throw msg;
      }
    });
};

export default { createDailyLog, getDailyLogbyProjectID };
