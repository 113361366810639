import  securesecureLocalStorage  from  "react-secure-storage";
const hasSubscription = () => {
    const userInfoRaw = securesecureLocalStorage.getItem("userInfo");
    const userInfoParsed = JSON.parse(userInfoRaw);
    return userInfoParsed?.has_subscription;
}

const getUserInfoDetails = () => {
    const userInfoRaw = securesecureLocalStorage.getItem("userInfo");
    const userInfoParsed = JSON.parse(userInfoRaw);
    return userInfoParsed;
}

export { hasSubscription, getUserInfoDetails }