import React, { useEffect, useState } from "react";
import { Breadcrumbs, FormControl, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { projectTypeEnum, projectStatusEnum } from "../../../constants/Project.constants";
import { dateFormat } from "../../../constants/Global.constants";
import { useParams } from "react-router-dom";
import StyledDropzone from "../../../components/DropZone/DropZone";

function ProjectStep1({ step1DataSubmit, shouldResetForm }) {
  
  let { project_app_id } = useParams();

  const [savedFile, setSavedFile] = useState([]);

  const schema = yup
    .object({
      project_name: yup.string().required("Project Name is required"),
      description: yup.string(),
      project_type: yup.string().required("Project Type is required"),
      address: yup.string().required("Site Address is required"),
      start_date: yup
        .date()
        // .default(undefined)
        .typeError("Invalid Date")
        .required("Start date is required"),
      end_date: yup
        .date()
        .typeError("Invalid Date")
        .required("End date is required")
        .test(
          "",
          "End date must be greater than start date",
          (val, props) => {
            const endDate = moment(val);
            const startDate = moment(props.parent.start_date);
            const tmpExpiryDate = moment(startDate).add(1, "days");
  
            if (!tmpExpiryDate.isAfter(endDate)) {
              return true;
            }
          }
        ),
      status: yup.number().default(0).required("Status is required"),
      files: yup.mixed(),
    })
    .required();

  useEffect(() => {
    if(!project_app_id) {
      setValue("status", 0);
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const step1Submit = (data) => {
    step1DataSubmit(data);
  };

  useEffect(() => {
    if (shouldResetForm.start_date) {
      shouldResetForm.start_date = moment(shouldResetForm.start_date);
    }
    if (shouldResetForm.end_date) {
      shouldResetForm.end_date = moment(shouldResetForm.end_date);
    }

    reset(shouldResetForm);
  }, [shouldResetForm])

  // Function to handle file uploads
  const uploadFiles = (file) => {
      const uploadedFiles = [...savedFile, ...file];
      setSavedFile(uploadedFiles);
      setValue("files", uploadedFiles);
      trigger("files");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <form
        className="form-private form-design"
        onSubmit={handleSubmit(step1Submit)}
      >
        <div className="form-group mb-3">
          <label className="mb-2">
            Project Title<i className="required">*</i>
          </label>
          <TextField
            id="project_name"
            variant="outlined"
            placeholder="Title"
            {...register("project_name")}
          />
          <p className="error-msg">{errors.project_name?.message}</p>
        </div>
        <div className="form-group mb-3">
          <label className="mb-2">Project Description</label>
          <TextField
            id="project-description"
            variant="outlined"
            placeholder="Description"
            {...register("description")}
          />
          <p className="error-msg">{errors.description?.message}</p>
        </div>
        <div className="form-group">
          <InputLabel>Project Type</InputLabel>
          <div className="select-dropdown">
            <Controller
              name="project_type"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (<TextField
                labelId="project_type_label"
                id="project_type"
                select
                value={value}
                {...register("project_type")}
              >
                {Object.keys(projectTypeEnum).map((type) => {
                  return (
                    <MenuItem key={type} value={projectTypeEnum[type].value}>
                      {projectTypeEnum[type].name}
                    </MenuItem>
                  );
                })}
              </TextField>
              )}
            />
            <p className="error-msg">{errors.project_type?.message}</p>
          </div>
        </div>
        {(project_app_id) &&
          (<div className="form-group">
            <InputLabel>Project Status</InputLabel>
            <div className="select-dropdown">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (<TextField
                  labelId="status"
                  id="status"
                  select
                  value={value}
                  {...register("status")}
                >
                  {Object.keys(projectStatusEnum).map(type => {
                    return <MenuItem key={type} value={projectStatusEnum[type].value}>{projectStatusEnum[type].name}</MenuItem>
                  })}
                </TextField>
                )}
              />
              <p className="error-msg">{errors.status?.message}</p>
            </div>
          </div>)
        }
        <div className="form-group">
          <label className="mb-2">
            Project Address<i className="required">*</i>
          </label>
          <TextField
            id="project-address"
            variant="outlined"
            placeholder="Address"
            multiline
            maxRows={4}
            {...register("address")}
          />
          <p className="error-msg">{errors.address?.message}</p>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">
                Project Start Date<i className="required">*</i>
              </label>
              <Controller
                name="start_date"
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => {
                  return (
                    <DatePicker
                      id="project_state_date"
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                        trigger("start_date");
                      }}
                      variant="inline"
                      format={dateFormat}
                    />
                  );
                }}
              />
              <p className="error-msg">{errors.start_date?.message}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group">
              <label className="mb-2">
                Project End Date<i className="required">*</i>
              </label>
              <Controller
                name="end_date"
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => {
                  return (
                    <DatePicker
                      id="project_end_date"
                      minDate={
                        getValues("start_date") ? getValues("start_date") : null
                      }
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      variant="inline"
                      format={dateFormat}
                    />
                  );
                }}
              />
              <p className="error-msg">{errors.end_date?.message}</p>
            </div>
          </Grid>
          
        </Grid>
        <div className="form-group">
          <label for="attachments" className="mb-2">
              Attachments
              </label>
              <StyledDropzone
              id="attachments"
              uploadFiles={uploadFiles}
              fileData={savedFile}
              uploadedDocuments={shouldResetForm?.attachments || []}
              />
          </div>
        <div className="login-links-section text-right">
          {/* <button type="submit" className="secondary-button w-auto mr-3">
            Preview
          </button> */}
          <button type="submit" className="primarynext-button w-auto">
            Next
          </button>
        </div>
      </form>
    </LocalizationProvider>
  );
}

export default ProjectStep1;
