import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  ListItemText,
  Box,
  Divider,
  InputAdornment,
  Snackbar,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Backdrop,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ChangeOrderService from "../../../service/ChangeOrderService";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PCOPDFGeneration from "./PCOPDFGeneration";

import { ItemUnits } from "../../../constants/ChangeOrder.constant";
import moment from "moment";
import { NumericFormatCustom } from "../../../components/InputMask";
import { apiDateFormat, dateFormat } from "../../../constants/Global.constants";

const PCOStep4 = ({ step, setStep, PCOData, setPCOData, isChangeOrder }) => {
  const navigate = useNavigate();
  let { pco_id: pcoId } = useParams();
  const message = { state: "success", message: "", open: false };
  const [openMessage, setOpenMessage] = useState(message);
  const [changeList, setChangeList] = useState([]);
  const [propostedCost, setProposedCost] = useState(0);
  const [revisedCost, setRevisedCost] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [feeMarkup, setFeeMarkup] = useState(PCOData?.fee_mark_percent || "");
  const [feeMarkupError, setFeeMarkupError] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const schema = yup
    .object({
      item_title: yup.string().required("Item title is required"),
      item_unit: yup.string().required("Item Unit is required"),
      item_qty: yup
        .number()
        .typeError("Use only numbers")
        .required("Item Quantity is required")
        .min(0, "Min value 0.")
        .test(
          "noEOrSign", // type of the validator (should be unique)
          "Number had an 'e' or sign.", // error message
          (value) =>
            typeof value === "number" && !/[eE+-]/.test(value.toString())
        ),
      item_description: yup.string().required("Item description is required"),
      item_rate: yup
        .number()
        .typeError("Use only numbers")
        .required("Change amount is required"),
      // .test(
      //     "noEOrSign", // type of the validator (should be unique)
      //     "Number had an 'e' or sign.", // error message
      //     (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
      // ),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (PCOData.items.length > 0) setChangeList(PCOData.items);
  }, []);

  const disableFields = () => {
    if (PCOData?.General?.status >= 3) return true;
    return false;
  };

  useEffect(() => {
    let changeCost = 0;
    changeList.map((list) => {
      changeCost += parseFloat(list.amount);
    });
    setProposedCost(changeCost);
    // setSubtotal(changeCost);
    setRevisedCost(
      parseFloat(PCOData?.Owner?.approved_cost) + parseFloat(changeCost)
    );
    setPCOData({
      type: "UPDATE_BREAKDOWN_INFO",
      payload: changeList,
    });
    if (isChangeOrder) {
      handleTotalCaculation(feeMarkup);
    }
  }, [changeList]);

  const handleTotalCaculation = (percent) => {
    setFeeMarkupError("");
    if (percent > -1) {
      let changeCost = 0;
      changeList.map((list) => {
        changeCost += parseFloat(list.amount);
      });
      const markupValue = ((percent / 100) * changeCost).toFixed(2);
      let revisedTotal =
        parseFloat(PCOData?.Owner?.approved_cost) +
        parseFloat(changeCost) +
        parseFloat(markupValue);
      setTotal(parseFloat(changeCost) + parseFloat(markupValue));
      setProposedCost(parseFloat(changeCost) + parseFloat(markupValue));
      setSubtotal(parseFloat(markupValue));
      setRevisedCost(revisedTotal);
    }
  };

  const onSubmit = (data) => {
    setChangeList([
      ...changeList,
      {
        item_id: changeList.length,
        title: data.item_title,
        description: data.item_description,
        unit: data.item_unit,
        quantity: data.item_qty,
        rate: data.item_rate,
        amount: parseInt(data.item_qty) * parseFloat(data.item_rate),
      },
    ]);
    reset();
  };

  const deletItem = (item) => {
    setChangeList(changeList.filter((list) => list.item_id != item.item_id));
  };

  const generatePdfDocument = async (documentData) => {
    const blob = await pdf(
      <PCOPDFGeneration
        title="PCO PDF"
        pdfObj={documentData}
        isChangeOrder={isChangeOrder}
      />
    ).toBlob();
    return blob;
    //saveAs(blob, "BuildifyPro-PCO.pdf");
  };

  const submitCO = async () => {
    if (isChangeOrder && feeMarkup === "") {
      setFeeMarkupError("Markup % is Required");
      return false;
    }
    if (changeList.length > 0) {
      let fileInfo = [...PCOData.file];
      let assignedData = [];
      PCOData?.Requestor?.assigned_to_list.map((list) => {
        assignedData.push(list?.app_id);
      });
      PCOData.Requestor.assigned_to = assignedData;
      delete PCOData.Requestor.submitted_by;
      PCOData.file = [];
      if (isChangeOrder) {
        PCOData.is_co = true;
        PCOData.fee_mark_percent = feeMarkup;
      }
      const needByDate = moment(
        new Date(PCOData.Requestor.date_needed_by)
      ).format(apiDateFormat);
      if (moment(needByDate, dateFormat).isValid()) {
        PCOData.Requestor.date_needed_by = needByDate;
      }
      setLoading(true);
      let CurrentDate = moment().format();
      const docPDF = await generatePdfDocument(PCOData);
      const formData = new FormData();
      const uploadFiles = [];
      uploadFiles.push(docPDF);
      formData.append("attachments", docPDF, `build-${CurrentDate}.pdf`);
      if (fileInfo.length > 0) {
        fileInfo.map((file) => {
          formData.append("attachments", file, file.name);
          //uploadFiles.push(file);
        });
      }
      formData.append("json_data", JSON.stringify(PCOData));
      if (pcoId) {
        ChangeOrderService.updateChangeOrder(pcoId, formData)
          .then((response) => {
            if (response && response.data) {
              setLoading(false);
              setOpenMessage({
                state: "success",
                message: response?.message,
                open: true,
              });
              navigate(
                `/${isChangeOrder ? "changeOrder" : "pco"}/${PCOData.project}`
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            setOpenMessage({ state: "error", message: error, open: true });
          });
      } else
        ChangeOrderService.createChangeOrder(formData)
          .then((response) => {
            if (response && response.data) {
              setLoading(false);
              setOpenMessage({
                state: "success",
                message: response?.message,
                open: true,
              });
              navigate(`/${isChangeOrder ? "co" : "pco"}/${PCOData.project}`);
            }
          })
          .catch((error) => {
            setOpenMessage({ state: "error", message: error, open: true });
          });
    }
  };

  function priceFormat(num) {
    return parseFloat(num).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    //`${parseFloat(num).toFixed(2)}`;
  }

  return (
    <React.Fragment>
      <Snackbar
        open={openMessage.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setOpenMessage(message);
        }}
      >
        <Alert
          onClose={() => {
            setOpenMessage(message);
          }}
          severity={openMessage.state}
          sx={{ width: "100%" }}
        >
          {openMessage.message}
        </Alert>
      </Snackbar>
      <Container component="main" maxWidth="md" sx={{ mb: 2, mt: 10 }}>
        {!disableFields() && (
          <React.Fragment>
            <Grid
              sx={{ mt: 5, mb: 2 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <h5>Add Change Items</h5>
            </Grid>
            <form
              className="form-sign-in"
              sx={{ mb: 4 }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="item_title"
                    placeholder="Item title *"
                    label="Item title *"
                    fullWidth
                    variant="outlined"
                    {...register("item_title")}
                  />
                  <p className="error-msg">{errors.item_title?.message}</p>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="item_unit"
                    placeholder="Item unit *"
                    label="Item unit *"
                    fullWidth
                    variant="outlined"
                    {...register("item_unit")}
                    select
                  >
                    {ItemUnits.map((unit) => {
                      return (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <p className="error-msg">{errors.item_unit?.message}</p>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="item_qty"
                    placeholder="Item quantity *"
                    label="Item quantity *"
                    fullWidth
                    variant="outlined"
                    {...register("item_qty")}
                  />
                  <p className="error-msg">{errors.item_qty?.message}</p>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="item_rate"
                    label="Item Rate *"
                    placeholder="Item Rate *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    {...register("item_rate")}
                    fullWidth
                    variant="outlined"
                  />
                  <p className="error-msg">{errors.item_rate?.message}</p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="item_description"
                    label="Item Description *"
                    placeholder="Item Description *"
                    {...register("item_description")}
                    multiline
                    maxRows={4}
                    fullWidth
                    variant="outlined"
                  />
                  <p className="error-msg">
                    {errors.item_description?.message}
                  </p>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<AddIcon />}
                  >
                    Add Item
                  </Button>
                </Grid>
              </Grid>
            </form>
            {isChangeOrder && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className="mt-5">
                  <TextField
                    id="fee_mark_percent"
                    label="Markup % *"
                    placeholder="Markup % "
                    defaultValue={PCOData?.item?.fee_mark_percent}
                    fullWidth
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      //startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    variant="outlined"
                    value={feeMarkup}
                    onChange={(event) => {
                      setFeeMarkup(event.target.value);
                      console.log(event);
                      handleTotalCaculation(event.target.value);
                    }}
                  />
                  <p className="error-msg">{feeMarkupError}</p>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        )}
        {changeList.length > 0 && isChangeOrder && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableRow>
                <TableCell align="left">
                  <h4>Markup % ({feeMarkup}) Cost</h4>
                </TableCell>
                <TableCell align="left" colSpan={2}>
                  <h4>{priceFormat(subtotal)}</h4>
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <h4>Total Amount</h4>
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <h4>{priceFormat(propostedCost)}</h4>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        )}
        <Grid sx={{ mb: 4, mt: 10 }}>
          <Grid
            sx={{ mt: 5, mb: 2 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <h5>Change Items Summary</h5>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h4>Title</h4>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <h4>Description</h4>
                  </TableCell>
                  <TableCell>
                    <h4>Unit</h4>
                  </TableCell>
                  <TableCell>
                    <h4>Rate</h4>
                  </TableCell>
                  <TableCell>
                    <h4>Quantity</h4>
                  </TableCell>
                  <TableCell align="right">
                    <h5>Amount</h5>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changeList.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell colSpan={2}>{item.description}</TableCell>
                      <TableCell>{item.unit}</TableCell>
                      <TableCell>{priceFormat(item.rate)}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell align="right">
                        {priceFormat(item.amount)}{" "}
                        {!disableFields() && (
                          <IconButton
                            aria-label="comment"
                            onClick={() => deletItem(item)}
                          >
                            <DeleteIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {changeList.length == 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      {" "}
                      No Change Item Added{" "}
                    </TableCell>
                  </TableRow>
                )}
                {isChangeOrder && (
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={3}>
                      <h4>Subtotal</h4>
                    </TableCell>
                    <TableCell align="right" colSpan={2}>
                      <h4>
                        {priceFormat(
                          parseFloat(propostedCost) - parseFloat(subtotal)
                        )}
                      </h4>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell colSpan={3}>
                    <h4>
                      Proposed Additional Cost{" "}
                      {isChangeOrder ? `(Subtotal)` : ``}
                    </h4>
                  </TableCell>
                  <TableCell align="right" colSpan={2}>
                    <h4>{priceFormat(propostedCost)}</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell colSpan={3}>
                    <h4>Project Approved Amount</h4>
                  </TableCell>
                  <TableCell align="right" colSpan={2}>
                    <h4>{priceFormat(PCOData?.Owner?.approved_cost)}</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell colSpan={3}>
                    <h4>Revised Contract Amount After Approval</h4>
                  </TableCell>
                  <TableCell align="right" colSpan={2}>
                    <h4>{priceFormat(revisedCost)}</h4>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          sx={{ mt: 10 }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="row">
            <div className="col-6">
              <button
                id="pco_step2_previous"
                type="submit"
                className="secondary-button"
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                {"Previous"}
              </button>
            </div>
          </div>
          {(changeList.length > 0 && !disableFields()) &&(
            <div className="row">
              <button
                id="pco_step1_submit"
                type="button"
                onClick={() => submitCO()}
                className="primarynext-button"
              >
                {"Save And Generate PDF"}
              </button>
            </div>
          )}
          {(changeList.length == 0 || disableFields()) && (
            <div className="row">
              <Button disabled variant="outlined" >
                Save And Generate PDF
              </Button>
            </div>
          )}
        </Grid>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default PCOStep4;
