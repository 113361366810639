import { authHeaders } from "../helper/AuthHelper";
import http from "./HttpService";
import  securesecureLocalStorage  from  "react-secure-storage";

const loginUser = (bodyFormData) => {
  return http
    .post("/login/", bodyFormData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        let responseData = response?.data?.data;
        securesecureLocalStorage.setItem("builderAccessToken", responseData?.token);
        securesecureLocalStorage.setItem("builderTenantId", responseData?.tenant_id);
        if (responseData?.app_id)
          securesecureLocalStorage.setItem(
            "userInfo",
            JSON.stringify({
              email: bodyFormData?.email,
              user_id: responseData?.app_id,
              has_subscription: responseData?.has_subscription,
              user_type: responseData?.user_type,
            })
          );
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};
const registerUser = (payload) => {
  let bodyFormData = new FormData();

  for (let kv in payload) {
    bodyFormData.append(kv, payload[kv]);
  }
  
  return http
    .post("signup/", payload, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        let responseData = response?.data?.data;
        if (responseData?.tenant_joining_error) return response.data;
        else {
          if (responseData?.token)
            securesecureLocalStorage.setItem("builderAccessToken", responseData?.token);
          if (responseData?.tenant_id)
            securesecureLocalStorage.setItem("builderTenantId", responseData?.tenant_id);
          if (responseData?.app_id)
            securesecureLocalStorage.setItem(
              "userInfo",
              JSON.stringify({
                email: payload?.email,
                user_id: responseData?.app_id,
              })
            );
          return response.data;
        }
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const verifyEmail = (email) => {
  return http
    .get("/check-email/", {
      headers: authHeaders(),
      params: {
        email,
      },
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const forgotPassword = (bodyFormData) => {
  return http
    .post("forgetpassword/", bodyFormData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const changePassword = (bodyFormData) => {
  return http
    .post("changepassword/", bodyFormData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

const resendVerificationEmail = (bodyFormData) => {
  return http
    .post("resend-verification-email/", bodyFormData, {
      headers: authHeaders(),
    })
    .then((response) => {
      if (response && response.data) {
        return response;
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data)
        throw error.response?.data?.message;
    });
};

export default {
  loginUser,
  registerUser,
  forgotPassword,
  changePassword,
  verifyEmail,
  resendVerificationEmail
};
