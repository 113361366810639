const TaskPriorityEnum = {
    0: { className: 'success', color: 'green', name: "Low", value: 0 },
    1: { className: 'warning', color: 'orange', name: "Medium", value: 1 },
    2: { className: 'error', color: 'red', name: "High", value: 2 },
}

const TaskPriorityList = [
    { className: 'success', color: 'green', name: "Low", value: 0 },
    { className: 'warning', color: 'orange', name: "Medium", value: 1 },
    { className: 'error', color: 'red', name: "High", value: 2 },
]




export { TaskPriorityEnum, TaskPriorityList };